<template>
  <master-layout pageTitle="Testing" :showBack="false">
    <div style="margin-bottom: 900px">
      <VideoPlayer />
    </div>

    <div style="margin-bottom: 900px">
      <img
        v-if="imageData"
        :src="imageData"
        alt="Captured content"
        style="width: 250px; height: 250px"
      />
      <div class="hiding-post-container">
        <div ref="content" class="post-container">
          <div class="background"></div>
          <div class="post-content">
            <div
              class="avatars-section"
              :class="avatars.length > 1 ? 'double-avatars' : ''"
            >
              <div
                v-for="(avatar, index) in avatars"
                :key="index"
                :class="'avatar-container-' + index"
              >
                <img :src="avatar" />
              </div>
            </div>
            <div class="title-section">
              <span class="title-text">{{ nomePremio }}</span>
            </div>
            <div class="badge-section">
              <span class="name">{{ nomeVincitore }}</span>
              <span class="detail"> {{ punteggio }}</span>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex; flex-direction: column">
        <input
          type="text"
          v-model="nomePremio"
          placeholder="nome premio"
          style="margin-bottom: 10px"
        />
        <input
          type="text"
          v-model="nomeVincitore"
          placeholder="nome vincitore"
          style="margin-bottom: 10px"
        />
        <input
          type="text"
          v-model="punteggio"
          placeholder="punteggio"
          style="margin-bottom: 10px"
        />

        <button
          @click="captureImage"
          style="
            padding: 10px 20px;
            background-color: #1280b6;
            margin-bottom: 10px;
            color: white;
          "
        >
          Capture Image
        </button>
        <button
          @click="captureAndDownloadImage"
          style="padding: 10px 20px; background-color: #1280b6; color: white"
        >
          Capture and Download Image
        </button>
      </div>
    </div>
    <h3>Notifiche</h3>
    <textarea
      style="width: 100%; height: 100px"
      v-model="textareadata"
    ></textarea>
    <ion-button
      @click="
        openNotification({
          from: '992781005473',
          collapseKey: 'do_not_collapse',
          notification: { body: { title: 'Hello', body: 'Yellow' } },
        })
      "
      >Base</ion-button
    >
    <ion-button
      @click="
        openNotification({
          from: '992781005473',
          collapseKey: 'do_not_collapse',
          notification: { body: { titolo: 'Hello', testo: 'Yellow' } },
        })
      "
      >Base 2</ion-button
    >
    <ion-button
      @click="
        openNotification({
          from: '992781005473',
          collapseKey: 'do_not_collapse',
          notification: {
            body: {
              titolo: 'Hello',
              testo: 'Yellow<br>Yellow2',
              img_big: imgBig,
            },
          },
        })
      "
      >Base with image</ion-button
    >
    <ion-button
      @click="
        openNotification({
          from: '992781005473',
          collapseKey: 'do_not_collapse',
          notification: {
            body: { titolo: 'Hello', id_campagna: 88, tipo_azione: 'kpi' },
          },
        })
      "
      >Close to badge</ion-button
    >
    <ion-button
      @click="
        openNotification({
          from: '992781005473',
          collapseKey: 'do_not_collapse',
          notification: {
            body: {
              titolo: 'Complimenti Martina<br>hai concluso il task!',
              testo:
                'Fai la recensione del tuo supervisore <strong>Sara</strong> per ottenere <strong>20 punti extra</strong>',
              img_big: imgBig,
              img_small: testImg,
              img_small_label: 'Sara Verdi',
              action_label: 'Fai recensione',
            },
          },
        })
      "
      >2 Images and second image label</ion-button
    >
    <ion-button
      @click="
        openNotification({
          from: '992781005473',
          collapseKey: 'do_not_collapse',
          notification: {
            body: {
              titolo: 'Grande risultato, continua così!',
              testo:
                'Ciao Martina, Sara si congratula con te per aver scalato la classifica grazie al <strong>Badge ON Boarding</strong>',
              img_big: imgBig,
              img_small: testImg,
              img_small_2: testImg,
              action_label: 'Chiudi',
              customtype: 'title_sub',
            },
          },
        })
      "
      >1+2 Images, customtype title_sub</ion-button
    >
    <ion-button
      @click="
        openNotification({
          from: '992781005473',
          collapseKey: 'do_not_collapse',
          notification: {
            body: {
              titolo:
                'Dai una votazione a Sara<br>e spiega perché dal tuo punto di vista',
              img_big: imgBig,
              action_label: 'Invia',
              customtype: 'feedback',
            },
          },
        })
      "
      >customtype feedback</ion-button
    >

    <!-- <p>Azioni permesse:</p>
    <ul>
      <li>ranking - con campaign_id</li>
      <li>badge - con campaign_id</li>
      <li>classroom - con campaign_id</li>
      <li>ticket</li>
    </ul>

    <h3>Calendario</h3>
    <v-date-picker :attributes="attributes" :min-date="new Date()" :max-date="new Date()" :disabled-dates="{}" />
    {{ days }}

    <h3>Organigramma</h3>
    <OrgChart :data="orgData"></OrgChart> -->

    <button @click="provas">provas</button>

    <div
      @dragover.prevent.stop="onDragOver($event)"
      @dragleave.prevent.stop="onDragLeave($event)"
      @drop.prevent="onDrop($event)"
      style="
        height: 100px;
        width: 100%;
        background-color: beige;
        display: grid;
        place-content: center;
      "
    >
      Drag here...
    </div>

    <button :disabled="isDragging" class="p-d button-primary">
      {{ isDragging ? "Caricamento..." : "PROVA CTA" }}
    </button>
  </master-layout>
</template>

<script>
import { defineComponent } from "vue";

import VideoPlayer from "@/components/VideoPlayer.vue";

import "swiper/swiper-bundle.min.css";
import "swiper/components/pagination/pagination.scss";
import "@ionic/vue/css/ionic-swiper.css";
import NotificationService from "../common/mixins/Notification";
import * as d3 from "d3";
import { IonIcon, IonButton, IonButtons } from "@ionic/vue";

import { arrowForward } from "ionicons/icons";
import ApiService from "../common/service.api";
import genAxios from "../common/service.api";
import { translate } from "../common/helper.auth";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { IonContent, IonPage, IonicSwiper } from "@ionic/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import OrgChart from "@/components/OrgChart";
import html2canvas from "html2canvas";

import { useStore } from "vuex";

import axios from "axios";

SwiperCore.use([IonicSwiper, Navigation, Pagination]);

export default {
  name: "Testing",
  components: {
    OrgChart,
    // IonIcon,
    // IonButton,

    IonButton,
    IonButtons,
    IonIcon,
    Swiper,
    SwiperSlide,
    IonContent,
    IonPage,
    VideoPlayer,
  },
  data() {
    return {
      avatarUrl: "",
      textareadata: "",
      days: [
        { id: "2022-02-02", date: "2022-02-02T03:00:00.000Z" },
        { id: "2022-03-03", date: "2022-03-03T03:00:00.000Z" },
        { id: "2022-02-06", date: "2022-02-06T03:00:00.000Z" },
      ],
      draggedOver: null,
      isDragging: false,
      imageData: "",
      nomePremio: "TOP VENDITE PER CAMPAGNA",
      nomeVincitore: "Mario Rossi",
      punteggio: "140 Vendite",
      avatars: [],
    };
  },
  computed: {
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  mixins: [NotificationService],
  created() {
    this.imgBig = this.whappyClientAsset + "img/logo.png";
    this.testImg = this.whappyClientAsset + "img/logo.png";
    const store = useStore();
    const loggedUser = store.getters["user/getUserData"];
    this.avatarUrl = loggedUser.avatar_url;
    this.avatars.push(this.avatarUrl);
    this.avatars.push(
      "https://api-retail.whappy.it/Drive/921c5d57-d147-4e34-957d-008a9a706f1b_image.jpg"
    );

    if (this.avatars.length > 2) this.avatars.splice(2);
  },
  mounted() {
    this.orgData = [
      {
        name: "Ian Devling",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/cto.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CTO office",
        tags: "Ceo,tag1,manager,cto",
        isLoggedUser: "false",
        positionName: "Chief Executive Officer ",
        id: "O-6066",
        parentId: "",
        size: "",
      },
      {
        name: "Davolio Nancy",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6067",
        parentId: "O-6066",
        size: "",
      },
      {
        name: " Leverling Janet",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6068",
        parentId: "O-6066",
        size: "",
      },
      {
        name: " Leverling Janet",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6069",
        parentId: "O-6066",
        size: "",
      },
      {
        name: " Leverling Janet",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6070",
        parentId: "O-6066",
        size: "",
      },
      {
        name: " Leverling Janet",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6071",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Fuller Andrew",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Linear Manager",
        id: "O-6072",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Peacock Margaret",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CEO",
        id: "O-6073",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Buchanan Steven",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Head of direction",
        id: "O-6074",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Suyama Michael",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior sales manager",
        id: "O-6075",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "King Robert",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Sales Manager",
        id: "O-6076",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "West Adam",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO",
        id: "O-6077",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Charlotte Cooper",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Trader",
        id: "O-6078",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Yoshi Nagase",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Head of laboratory",
        id: "O-6079",
        parentId: "O-6067",
        size: "",
      },
      {
        name: "Peter Wilson",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Head of channels",
        id: "O-6080",
        parentId: "O-6068",
        size: "",
      },
      {
        name: "Carlos Diaz",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Android Developer",
        id: "O-6081",
        parentId: "O-6068",
        size: "",
      },
      {
        name: "Sven Petersen",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Developer",
        id: "O-6082",
        parentId: "O-6068",
        size: "",
      },
      {
        name: "Peter Wilson",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Head of channels",
        id: "O-6083",
        parentId: "O-6069",
        size: "",
      },
      {
        name: "Carlos Diaz",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Android Developer",
        id: "O-6084",
        parentId: "O-6069",
        size: "",
      },
      {
        name: "Sven Petersen",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Developer",
        id: "O-6085",
        parentId: "O-6069",
        size: "",
      },
      {
        name: "Peter Wilson",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Head of channels",
        id: "O-6086",
        parentId: "O-6070",
        size: "",
      },
      {
        name: "Carlos Diaz",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Android Developer",
        id: "O-6087",
        parentId: "O-6070",
        size: "",
      },
      {
        name: "Sven Petersen",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Senior Developer",
        id: "O-6088",
        parentId: "O-6070",
        size: "",
      },
      {
        name: " Leverling Janet",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "CTO ",
        id: "O-6089",
        parentId: "O-6071",
        size: "",
      },
      {
        name: "Fuller Andrew",
        imageUrl:
          "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        area: "Corporate",
        profileUrl: "http://example.com/employee/profile",
        office: "CEO office",
        tags: "Ceo,tag1, tag2",
        isLoggedUser: "false",
        positionName: "Linear Manager",
        id: "O-6090",
        parentId: "O-6071",
        size: "",
      },
    ];
  },
  methods: {
    captureImage() {
      this.imageData = "";
      const content = this.$refs.content;
      html2canvas(content, {
        logging: true,
        letterRendering: 1,
        allowTaint: false,
        useCORS: true,
      }).then((canvas) => {
        this.imageData = canvas.toDataURL();
      });
    },
    captureAndDownloadImage() {
      const content = this.$refs.content;
      html2canvas(content).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download =
          "social_post_" + this.nomePremio + "_" + this.nomeVincitore + ".png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    onDragOver(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    onDragLeave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    onDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      console.log("DR EVENT: ", event.dataTransfer.files);
    },
    provas() {
      genAxios
        .post("api.tinify.com", {
          Authorization: "Basic D9RLnx75TwlZ4lnkf70wjl5tHC2sXNYS",
          source: {
            url: "https://tinypng.com/images/panda-happy.png",
          },
        })
        .then((res) => {
          console.log(res);
        });
    },
    onDayClick(day) {
      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }
    },
    openNotification(payload) {
      // this.textareadata = JSON.stringify(payload);
      this.openModal(payload);
    },
  },
  ionViewWillEnter() {},
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap");

// .vc-day .vc-opacity-0 {
//   opacity: 0.5;
// }

// .vc-day .vc-pointer-events-none {
//   pointer-events: none;
// }

// .blocked {
//   position: relative;
// }
// .blocked:after {
//   content: "";
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   z-index: 1;
//   background: transparent;
// }

div {
  text-align: center;
}
img {
  // margin-top: 20px;
  max-width: 100%;
}

//IMMAGINE SCONCIA

.hiding-post-container {
  width: 900px;
  height: 900px;
  // width: 1px;
  // height: 1px;
  padding-top: 1px;
  overflow: hidden;
  border: 1px solid #000;
}

.post-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 900px;
  border-radius: 20px;
  overflow: hidden;
}

.background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../public/img/socialpost.png") no-repeat center center;
  background-size: cover;
}

.success-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  opacity: 0.3;
}

.post-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.avatars-section {
  position: absolute;
  top: 450px;
  left: 30px;
  transform: translate(0, -50%);
  width: auto;

  // border: 1px solid salmon;

  .avatar-container-0 {
    width: 270px;
    height: 270px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--ion-color-primary);
    border: 10px solid var(--ion-color-primary);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .avatar-container-1 {
    width: 270px;
    height: 270px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--ion-color-secondary);
    border: 10px solid var(--ion-color-secondary);

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.double-avatars {
  width: 830px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-section {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: auto;
  height: 350px;
  margin-bottom: 240px;
  color: var(--ion-color-primary);
  font-family: "Abril Fatface", serif;
  font-weight: 900;
  font-size: 75px;
  line-height: 60px;
  text-transform: uppercase;
  text-align: center;
  z-index: 1000;
  // border: 1px solid #b61212;
  overflow: hidden;
  .title-text {
    text-align: center;
    max-width: 500px;
  }
}

.badge-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: var(--ion-color-secondary);

  width: 370px;
  height: 235px;
  text-align: center;
  font-family: "Dosis", sans-serif;
  text-transform: uppercase;
  overflow: hidden;
  // border: 1px solid #b61212;
  z-index: 1000;

  .detail {
    font-size: 35px;
    font-weight: 900;
  }
  .name {
    font-size: 55px;
    font-weight: 600;
  }
}
</style>
