<template>
  <div class="d-flex align-items-start">
    <div class="network_user d-flex" ref="user">
      <div class="avatar">
        <img :src="avatar_url" @error="handleImgError" />
      </div>
      <div class="text">
        <div>
          <strong>{{ user.nome }} {{ user.cognome }}</strong>
        </div>
        <div class="text-muted network-role">
          {{ getRoleName(user.id_role) }}
        </div>
      </div>
    </div>
    <div class="children_user_wrapper" v-if="showChildren">
      <NetworkUser
        v-for="child_user in user.children"
        :key="child_user.id"
        :user="child_user"
        :roles="roles"
        @add-user-coordinate="addUserCoordinate"
      />
    </div>
  </div>
</template>

<script>
import { IonAvatar } from "@ionic/vue";
import { AVATAR_PLACEHOLDER_URL } from "@/common/config";
import { stopwatchOutline } from "ionicons/icons";
export default {
  name: "NetworkUser",
  props: ["user", "active_user", "roles"],
  components: {
    IonAvatar,
  },
  data() {
    return {
      avatar_url: null,
    };
  },
  mounted() {
    this.avatar_url = this.user.avatar_url;
    if (!this.avatar_url) {
      this.avatar_url = AVATAR_PLACEHOLDER_URL;
    }
    this.$nextTick(() => {
      const rect = this.$refs["user"].getBoundingClientRect();

      this.$emit("add-user-coordinate", this.user.id, rect);
    });
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    addUserCoordinate(id, rect) {
      this.$emit("add-user-coordinate", id, rect);
    },
    getRoleName(idRole) {
      const role = this.roles.find((x) => x.id === idRole);
      return role ? role.nome : "";
    },
  },
  computed: {
    showChildren() {
      return (
        this.active_user == null || this.active_user_path.includes(this.user.id)
      );
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
}

.align-items-start {
  align-items: flex-start;
}

.avatar {
  width: 44.8px;
  height: 44.8px;
  margin-right: 0.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
}

.text {
  width: 115.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.network-role {
  padding-top: 0.2rem;
}
/* .network_user {
  height: 100px;
} */
</style>
