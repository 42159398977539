<template>
  <master-layout
    smallTitle="le nostre"
    bigTitle="FAQ"
    :showInfoPopHover="true"
    :infoPopHover="
      whappyClientConfig.name == 'Yoube'
        ? INFOPOPOVER_MAP[whappyClientConfig.name]
        : INFOPOPOVER_MAP['default']
    "
  >
    <ion-row class="searchbar">
      <ion-col>
        <ion-searchbar
          placeholder="Ricerca..."
          v-model="filter"
          autocomplete="on"
          clear-icon
          search-icon
        ></ion-searchbar>
      </ion-col>
    </ion-row>

    <accordion v-if="filteredItems" :list="filteredItems"> </accordion>
  </master-layout>
</template>
<script>
import { IonItem, IonIcon, IonSearchbar } from "@ionic/vue"

import Accordion from "../components/Accordion"
import ApiService from "../common/service.api"

export default {
  name: "Faq",
  components: {
    Accordion,
    IonSearchbar,
    IonIcon,
    IonItem,
  },
  created() {
    this.getData()
  },
  data() {
    return {
      list: [],
      filter: "",
      loadingGroups: false,
      INFOPOPOVER_MAP: {
        Yoube:
          "In questa sezione trovi le domande più frequenti. Consulta questa sezione per qualsiasi dubbio e utilizza la comoda barra di ricerca per trovare le risposte più facilmente. Se non trovi la risposta alla tua domanda scrivici a : info.clienti@aphrodite.srl (valutare se possibile aggiungere il link)",
        default:
          "In questa sezione trovi le domande più frequenti. Consulta questa sezione per qualsiasi dubbio e utilizza la comoda barra di ricerca per trovare le risposte più facilmente",
      },
    }
  },

  methods: {
    getData() {
      this.loadingGroups = true
      this.list.splice(0)
      ApiService.get("FaqGroup")
        .then((data) => {
          data.data.forEach((group) => {
            this.list.push({ ...group, questions: [] })

            ApiService.get("FaqByGroup/" + group.id).then((res) => {
              let tmpIndex = this.list.findIndex((x) => x.id == group.id)
              this.list[tmpIndex].questions = [...res.data]
            })
          })
        })
        .finally(() => {
          this.loadingGroups = false
        })
    },
  },
  computed: {
    filteredItems() {
      if (this.filter) {
        let categoriesArray = []

        this.list.forEach((category) => {
          let filteredQuestions = category.questions.filter((question) =>
            question.question["it-IT"]
              .toLowerCase()
              .includes(this.filter.toLowerCase())
          )

          if (filteredQuestions) {
            categoriesArray.push({ ...category, questions: filteredQuestions })
          }
        })
        return categoriesArray
      } else {
        return this.list
      }
    },
  },
}
</script>
