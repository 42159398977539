<template>
  <campaign-layout
    :showBack="computed_debug"
    :showMenu="computed_debug"
    :pageTitle="isAccademy"
  >
    <swiper
      v-show="!showModal"
      :effect="'coverflow'"
      :grabCursor="true"
      :centeredSlides="true"
      :slidesPerView="'auto'"
      :coverflowEffect="{
        rotate: 20,
        stretch: 50,
        depth: 100,
        modifier: 2,
        slideShadows: true,
      }"
      class="mySwiper"
    >
      <swiper-slide
        part="swiper-slide"
        v-for="slide in awardsList"
        :key="slide.id"
        class="slide card-item"
      >
        <div class="ion-text-center slide-container">
          <div class="slide-img" @click="presentAlert(slide)">
            <ion-avatar>
              <img :src="slide.url_image" @error="handleImgError" />
            </ion-avatar>
          </div>
          <div class="slide-title">
            <h5>{{ slide.title }}</h5>
          </div>
          <div v-if="!slide.riscattato" class="slide-btn">
            <ion-button @click="redeemAward(slide)" part="native">
              <ion-icon v-if="slide.vinto" :icon="lockOpen"></ion-icon>
              <ion-icon v-else :icon="lockClosed"></ion-icon>
              Riscatta
            </ion-button>
          </div>
          <div v-if="slide.riscattato">
            <ion-icon
              class="icon-premio-riscattato"
              :icon="checkmarkCircle"
            ></ion-icon>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <br />
    <div v-if="isAccademy === 'Certificazioni'" @click="goToAccademy">
      <ion-card class="px-4 py-3">

        <div class="w-full flex justify-content-end align-items-center">
          <span style="font-size: 14px" class="font-bold">0%</span>
        </div>
        <div class="w-full flex justify-content-start align-items-center">
          <h3 class="font-bold">Accademy</h3>
        </div>
        <common-progress-bar :value="0"></common-progress-bar>
      </ion-card>
    </div>
    <common-campaign-menu
      v-else-if="isAccademy === 'Awards'"
      v-show="!showModal"
      on-award="true"
    ></common-campaign-menu>

    <div
      v-if="kpis && kpis.length && showKpi"
      v-show="!showModal"
      on-award="true"
    >
      <transition-group
        name="block-slide-in"
        :style="{ '--total': kpis.length }"
      >
        <template v-for="(kpi, i) in kpis">
          <ion-card
            :key="i"
            :style="{ '--i': i }"
            v-if="showList1 && showList2"
            @click="
              (kpi.kpiGruppo && kpi.kpiGruppo.length) || kpi.kpiHistoryLabel
                ? (kpi.expanded = !kpi.expanded)
                : null
            "
          >
            <ion-card-header>
              <!--            <ion-card-subtitle>Card Subtitle</ion-card-subtitle>-->
              <div class="percentage">
                {{ kpi.perc_completamento.toFixed(0) }}%
              </div>
              <div class="title">
                <ion-card-title class="text-20">
                  <ion-row class="ion-justify-content-around">
                    <ion-col size="1" class="ion-no-padding">
                      <div style="opacity: 0.6">
                        <ion-icon
                          v-if="kpi.kpiGruppo"
                          :icon="peopleCircle"
                        ></ion-icon>
                        <ion-icon v-else :icon="personCircle"></ion-icon>
                      </div>
                    </ion-col>
                    <ion-col size="10" class="ion-no-padding">
                      {{ $root.translate(kpi.friendlyName) }}
                    </ion-col>
                  </ion-row>
                </ion-card-title>
              </div>
              <div class="common-bar-container">
                <div class="light-title" v-if="kpi.Name">
                  {{ kpi.Name }}
                </div>
                <common-progress-bar
                  v-show="!kpi.expanded"
                  :value="kpi.perc_completamento / 100"
                ></common-progress-bar>
              </div>
              <template v-if="kpi.expanded">
                <div v-if="kpi.kpiHistoryLabel">
                  <!-- <kpi-graph
                    :prop_dataSet="[
                      {
                        data: kpi.kpiHistoryValue,
                        borderColor: '#ffffff',
                        backgroundColor: '#ffffff',
                        fill: {
                          target: 'origin',
                          above: '#ffffff' + '33',
                        },
                        tension: 0.6,
                      },
                    ]"
                    :labelsValues="kpi.kpiHistoryLabel"
                  >
                  </kpi-graph> -->
                  <div class="light-title" v-if="kpi.Name">
                    {{ kpi.Name }}
                  </div>

                  <kpi-graph
                    :prop_dataSet="[
                      { data: kpi.kpiHistoryValue, color: primaryColor },
                      { data: kpi.kpiHistoryGoal, color: secondaryColor },
                    ]"
                    :labelsValues="kpi.kpiHistoryLabel"
                    :prop_options="{ fontColor: 'white', colorXY: 'white' }"
                  ></kpi-graph>
                </div>

                <div v-else>
                  <div
                    v-for="kpiUser of kpigroup_inOrdine(kpi.kpiGruppo)"
                    :key="kpiUser.idUser"
                    class="ion-no-padding"
                  >
                    <div v-if="kpiUser.kpiHistoryLabel" class="graph-container">
                      <ion-row>
                        <ion-col size="auto">
                          <div class="avatar-container">
                            <ion-avatar v-if="!kpiUser.Name">
                              <img
                                v-if="kpiUser.UserImage"
                                :src="kpiUser.UserImage"
                                @error="handleImgError"
                              />
                              <img
                                v-else
                                src="../../../public/assets/img/avatar_placeholder.png"
                                @error="handleImgError"
                              />
                            </ion-avatar>
                          </div>
                        </ion-col>
                        <ion-col>
                          <div class="light-title" v-if="kpiUser.Name">
                            {{ kpiUser.Name }}
                          </div>

                          <kpi-graph
                            :prop_dataSet="[
                              {
                                data: kpiUser.kpiHistoryValue,
                                color: primaryColor,
                              },
                              {
                                data: kpiUser.kpiHistoryGoal,
                                color: secondaryColor,
                              },
                            ]"
                            :labelsValues="kpiUser.kpiHistoryLabel"
                            :prop_options="{
                              fontColor: 'white',
                              colorXY: 'white',
                            }"
                          ></kpi-graph>
                        </ion-col>
                      </ion-row>
                    </div>

                    <div v-else class="progress-container">
                      <div class="light-title" v-if="kpiUser.Name">
                        {{ kpiUser.Name }}
                      </div>
                      <!-- <div style="margin-bottom: 1rem">
                        {{ kpiUser.valore }} /
                        {{ dynamicGoal(kpi, kpiUser.valore) }} ({{
                          kpiUser.valore
                        }}
                        +
                        {{ dynamicGoal(kpi, kpiUser.valore) - kpiUser.valore }})
                      </div> -->
                      <div
                        v-if="percIsLegit(kpi.valore, kpi.goal)"
                        style="margin-bottom: 0.5rem"
                      >
                        <!-- {{
                          (
                            (kpiUser.valore /
                              dynamicGoal(kpi, kpiUser.valore)) *
                            100
                          ).toFixed(2)
                        }}% -->
                        {{ percIsLegit(kpi.valore, kpi.goal) }}%
                      </div>
                      <!-- <common-progress-bar
                        :value="
                          kpiUser.valore / dynamicGoal(kpi, kpiUser.valore)
                        "
                        :avatar="kpiUser.UserImage"
                      ></common-progress-bar> -->
                      <common-progress-bar
                        :value="
                          kpiUser.valore /
                          Math.max(
                            kpi.valore / kpi.kpiGruppo.length,
                            kpi.topValue
                          )
                        "
                        :avatar="kpiUser.UserImage"
                      ></common-progress-bar>
                    </div>
                  </div>
                </div>
              </template>
            </ion-card-header>
          </ion-card>
        </template>
      </transition-group>
    </div>
    <div v-if="showModal" :class="{ 'show-modal': showModal }" class="modal">
      <div class="modal-content">
        <div class="title-container">
          <h4>{{ modalData.title }}</h4>
        </div>
        <div class="image">
          <ion-avatar>
            <img :src="modalData.url_image" @error="handleImgError" />
          </ion-avatar>
        </div>
        <div v-if="modalData.perc_completamento" class="progressbar-container">
          <common-progress-bar
            class="height-10"
            :value="modalData.perc_completamento"
          ></common-progress-bar>
        </div>
        <div class="description-container">
          <ion-row class="contenuto">
            <ion-col>
              <ion-row v-if="modalData.description">
                <ion-col>
                  {{ $root.translate(modalData.description) }}
                </ion-col>
              </ion-row>
              <ion-row v-if="modalData.nomeCampagna">
                <ion-col>
                  Award vinto nella campagna:<br />
                  <span>{{ $root.translate(modalData.nomeCampagna) }}</span>
                </ion-col>
              </ion-row>
              <ion-row v-if="modalData.vintoData">
                <ion-col>
                  Award vinto in data:<br />
                  <span>{{
                    modalData.vintoData.slice(8, 10) +
                    "-" +
                    modalData.vintoData.slice(5, 7) +
                    "-" +
                    modalData.vintoData.slice(0, 4)
                  }}</span>
                </ion-col>
              </ion-row>
              <ion-row v-if="modalData.riscattatoData">
                <ion-col>
                  Award riscattato in data: <br />
                  <span>{{
                    modalData.riscattatoData.slice(8, 10) +
                    "-" +
                    modalData.riscattatoData.slice(5, 7) +
                    "-" +
                    modalData.riscattatoData.slice(0, 4)
                  }}</span>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </div>
        <ion-row class="ion-justify-content-center ion-margint-top">
          <ion-col size="auto">
            <ion-button @click="showModal = false"> Close </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </campaign-layout>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import "swiper/components/pagination/pagination.scss";
import "@ionic/vue/css/ionic-swiper.css";
// import "swiper/css";
// import "swiper/css/effect-coverflow";
import KpiComponent from "@/components/Campaign/KpiComponent.vue";

import CampaignService from "../../common/mixins/Campaign.vue";
import { useGeneralStore } from "../../stores/useGeneralStore";
import {
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonPage,
  toastController,
  IonButton,
  IonicSwiper,
  IonAvatar,
  IonCol,
  IonRow,
  modalController,
} from "@ionic/vue";
import {
  lockOpen,
  lockClosed,
  checkmarkCircle,
  arrowForward,
  peopleCircle,
  personCircle,
} from "ionicons/icons";

import { ref, computed } from "vue";

import ApiService from "../../common/service.api";

import { translate } from "../../common/helper.auth";

import CampaignLayout from "../../components/CampaignLayout";
import ModalInfoAward from "../../views/Modals/ModalInfoAward.vue";

import { useRoute, useRouter } from "vue-router";

import SwiperCore, { Navigation, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import CommonCampaignMenu from "../../components/CampaignMenu";
import CommonProgressBar from "../../components/CommonProgressBar.vue";

SwiperCore.use([IonicSwiper, Navigation, EffectCoverflow, Pagination]);

export default {
  name: "Award",
  components: {
    KpiComponent,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonContent,
    IonPage,
    toastController,
    CampaignLayout,
    IonButton,
    Swiper,
    SwiperSlide,
    CommonCampaignMenu,
    ModalInfoAward,
    IonAvatar,
    IonCol,
    IonRow,
    CommonProgressBar,
  },
  mixins: [CampaignService],
  created() {
    console.log("GET COOKIE DEBUG, ", this.$cookies.get("debug"));
    this.campaignOnlyWithKpi();
    this.useMixinGetCampaign();
    this.primaryColor = this.whappyClientConfig.graphDarkColor_1;
    this.secondaryColor = this.whappyClientConfig.graphDarkColor_2;
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const generalStore = useGeneralStore();

    const isAccademy = computed(() => {
      return generalStore.isAccademy ? "Certificazioni" : "Awards";
    });

    const { id } = route.params;

    const awardsList = ref([]);

    function apiGet() {
      ApiService.get(`CampaignManager/GetUserAward/${id}`).then((res) => {
        console.log(res);
        res.data.forEach((element) => {
          if ("title" in element) {
            element.title = translate(element.title);
          }

          awardsList.value.push(element);
        });
      });
    }

    function goToAccademy() {
      router.push(generalStore.accademyPath);
    }

    apiGet();

    return {
      id,
      awardsList,
      apiGet,
      goToAccademy,
      EffectCoverflow,
      isAccademy,
    };
  },
  data() {
    return {
      lockOpen,
      lockClosed,
      checkmarkCircle,
      campaignMenuVisible: false,
      showKpi: false,
      showModal: false,
      modalData: {},
      peopleCircle,
      personCircle,
      kpis: null,
      showList1: false,
      showList2: false,
      primaryColor: "",
      secondaryColor: "",
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    percIsLegit(valore, goal) {
      if (valore && goal) {
        return ((valore / goal) * 100).toFixed(2);
      } else {
        return false;
      }
    },
    //     dynamicGoal(kpi, userValore) {
    //       return userValore + (kpi.goal - kpi.valore) / kpi.kpiGruppo.length;
    //     },
    kpigroup_inOrdine(kpiGruppo) {
      let kpiGruppoOrdinato = kpiGruppo.sort((a, b) => b.valore - a.valore);
      return kpiGruppoOrdinato;
    },
    useMixinGetCampaign() {
      if (
        this.whappyClientConfig.name == "Whappy" ||
        this.whappyClientConfig.name != "Whappy"
      ) {
        this.campaignMenuVisible = true;
      }
    },
    campaignOnlyWithKpi() {
      this.getCampaign(this.$route.params.id).then((data) => {
        if (
          data.sezioniCampagna.length == 1 &&
          data.sezioniCampagna[0].modulo == "KPI"
        ) {
          if (
            this.whappyClientConfig.name == "Masterclass" ||
            this.whappyClientConfig.name == "Yoube"
          ) {
            this.showKpi = true;
          } else {
            this.showKpi = false;
          }
        } else {
          this.showKpi = false;
        }
      });
    },
    redeemAward(award) {
      ApiService.post(`CampaignManager/RedeemAdword/${award.id}`).then(
        async (res) => {
          console.log(res);
        }
      );
    },
    presentAlert(item) {
      this.modalData = {};

      this.showModal = true;

      this.modalData = JSON.parse(JSON.stringify(item));

      console.log(this.modalData);
    },
  },
  ionViewWillEnter() {
    const route = this.$route;
    const { id } = route.params;
    this.showList1 = false;
    this.showList2 = false;
    ApiService.get("CampaignManager/GetUserKpi/" + id, {})
      .then((res) => {
        console.log("GET USER KPI    -- ", res);
        this.kpis = res.data;
        console.log(res.data);
        setTimeout(() => {
          this.$nextTick(() => {
            this.showList1 = true;
          });
        }, 1);
      })
      .finally(() => {
        this.kpis.forEach((kpi) => {
          if (kpi.kpiGruppo) {
            kpi.kpiGruppo.forEach((kpiGruppo) => {
              kpiGruppo.kpiHistoryGoal = kpiGruppo.kpiHistoryGoal.map(
                (item) => {
                  return item / kpiGruppo.kpiHistoryGoal.length;
                }
              );
            });
          }
        });
      });
  },
  ionViewDidEnter() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.showList2 = true;
      });
    }, 1);
  },
  computed: {
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* * {
  border: 1px solid rgb(168, 255, 168);
} */
ion-card {
  //--background: var(--ion-color-primary-h);
  --background: var(--ion-color-primary-shade);
  color: var(--text-color-light);
  ion-card-title {
    color: var(--text-color-light);
  }

  ion-icon {
    font-size: 150%;
    margin: 0 5px -3px 0;
  }
}
.percentage {
  width: 100%;
  float: left;
  text-align: right;
  font-weight: bold;
  color: var(--text-color-light);
}
.title {
  display: block;
  clear: both;
}
ion-card {
  ion-button {
    margin-top: 15px;
  }
  ion-button::part(native) {
    background: var(--text-color-light);
    color: var(--ion-color-primary);
  }
}

.graph-container {
  margin: 20px 0;
}

.progress-container {
  position: relative;
  margin: 70px 0 70px;
  padding-right: 1rem;
  padding-left: 1rem;
  &:last-child {
    margin-bottom: 30px;
  }
}
.common-bar-container {
  margin-top: 10px;
  //   padding-left: 3rem;
}

.slide {
  width: 200px;
  height: 300px;
  margin: 30px 0;
  padding: 1rem 0.2rem;
  border-radius: 10px;
}

.slide-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1rem;
  //   * {
  //     border: 1px solid salmon;
  //   }
}

.slide-title {
  font-size: 0.5rem;
}

.slide-img {
  display: grid;
  place-content: center;
  width: auto;
  max-height: 50%;

  ion-avatar {
    width: 130px;
    height: 130px;
  }
}

.slide-img img {
  height: 100%;
}

.slide-btn {
  align-self: center;
  width: fit-content;
}

// ion-button::part(native) {
//   background-color: var(--text-color-light);
//   color: var(--ion-color-primary);
//   border-radius: var(--border-radius);
// }

ion-icon {
  --ionicon-stroke-width: 32px;
}

.icon-premio-riscattato {
  font-size: 4rem;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  z-index: 10;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem 1.5rem;
  width: 80vw;
  border-radius: 0.5rem;
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.image {
  padding: 0.5rem;
  display: grid;
  place-content: center;

  ion-avatar {
    width: 40vw;
    height: 40vw;
  }
}

.progressbar-container {
  padding: 3rem 0;
  width: 30vw;
  margin: auto;
  ion-progress-bar {
    border-radius: 20px;
  }
}

.description-container {
  margin-bottom: 3rem;
}

.title-container {
  margin-bottom: 1rem;
}
</style>
