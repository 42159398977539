<template>
  <div class="d-flex flex-column">
    <div class="video-container">
      <div ref="playerContainer" class="youtube-player"></div>
      <div id="progress-bar-container" class="progress-bar-container">
        <div class="progress-bar" :style="{ width: progressPercentage + '%' }"></div>
      </div>
    </div>
    <!-- <div v-if="youtubeLink" class="d-flex flex-column">
      <p>Durata: {{ formatTime(duration) }}</p>
      <p>Tempo guardato: {{ formatTime(watchTime) }}</p>
      <p>Tempo rimanente: {{ formatTime(remainingTime) }}</p>
      <p>Percentuale guardata: {{ (progressPercentage).toFixed(2) }}%</p>
    </div> -->
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, computed, defineProps, defineEmits, nextTick } from 'vue';

const props = defineProps({
  url:String
})

const emit = defineEmits(['watch_session_ended']);


const youtubeLink = ref(props.url);
const videoId = ref('');
const playerContainer = ref(null);
const player = ref(null);
const playerState = ref(null);
const watchTime = ref(0);
const duration = ref(0);
const intervalId = ref(null);

const remainingTime = computed(() => duration.value && watchTime.value ? duration.value - watchTime.value : 0);

const progressPercentage = computed(() => duration.value && watchTime.value ? (watchTime.value / duration.value) * 100 : 0);

let youtubeApiLoaded = ref(false);

function extractVideoId(url) {
  let match = url.match(/[?&]v=([^&]+)/); 
  if (match) {
    return match[1]; 
  }
  match = url.match(/\/embed\/([a-zA-Z0-9_-]+)/);
  return match ? match[1] : '';
}

function updateVideoId() {
  videoId.value = extractVideoId(youtubeLink.value);
}

function calculateDimensions(containerWidth, aspectRatio = 16 / 9) {
  const maxHeight = window.innerHeight - 200; // Altezza massima dinamica

  let width, height;

  if (containerWidth / aspectRatio <= maxHeight) {
    width = containerWidth;
    height = width / aspectRatio;
  } else {
    height = maxHeight;
    width = height * aspectRatio;
  }

  window.document.getElementById("progress-bar-container").style.width = width + "px";

  return { width, height };
}

function createPlayer(container, width, height, videoId, onReady, onStateChange) {
  return new window.YT.Player(container, {
    height: height.toString(),
    width: width.toString(),
    videoId: videoId,
    playerVars: {
      'controls': 0,
      'disablekb': 1,
      'modestbranding': 1,
      'playsinline': 1,
      'rel': 0,
      'showinfo': 0,
    },
    events: {
      'onReady': onReady,
      'onStateChange': onStateChange,
    },
  });
}

function destroyPlayer() {
  if (player.value) {
    player.value.destroy();
    player.value = null;
  }
}

function resetSessionInfo() {
  watchTime.value = 0;
  duration.value = 0;
  if (intervalId.value) {
    clearInterval(intervalId.value);
    intervalId.value = null;
  }
}

function loadVideo() {

  resetSessionInfo();
  destroyPlayer();

  if (window.YT && playerContainer.value) {

    const containerWidth = playerContainer.value.clientWidth;
    const { width, height } = calculateDimensions(containerWidth);

    player.value = createPlayer(playerContainer.value, width, height, videoId.value, onPlayerReady, onPlayerStateChange);
  }


}

function onPlayerReady(event) {
  duration.value = event.target.getDuration();
  startUpdatingTimes();
}



function onPlayerStateChange(event) {
  playerState.value = event.data;

  if (event.data === window.YT.PlayerState.PAUSED || event.data === window.YT.PlayerState.ENDED) {

    emit('watch_session_ended', {
      durata: duration.value,
      tempoGuardato: watchTime.value,
      tempoRimanente: remainingTime.value,
      percentualeGuardata: Number(progressPercentage.value.toFixed(2)),
    });
  }

  if (event.data === window.YT.PlayerState.PLAYING) {
    startUpdatingTimes();
  } else {
    stopUpdatingTimes();
  }
}


function startUpdatingTimes() {
  intervalId.value = setInterval(() => {
    if (player.value && playerState.value === window.YT.PlayerState.PLAYING) {
      watchTime.value = player.value.getCurrentTime();
    }
  }, 1000);
}

function stopUpdatingTimes() {
  if (intervalId.value) {
    clearInterval(intervalId.value);
    intervalId.value = null;
  }
}

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
}

function onYouTubeIframeAPIReady() {
  youtubeApiLoaded.value = true;
  updateVideoId();
}

function handleInputChange() {
  updateVideoId();
  if (!youtubeLink.value) {
    resetSessionInfo();
    destroyPlayer();
  }
}

function clearInput() {
  youtubeLink.value = '';
  handleInputChange();
}

onMounted(() => {

setTimeout(()=>{
  if (!window.YT) {

const script = document.createElement('script');
script.src = 'https://www.youtube.com/iframe_api';
document.head.appendChild(script);

script.onload = () => {
  window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
};



} else {

youtubeApiLoaded.value = true;
updateVideoId()
loadVideo()
}
}, 1000)




});

onUnmounted(() => {
  stopUpdatingTimes();
  destroyPlayer();



});

watch(youtubeLink, handleInputChange);
</script>

<style scoped>
.video-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.youtube-player {
  width: 100%;
  height: 360px;
  background-color: #000; /* Aggiungi uno sfondo nero o un colore di tua scelta */
}

.progress-bar-container {

  height: 10px;
  background-color: #e0e0e0;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: var(--ion-color-primary);
  transition: width 0.5s ease; /* Aggiunge una transizione fluida */
}
</style>