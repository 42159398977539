<template>
  <ion-row class="ion-justify-content-center">
    <ion-col size="10">
      <ion-row>
        <ion-col>
          <ion-row>
            <ion-col :class="{ animateProgressBar: animate }">
              <ion-progress-bar
                class="fat"
                :value="finalValue"
              ></ion-progress-bar>
              <ion-avatar :style="avatarPosition">
                <img
                  v-if="user.profileImage"
                  @click="openModal(user)"
                  :src="user.profileImage"
                  @error="handleImgError"
                />
                <img
                  v-else
                  @click="openModal(user)"
                  src="../../public/assets/img/avatar_placeholder.png"
                  @error="handleImgError"
                />
              </ion-avatar>
              <div class="rank-index-position">
                <h5>
                  <strong>{{ user.position }}</strong>
                </h5>
              </div>
            </ion-col>
          </ion-row>
          <ion-row
            v-if="showPerc && whappyClientConfig.name != 'MySales'"
            class="ion-justify-content-between ion-padding-top"
          >
            <ion-col>
              <p>{{ user.name }}</p>
            </ion-col>
            <ion-col size="3" class="ion-text-end">
              <p>{{ (perc_completamento * 100).toFixed(0) }}%</p>
            </ion-col>
          </ion-row>
          <ion-row v-else class="ion-justify-content-between ion-padding-top">
            <ion-col>
              <p>{{ user.name }}</p>
            </ion-col>
            <ion-col size="3" class="ion-text-end">
              <p>{{ user.punti }} PT</p>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonIcon,
  modalController,
  IonRow,
  IonCol,
  IonProgressBar,
  IonAvatar,
} from "@ionic/vue";
import { personOutline } from "ionicons/icons";
import UserStatistics from "../views/Modals/UserStatistics.vue";

export default {
  name: "ItemRanking",
  components: { IonIcon, IonRow, IonCol, IonProgressBar, IonAvatar },
  props: {
    user: Object,
    perc_completamento: Number,
    showPerc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      personOutline,
      progress: 0,
      animate: true,
      finalValue: 100,
    };
  },
  created() {
    this.finalvalue = this.perc_completamento;
    const that = this;
    setTimeout(() => {
      // that.progress = Math.min(1,this.value+0.1);
      that.progress = 0;
    }, 500);
    setTimeout(() => {
      that.progress = this.perc_completamento;
    }, 1000);
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    async openModal(item) {
      const modal = await modalController.create({
        component: UserStatistics,
        cssClass: "my-custom-class",
        componentProps: {
          user: item,
          idCampaign: Number(this.$route.params.id),
          showPerc: this.showPerc,
        },
      });
      return modal.present();
    },
  },
  computed: {
    avatarPosition() {
      return {
        "--left": this.progress * 100 + "%",
      };
    },
  },
};
</script>

<style scoped>
/* * {
  border: 1px solid rgb(216, 255, 158);
} */

.animateProgressBar ion-progress-bar::part(progress) {
  transform: scaleX(v-bind("progress"));
  /*transition: transform 0.5s linear 0s, -webkit-transform 0.5s linear 0s;*/
  transition: 2.5s cubic-bezier(0.2, 0.5, 0.1, 1);
}

.animateProgressBar ion-avatar {
  transform: translateX(0vw);
  transition: 2.5s cubic-bezier(0.2, 0.5, 0.1, 1);
}

.icon-anon-user {
  padding: 0.25rem;
  font-size: 2rem;
  color: grey;
}

ion-progress-bar {
  position: relative;
}

ion-avatar {
  width: 55px;
  height: 55px;
  position: absolute;
  top: -11px;
  left: calc(var(--left) - 15px);
  border-radius: 50%;
  z-index: 1000;
  transform: translate3d(0, 0, 0) translateX(-100vw) translateZ(1000px);
}

h5 {
  font-size: 1.24rem;
  margin-top: 16px;
}

ion-row {
  width: 100%;
}

p {
  margin: 4px 0 1rem 0;
  font-size: 0.7rem;
}

.rank-index-position {
  position: absolute;
  top: -12px;
  left: -36px;
  padding: 0 1rem 0 0.6rem;
}
</style>
