<template>
  <div class="user-card">
    <img :src="user.image" alt="User Image" class="user-image" />
    <div class="user-info">
      <p class="user-name">{{ user.name }}</p>
      <div class="status-indicator" :class="{ checked: checked }" @click="toggleState">
        <span v-if="checked" class="checkmark"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.checked = newValue;
    },
  },
  methods: {
    toggleState() {
      if (!this.disabled) {
        this.checked = !this.checked;
        this.$emit("update:modelValue", this.checked);
      }
    },
  },
};

</script>

<style scoped>
.user-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--text-color-gray);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 10px;
}

.user-name {
  font-weight: 600;
}

.status-indicator {
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: 2px solid #d1d5db;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.status-indicator.checked {
  background-color: #3b82f6;
  border-color: #3b82f6;
}

.status-indicator .checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.status-indicator.disabled {
  cursor: default;
  opacity: 1;
}
</style>
