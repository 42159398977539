<template>
  <div class="progress-bar" v-bind:class="{ animateProgressBar: animate }">
    <ion-progress-bar v-bind="$attrs" :value="finalvalue"></ion-progress-bar>
    <div class="avatar-container" v-if="avatar || avatar == ''">
      <ion-avatar>
        <img v-if="avatar" :src="avatar" alt="" @error="handleImgError" />
        <img
          v-else
          src="../../public/assets/img/avatar_placeholder.png"
          @error="handleImgError"
        />
      </ion-avatar>
    </div>
  </div>
</template>

<script>
import { IonAvatar, IonProgressBar } from "@ionic/vue";

export default {
  name: "CommonProgressBar",
  components: { IonProgressBar, IonAvatar },
  inheritAttrs: false, // optional
  props: ["value", "avatar"],
  data() {
    return {
      progress: 0,
      progressperc: 0,
      animate: true,
    };
  },
  created() {
    this.finalvalue = this.value;
    const that = this;
    setTimeout(() => {
      that.progress = 0;
      that.progressperc = that.progress + "%";
    }, 500);
    setTimeout(() => {
      that.progress = Math.min(1, this.value + 0.1);
      that.progress = 1;
      that.progressperc = 100 * that.progress + 2.7 + "%";
    }, 500);
    setTimeout(() => {
      that.progress = this.value;
      that.progressperc = that.progress + "%";

      that.progress = this.value;
      that.progressperc = 100 * that.progress + 2.7 + "%";
    }, 1000);
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
  },
};
</script>
<style scoped>
ion-progress-bar {
  margin-top: 4px;
}

.progress-bar {
  position: relative;
}
.avatar-container {
  position: absolute;
  top: -18px;
  left: -30px;
  width: calc(100%);
  z-index: 1000;
  transform: translate3d(0, 0, 0) translateZ(1000px);
}
ion-avatar {
  position: relative;
  height: 40px;
  width: 40px;
  z-index: 100;
  transform: translateZ(1000);
}
.animateProgressBar .avatar-container {
  transform: translate(v-bind("progressperc"), 0);
  transition: transform 0.8s cubic-bezier(0.2, 0.5, 0.1, 1);
}
.animateProgressBar ion-progress-bar::part(progress) {
  transform: scaleX(v-bind("progress"));
  /*transition: transform 0.5s linear 0s, -webkit-transform 0.5s linear 0s;*/
  transition: transform 0.8s cubic-bezier(0.2, 0.5, 0.1, 1);
}
</style>
