<template>
  <ion-fab
    v-if="
      whappyClientConfig.name == 'Trivellato'
    "
    vertical="top"
    horizontal="end"
  >
    <ion-fab-button
      @click="openModalNewKpiChat"
      class="ion-margin-end ion-margin-bottom"
    >
      <ion-icon :icon="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <campaign-layout
    :showMenu="computed_debug"
    :showBack="computed_debug"
    :pageTitle="items.title"
    :smallTitle="idFolder !== 0 ? items.smallTitle : null"
  >
    <template v-if="idFolder === 0">
      <campaign-menu-journey-folders />
    </template>
    <template v-if="idFolder !== 0">
      <campaign-menu-journey-sub-folders />
    </template>
  </campaign-layout>
</template>

<script>
import { add } from "ionicons/icons";
import ApiService from "../../common/service.api";
import CampaignLayout from "../../components/CampaignLayout";
import { useRoute } from "vue-router";
import CommonCampaignMenu from "../../components/CampaignMenu";
import CampaignMenuJourneyFolders from "../../components/CampaignJourneyMenuFolders";
import CampaignMenuJourneySubFolders from "../../components/CampaignJourneyMenuSubFolders.vue";
import ModalNewOrdine from "@/views/Modals/ModalNewOrdine.vue";
import { modalController } from "@ionic/vue";

export default {
  name: "Menu",
  data() {
    return {
      items: [],
      kpis: null,
      showList1: false,
      showList2: false,
      add,
      idFolder: null,
    };
  },
  components: {
    CommonCampaignMenu,
    CampaignLayout,
    ModalNewOrdine,
    CampaignMenuJourneyFolders,
    CampaignMenuJourneySubFolders,
  },
  setup() {
    const route = useRoute();
    const { id } = route.params;
    return { id };
  },
  mounted() {
    const route = this.$route;
    const { id } = route.params;
    this.idFolder = Number(route.params.idFolder || 0); 

    ApiService.get(`Journey/GetAppJourney/${id}`).then((data) => {

  const title = data.data.title["it-IT"];


  let smallTitle = null;
  if (this.idFolder !== 0) {
    const block = data.data.blocks.find(
      (block) => block.id === this.idFolder
    );
    smallTitle = block ? block.nome : null; 
  }

  this.items = {
    title,
    smallTitle,
    blocks: data.data.blocks,
  };
});
  },
  watch: {

    $route(to) {
      this.idFolder = Number(to.params.idFolder || 0);
    },
  },
  methods: {
    async openModalNewKpiChat() {
      let options = this.options;

      const modal = await modalController.create({
        component: ModalNewOrdine,
        cssClass: "common-modal",
      });

      modal.onDidDismiss().then(async () => {
        await ApiService.get(`SalesChat/GetOrders`).then((res) => {
          this.items = [];
          res.data.forEach((element) => {
            this.items.push(element);
          });
        });
      });
      return modal.present();
    },
  },
  computed: {
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
