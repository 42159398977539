<!-- ci hai mai fatto caso a che lettera viene fuori togliendo un "-" alla G? -->
<!-- TIP: devi pensare fino alla fine per trovare la risposta -->
<script setup>
import { ref, onMounted, nextTick, computed } from "vue";

import ApiService from "@/common/service.api";

import { useStore } from "vuex";

import SectionComponent from "./SectionComponent.vue";
import KPICard from "./KPICard.vue";
import BadgesCard from "./BadgesCard.vue";

import { IonSelect, IonSelectOption } from "@ionic/vue";

const store = useStore();

const loggedUser = computed(() => store.getters["user/getUserData"]);

const loadingCompetenzeList = ref(false);
const showList1 = ref(false);
const showList2 = ref(false);
const filtro = ref({
  acquisite: null,
  badge: [],
  scadute: null,
});

const competenzeList = ref([]);

const competenzeListFiltrate = computed(() => {
  let competenzeFiltrate = [...competenzeList.value];

  // Filtra per stato di acquisizione
  if (filtro.value.acquisite !== null) {
    competenzeFiltrate = competenzeFiltrate.filter((item) => {
      if (filtro.value.acquisite === "acquisite") {
        return item.acquisita;
      } else if (filtro.value.acquisite === "nonacquisite") {
        return !item.acquisita;
      }
      return true;
    });
  }

  // Filtra per tipo di badge
  if (filtro.value.badge.length > 0) {
    competenzeFiltrate = competenzeFiltrate.filter((item) => {
      const badgeTypes = filtro.value.badge;
      return badgeTypes.some((type) => {
        switch (type) {
          case "kpi":
            return (
              item.numBadgeKPIAcquisiti > 0 || item.numBadgeKPINecessari > 0
            );
          case "learning":
            return (
              item.numBadgeLearningAcquisiti > 0 ||
              item.numBadgeLearningNecessari > 0
            );
          case "landing":
            return (
              item.numBadgeLandingAcquisiti > 0 ||
              item.numBadgeLandingNecessari > 0
            );
          case "social":
            return (
              item.numBadgeSocialAcquisiti > 0 ||
              item.numBadgeSocialNecessari > 0
            );
          case "task":
            return (
              item.numBadgeTaskAcquisiti > 0 || item.numBadgeTaskNecessari > 0
            );
          default:
            return false;
        }
      });
    });
  }

  // Filtra per scadenza
  if (filtro.value.scadute !== null) {
    competenzeFiltrate = competenzeFiltrate.filter((item) => {
      const scaduta = daysUntil(item.dataScadenza) <= 0;
      if (filtro.value.scadute === "scadute") {
        return scaduta;
      } else if (filtro.value.scadute === "nonscadute") {
        return !scaduta;
      }
      return true;
    });
  }

  return competenzeFiltrate;
});

function dismiss() {
  this.$refs.modal.$el.dismiss();
}

function resetFiltro() {
  filtro.value = {
    acquisite: null,
    badge: [],
    scadute: null,
  };
}

function calculatePosition() {
  // Logica per calcolare la posizione del filtro
}

function daysUntil(date) {
  //how many days from today to date
  const today = new Date();
  const dateToCheck = new Date(date);
  const diffTime = Math.abs(dateToCheck - today);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

async function getCompetenzeStato() {
  competenzeList.value.splice(0);
  try {
    loadingCompetenzeList.value = true;
    let res = await ApiService.get(
      "Competenza/GetCompetenzeStato/" + loggedUser.value.id
    );

    competenzeList.value = res.data;

    const temp_x_adriano = [
      "https://api-automotive-demo.whappy.it/Drive/a86174d2-aa52-40d9-b231-c97328a92f3a_Schermata%202024-08-24%20alle%2019.08.39%202.png",
      "https://api-automotive-demo.whappy.it/Drive/3839acb6-6246-4893-b50b-d2ddaaa3eb9a_Schermata%202024-08-24%20alle%2019.08.39%202%203.png",
      "https://api-automotive-demo.whappy.it/Drive/e442e146-86d9-4a1a-abbe-6b96535604ad_Schermata%202024-08-24%20alle%2019.08.39%202%204.png",
      "",
    ];
    console.log("competenze list", competenzeList.value);
    for (let i = 0; i < competenzeList.value.length; i++) {
      console.log("i", i);
      console.log("competene [i]", competenzeList.value[i]);
      let c = competenzeList.value[i];
      console.log("c ", c);
      c.img = temp_x_adriano[i];
    }

    setTimeout(async () => {
      await nextTick();
      showList1.value = true;
      loadingCompetenzeList.value = false;
    }, 1);
  } catch (error) {
    console.log(error);
  }
}

onMounted(async () => {
  showList1.value = false;
  showList2.value = false;

  await getCompetenzeStato();

  setTimeout(async () => {
    await nextTick();
    showList2.value = true;
  }, 1);
});
</script>

<template>

  <div class="w-full" style="margin:auto; ">
    <ion-card class="ion-padding">

      <span style="font-size:1rem; font-weight: bold;">Filtri</span>
      <ion-select v-model="filtro.acquisite" placeholder="Acquisite" style="color: white;">
        <ion-select-option value="acquisite">Acquisite</ion-select-option>
        <ion-select-option value="non acquisite">Non Acquisite</ion-select-option>
      </ion-select>

      <ion-select v-model="filtro.scadute" placeholder="Scadute">
        <ion-select-option value="scadute">Scadute</ion-select-option>
        <ion-select-option value="non scadute">Non Scadute</ion-select-option>
      </ion-select>

    </ion-card>
  </div>

  <transition-group name="block-slide-in" :style="{ '--total': competenzeListFiltrate.length }">
    <template v-for="(c, i) in competenzeListFiltrate" :key="i">
      <div v-if="showList1 && showList2" :style="{ '--i': i }">
        <SectionComponent class="my-2" :sectionTitle="c.nomeCompetenza" sectionItemsName="Activities"
          :sectionPerc="c.completamento + '%'" :dataScadenza="c.dataScadenza">
          <template #icon>
            <img :src="c.img" style="
                width: 4rem;
                height: 4rem;
                border: 1px solid black;
                border-radius: 50%;
              " />

            <!-- <ion-icon
                style="font-size: 24px"
                :style="
                  !c.acquisita
                    ? { color: 'var(--ion-color-primary)' }
                    : { color: 'lightgray' }
                "
                :icon="ribbon"
              ></ion-icon> -->
          </template>

          <div class="kpi-grid">
            <div v-if="c.numBadgeKPINecessari" class="kpi-card">
              <span class="text-12">KPI</span>
              <span class="ml-1 font-bold">{{ c.numBadgeKPIAcquisiti || 0 }}/{{
                c.numBadgeKPINecessari
              }}</span>
            </div>

            <div v-if="c.numBadgeLearningNecessari" class="kpi-card">
              <span class="text-12">Learning</span>
              <span class="ml-1 font-bold">{{ c.numBadgeLearningAcquisiti || 0 }}/{{
                c.numBadgeLearningNecessari
              }}</span>
            </div>

            <div v-if="c.numBadgeLandingNecessari" class="kpi-card">
              <span class="text-12">Landing</span>
              <span class="ml-1 font-bold">{{ c.numBadgeLandingAcquisiti || 0 }}/{{
                c.numBadgeLandingNecessari
              }}</span>
            </div>

            <div v-if="c.numBadgeSocialNecessari" class="kpi-card">
              <span class="text-12">Social</span>
              <span class="ml-1 font-bold">{{ c.numBadgeSocialAcquisiti || 0 }}/{{
                c.numBadgeSocialNecessari
              }}</span>
            </div>

            <div v-if="c.numBadgeTaskNecessari" class="kpi-card">
              <span class="text-12">Task</span>
              <span class="ml-1 font-bold">{{ c.numBadgeTaskAcquisiti || 0 }}/{{
                c.numBadgeTaskNecessari
              }}</span>
            </div>

            <!-- <KPICard
              v-if="c.numBadgeKPINecessari"
              title="Kpi"
              :value="c.numBadgeKPIAcquisiti || 0"
              :total="c.numBadgeKPINecessari"
            />

            <KPICard
              v-if="c.numBadgeLearningNecessari"
              title="Learning"
              :value="c.numBadgeLearningAcquisiti || 0"
              :total="c.numBadgeLearningNecessari"
            />

            <KPICard
              v-if="c.numBadgeLandingNecessari"
              title="Landing"
              :value="c.numBadgeLandingAcquisiti || 0"
              :total="c.numBadgeLandingNecessari"
            />

            <KPICard
              v-if="c.numBadgeSocialNecessari"
              title="Social"
              :value="c.numBadgeSocialAcquisiti || 0"
              :total="c.numBadgeSocialNecessari"
            />

            <KPICard
              v-if="c.numBadgeTaskNecessari"
              title="Task"
              :value="c.numBadgeTaskAcquisiti || 0"
              :total="c.numBadgeTaskNecessari"
            /> -->
          </div>
          <BadgesCard :userBadges="c.userBadges" :userBadgesNotCompleted="c.userBadgesNotCompleted" class="my-3" />
        </SectionComponent>
      </div>

      <div class="separator"></div>
    </template>
  </transition-group>
</template>

<style scoped>
.kpi-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(90px, 1fr));
  /* 3 colonne uguali */
  gap: 8px;
  /* Spaziatura tra gli elementi */
}

.kpi-card {
  background-color: var(--ion-color-secondary);
  border-radius: 8px;
  padding: 0.3rem 0.7rem;
  color: var(--ion-color-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: #e2e9f1;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.title-header {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

ion-modal#example-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#example-modal h1 {
  margin: 20px 20px 10px 20px;
}

ion-modal#example-modal ion-icon {
  margin-right: 6px;

  width: 48px;
  height: 48px;

  padding: 4px 0;

  color: #aaaaaa;
}

ion-modal#example-modal .wrapper {
  margin-bottom: 10px;
}
</style>
<!-- esatto, una C -->
