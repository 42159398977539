<template>
  <div class="quiz-container">
    <TimerComponent
      v-if="enableMaxTime"
      :max-time="maxTime"
      ref="timerComponent"
      @time-up="handleTimeUp"
    />
    <ProgressComponent
      :current-question="currentQuestion + 1"
      :total-questions="questionsProp.length"
    />
    <QuestionComponent
      :question="questionsProp[currentQuestion]"
      @answer-selected="handleAnswerSelected"
    />
  </div>
</template>

<script>
import QuestionComponent from "./QuestionComponent.vue";
import ProgressComponent from "./ProgressComponent.vue";
import TimerComponent from "./TimerComponent.vue";
import { modalController } from "@ionic/vue";
import ModalQuizResult from "../../views/Modals/ModalQuizResult.vue";
import UserService from "../../common/mixins/User";
import { useQuizStore } from "@/stores/useQuizStore";

const quizStore = useQuizStore();

export default {
  name: "Quiz",
  components: {
    QuestionComponent,
    ProgressComponent,
    TimerComponent,
  },
  props: {
    quiz: Object,
    parentParams: Object,
  },
  mixins: [UserService],
  data() {
    return {
      finito: false,
      score: 0,
      questionsProp: this.quiz.questions,
      currentQuestion: 0,
      user: null,
    };
  },
  mounted() {
    this.resetQuiz();
    this.questionsProp = this.quiz.questions;
  },
  computed: {
    enableMaxTime() {
      return this.quiz.enable_max_time;
    },
    maxTime() {
      return this.quiz.max_time;
    },
  },
  methods: {
    resetQuiz() {
      this.finito = false;
      this.score = 0;
      this.currentQuestion = 0;
      this.user = null;
    },
    async openModal() {
      quizStore.setScore((this.score / this.questionsProp.length) * 100);
      quizStore.setMaxScore(this.quiz.unlock_next_perc);
      quizStore.setUser(this.user);

      this.$router.push("/quiz-result");

      // const modal = await modalController.create({
      //   component: ModalQuizResult,
      //   cssClass: "my-custom-class",
      //   componentProps: {
      //     score: (this.score / this.questionsProp.length) * 100,
      //     maxScore: this.quiz.unlock_next_perc,
      //     user: this.user,
      //   },
      // });

      // return modal.present();
    },
    handleAnswerSelected(answer) {
      if (answer.isCorrect) this.score++;
      if (this.currentQuestion + 1 >= this.questionsProp.length) {
        this.finito = true;
        this.loadUser();
      } else {
        this.currentQuestion++;
        this.resetTimer();
      }
    },
    handleTimeUp() {
      if (this.currentQuestion + 1 >= this.questionsProp.length) {
        this.finito = true;
        this.loadUser();
      } else {
        this.currentQuestion++;
        this.resetTimer();
      }
    },
    resetTimer() {
      this.$refs.timerComponent.resetTimer();
    },
    loadUser() {
      this.getCurrentUser().then((data) => {
        this.user = data;
        this.openModal();
        this.resetQuiz();
      });
    },
  },
};
</script>

<style scoped>
.quiz-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}
</style>
