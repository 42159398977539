<template>
  <ion-fab
    v-if="whappyClientConfig.name == 'Yoube' && client.id"
    vertical="bottom"
    horizontal="end"
  >
    <!-- <ion-fab-button
          @click="openModalNewKpiChat"
          class="ion-margin-end ion-margin-bottom"
        >
          <ion-icon :icon="cart"></ion-icon>
        </ion-fab-button> -->
    <ion-fab-button
      @click="apriCatalogo"
      class="ion-margin-end ion-margin-bottom"
    >
      <ion-icon class="pb-1" :icon="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <master-layout smallTitle="dettaglio" bigTitle="Cliente" :noPadding="true">
    <div>
      <div class="bg-ground2 px-3 pb-4">
        <ion-row>
          <ion-col class="ion-text-center">
            <trCard cardType="gray" class="mb-4" :img="true">
              <template #main>
                <div class="flex justify-content-between">
                  <div class="flex flex-column align-items-start">
                    <span class="font-bold text-16">{{
                      client.ragioneSociale
                    }}</span>
                    <span class="text-16"
                      >{{ client.nome }} {{ client.cognome }}</span
                    >
                  </div>
                  <!-- <div>
                    <span class="font-bold text-16 mr-1">ID.</span>
                    <span>{{ client.id }}</span>
                  </div> -->
                </div>
              </template>
              <template #right-side>
                <a
                  :href="'tel:' + client.telefono"
                  class="w-full h-full flex flex-column align-items-center ion-justify-content-center"
                  style="color: var(--button1-text-color)"
                >
                  <ion-icon style="font-size: 20px" :icon="call"></ion-icon>
                  <span class="mt-1">chiama</span>
                </a>
              </template>
            </trCard>
          </ion-col>
        </ion-row>

        <ion-row class="ion-margin-top">
          <ion-col>
            <div
              @click="toggleShowAnagraficaDetails"
              class="w-full flex align-items-center"
            >
              <span class="text-14 font-bold mr-2">Dettagli Anagrafica </span
              ><ion-icon
                class="text-26"
                v-if="anagraficaDetailsVisible"
                :icon="chevronDown"
              ></ion-icon>
              <ion-icon class="text-26" v-else :icon="chevronUp"></ion-icon>
            </div>
            <div v-if="anagraficaDetailsVisible" class="mb-2">
              <div class="pl-4 py-3 w-full" style="max-width: 300px">
                <div class="w-full flex justify-content-end">
                  <button
                    @click="openModalNewClient"
                    class="p-sm button-primary mt-1"
                  >
                    Modifica
                  </button>
                </div>
                <div
                  class="flex flex-column justify-content-center align-items-start mb-4"
                  v-for="(value, key) in clientDetails"
                  :key="key"
                >
                  <span>{{ convertCamelCaseToTitleCase(key) }}</span>
                  <span class="font-bold" style="word-wrap: break-word">{{
                    value === true
                      ? "Sì"
                      : value === false
                      ? "No"
                      : value || "--"
                  }}</span>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>

        <ion-row class="ion-margin-top">
          <ion-col>
            <div
              @click="toggleShowStoricoOridini"
              class="w-full flex align-items-center"
            >
              <span class="text-14 font-bold mr-2">Storico Ordini</span
              ><ion-icon
                class="text-26"
                v-if="storicoOrdiniVisible"
                :icon="chevronDown"
              ></ion-icon>
              <ion-icon class="text-26" v-else :icon="chevronUp"></ion-icon>
            </div>
            <div v-if="storicoOrdiniVisible" class="mb-2 mt-2">
              <div
                v-for="ordine in storicoOrdini"
                :key="ordine.id"
                class="flex flex-column mb-4 card-collapsable-dark p-d border-radius-client shadow-3"
                @click="ordine.collapsed = !ordine.collapsed"
              >
                <div class="flex justify-content-end">
                  <span class="text-10 mb-2 font-bold">{{
                    new Date(ordine.dataOrdine).toLocaleDateString("it", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  }}</span>
                </div>
                <div class="flex justify-content-between mb-2">
                  <span class="font-bold">{{ ordine.client_name }}</span>
                  <span>ID Ordine: {{ ordine.id }}</span>
                </div>

                <div class="flex justify-content-between">
                  <span class="font-bold">{{
                    ordine.confermato || ordine.confirmed
                      ? "Confermato"
                      : "Non confermato"
                  }}</span>

                  <span>{{
                    Number(ordine.tot_price).toLocaleString("it", {
                      style: "currency",
                      currency: "EUR",
                    })
                  }}</span>
                </div>
                <div v-if="ordine.collapsed" class="flex flex-column pt-4">
                  <div class="flex justify-content-between mb-2">
                    <span class="font-bold">Prodotti </span>
                  </div>
                  <div
                    v-for="item in ordine.items"
                    :key="item.id"
                    class="flex justify-content-between align-items-center"
                  >
                    <span>{{ item.nome }}</span>
                    <span class="font-bold">{{
                      Number(item.price).toLocaleString("it", {
                        style: "currency",
                        currency: "EUR",
                      })
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>

        <ion-row class="ion-margin-top">
          <ion-col>
            <div
              @click="toggleShowLeadScoreDetails"
              class="w-full flex align-items-center"
            >
              <span class="text-14 font-bold mr-2">Lead Score </span
              ><ion-icon
                class="text-26"
                v-if="leadScoreDetailsVisible"
                :icon="chevronDown"
              ></ion-icon>
              <ion-icon class="text-26" v-else :icon="chevronUp"></ion-icon>
            </div>

            <ion-row class="ion-align-items-center mb-2">
              <ion-col class="ion-no-padding">
                <gradient-progress-bar
                  v-if="leadScore > 0"
                  class="height-5 gradient"
                  :value="leadScore"
                ></gradient-progress-bar>
              </ion-col>
              <ion-col size="auto" class="ion-margin-start">
                <span class="font-bold"
                  >{{ (leadScore * 100).toFixed(2) }}%</span
                >
              </ion-col>
              <ion-col size="auto" class="ion-margin-start">
                <ion-icon
                  v-if="leadScore > 0.59"
                  :icon="thumbsUpSharp"
                  class="thumbs-up"
                ></ion-icon>
                <ion-icon
                  v-else
                  :icon="thumbsDownSharp"
                  class="thumbs-down"
                ></ion-icon>
              </ion-col>
            </ion-row>
            <ion-row v-if="leadScoreDetailsVisible" class="mb-2">
              <ion-col class="p-0 mt-1">
                <div
                  v-for="item in leadScoreDetails"
                  :key="item.title"
                  class="pl-4"
                >
                  <div class="grid leadscore-detail">
                    <div class="flex-grow-1 pb-1">
                      <span class="text-10">{{ item.title }}</span>
                      <gradient-progress-bar
                        class="height-5 gradient"
                        :value="item.value"
                      ></gradient-progress-bar>
                    </div>
                    <div class="flex">
                      <div class="ion-margin-start">
                        <span class="font-bold">{{ item.value * 100 }}%</span>
                      </div>
                      <div class="ion-margin-start">
                        <ion-icon
                          v-if="item.value > 0.59"
                          :icon="thumbsUpSharp"
                          class="thumbs-up"
                        ></ion-icon>
                        <ion-icon
                          v-else
                          :icon="thumbsDownSharp"
                          class="thumbs-down"
                        ></ion-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top">
          <ion-col>
            <span class="text-14 font-bold mr-2">Azioni</span>
          </ion-col>
        </ion-row>
        <div id="bottom-component">
          <div class="bottom-component" v-if="tastieraFocus"></div>
        </div>
        <ion-row class="bottoni-attivita my-4">
          <ion-col v-for="sezione in sezioniCrm" :key="sezione.key"
            ><ion-row class="ion-justify-content-center">
              <ion-icon
                :icon="sezione.icon"
                @click="azioneCrm(sezione)"
                style="color: var(--ion-color-primary)"
                class="text-26"
              ></ion-icon> </ion-row
            ><span
              class="flex justify-content-center mt-2 text-align-center text-12"
              >{{ sezione.label }}</span
            ></ion-col
          >
        </ion-row>

        <div
          class="box-azioni"
          v-if="
            sezioniCrm.nuovoDocumento.visible ||
            sezioniCrm.nuovaNota.visible ||
            sezioniCrm.nuovaVisita.visible ||
            sezioniCrm.infoSocialMarketing.visible
          "
        >
          <div v-if="sezioniCrm.nuovoDocumento.visible">
            <div v-if="documentiDisponibili.length > 0">
              <div v-if="loadingDocumentiDisponibili">
                Caricamento in corso...
              </div>
              <!-- <ion-radio-group
                @ionChange="radioCheck($event)"
                :allowEmptySelection="true"
              > -->
              <div v-else>
                <div
                  v-for="doc in documentiDisponibili"
                  :key="doc.id"
                  class="my-4 bottoni-attivita flex justify-content-between align-items-center"
                  @click="showCondividiDocumento(doc)"
                >
                  <ion-icon
                    v-if="selectedDocument"
                    :icon="closeOutline"
                    @click="getDocumentiDisponibili"
                    style="color: var(--ion-color-primary)"
                    class="text-26 mr-3"
                  ></ion-icon>
                  <div class="flex-grow-1 ion-no-margin mr-3">
                    <ion-card style="padding: 1rem 2rem" class="ion-no-margin">
                      {{ doc.title }}
                    </ion-card>
                  </div>
                  <ion-icon
                    :icon="eyeOutline"
                    @click="showAnteprimaDocumento(doc)"
                    style="color: var(--ion-color-primary)"
                    class="text-26"
                  ></ion-icon>
                </div>

                <div v-if="selectedDocument">
                  <div class="share">
                    <p>{{ $t("campaign_social_post.share_on") }}</p>
                    <div class="networks">
                      <ShareNetwork
                        network="facebook"
                        :url="selectedDocument.url"
                        :title="selectedDocument.title"
                        :description="selectedDocument.description"
                        class="facebook"
                        @open="onOpen"
                      >
                        <div class="logo">
                          <ion-icon :icon="logoFacebook"></ion-icon>
                        </div>
                        <div class="name">Facebook</div>
                      </ShareNetwork>
                      <ShareNetwork
                        network="linkedin"
                        :url="selectedDocument.url"
                        :title="selectedDocument.title"
                        :description="selectedDocument.description"
                        class="linkedin"
                        @open="onOpen"
                      >
                        <div class="logo">
                          <ion-icon :icon="logoLinkedin"></ion-icon>
                        </div>
                        <div class="name">LinkedIn</div>
                      </ShareNetwork>
                      <ShareNetwork
                        network="whatsapp"
                        :url="selectedDocument.url"
                        :title="selectedDocument.title"
                        :description="selectedDocument.description"
                        class="whatsapp"
                        @open="onOpen"
                      >
                        <div class="logo">
                          <ion-icon :icon="logoWhatsapp"></ion-icon>
                        </div>
                        <div class="name">WhatsApp</div>
                      </ShareNetwork>
                      <ShareNetwork
                        network="email"
                        :url="selectedDocument.url"
                        :title="selectedDocument.title"
                        :description="selectedDocument.description"
                        class="email"
                        @open="onOpen"
                      >
                        <div class="logo">
                          <ion-icon :icon="mail"></ion-icon>
                        </div>
                        <div class="name">Email</div>
                      </ShareNetwork>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ion-row v-if="showTaskDetails && sezioniCrm.nuovoDocumento.visible">
            <ion-col>
              <div>
                <div class="my-2">
                  <ion-textarea
                    :autoGrow="true"
                    :qutocapitalize="true"
                    :clearonedit="true"
                    v-model="textarea"
                    placeholder="Scrivi messaggio..."
                  ></ion-textarea>
                </div>
              </div>

              <ion-row class="mt-4">
                <ion-col>
                  <span class="text-14 font-bold mr-2">Invia documento</span>
                </ion-col>
              </ion-row>

              <div
                class="bottoni-attivita w-full flex justify-content-around my-2"
              >
                <ion-icon
                  :icon="mail"
                  @click="caricaNuovaNota"
                  style="color: var(--ion-color-primary)"
                  class="text-26"
                ></ion-icon>

                <ion-icon
                  :icon="logoWhatsapp"
                  @click="caricaNuovaNota"
                  style="color: var(--ion-color-primary)"
                  class="text-26"
                ></ion-icon>

                <ion-icon
                  :icon="logoFacebook"
                  @click="caricaNuovaNota"
                  style="color: var(--ion-color-primary)"
                  class="text-26"
                ></ion-icon>

                <ion-icon
                  :icon="copy"
                  @click="caricaNuovaNota"
                  style="color: var(--ion-color-primary)"
                  class="text-26"
                ></ion-icon>
              </div>
            </ion-col>
          </ion-row>

          <div v-if="sezioniCrm.nuovaNota.visible">
            <div class="w-full">
              <div class="ion-margin-bottom ion-padding-bottom">
                <ion-textarea
                  :autoGrow="true"
                  :autocapitalize="true"
                  :clearonedit="true"
                  v-model="textarea"
                  placeholder="Scrivi una nuova nota..."
                ></ion-textarea>
              </div>

              <div class="w-full flex justify-content-end">
                <button @click="salvaNuovaNota" class="p-d button-primary mt-1">
                  Salva
                </button>
              </div>
            </div>
          </div>

          <div v-if="sezioniCrm.nuovaVisita.visible">
            <div class="w-full">
              <div class="ion-margin-bottom ion-padding-bottom">
                <ion-datetime v-model="nuovoEventoData" hour-cycle="h24">
                  <span>Data della visita</span>
                </ion-datetime>
              </div>
              <div class="ion-margin-bottom ion-padding-bottom">
                <ion-textarea
                  :autoGrow="true"
                  :autocapitalize="true"
                  :clearonedit="true"
                  v-model="textarea"
                  placeholder="Scrivi una nuova nota..."
                ></ion-textarea>
              </div>
              <div classs="ion-margin-bottom ion-padding-bottom mx-2 px-2">
                <label>Valutazione interesse: </label>
                <ion-select
                  class="ion-no-padding font-bold"
                  placeholder="Valutazione interesse"
                  interface="popover"
                  v-model="valutazioneInteresse"
                >
                  <ion-select-option
                    v-for="valutazione in interesseOptions"
                    :key="valutazione.value"
                    :value="valutazione.value"
                  >
                    {{ valutazione.text }}
                  </ion-select-option>
                </ion-select>
              </div>
              <div class="my-2 flex justify-content-end">
                <button
                  @click="salvaNuovaVisita"
                  class="p-d button-primary my-2"
                >
                  Salva
                </button>
              </div>
            </div>
          </div>
          <div v-if="sezioniCrm.infoSocialMarketing.visible">
            <div class="w-full">
              <div class="my-2">
                <ion-textarea
                  :autoGrow="true"
                  :qutocapitalize="true"
                  :clearonedit="true"
                  v-model="textarea"
                  placeholder="Scrivi una nuova nota..."
                ></ion-textarea>
              </div>

              <div class="w-full flex justify-content-end">
                <button
                  @click="salvaInfoSocialMarketing"
                  class="p-d button-primary mt-1"
                >
                  Salva
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-3 pb-6 mt-4">
        <ion-row class="ion-margin-top ion-padding-top">
          <ion-col>
            <span class="text-14 font-bold mr-1">Attività svolte </span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div v-for="(task, index) in taskList" :key="index">
              <TimeLineStep
                :position="
                  taskList.length === 1
                    ? 'only'
                    : index === 0
                    ? 'first'
                    : index === taskList.length - 1
                    ? 'last'
                    : 'middle'
                "
                :date="task.data"
                ><div class="flex flex-column align-items-start">
                  <span class="font-bold">{{ task.tipoAzione }}</span>
                  <span>{{ task.testo }}</span>
                </div></TimeLineStep
              >
            </div>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </master-layout>
</template>

<script>
import {
  IonButtons,
  IonDatetime,
  IonModal,
  IonPopover,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonCheckbox,
  IonProgressBar,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonCard,
  modalController,
  IonFabButton,
  IonFab,
  IonRange,
} from "@ionic/vue";
import {
  arrowBackOutline,
  thumbsUpSharp,
  thumbsDownSharp,
  document,
  calendar,
  call,
  browsers,
  footstepsOutline,
  informationCircleOutline,
  eyeOutline,
  closeOutline,
  chevronDown,
  chevronUp,
  mail,
  logoWhatsapp,
  logoFacebook,
  logoLinkedin,
  add,
  copy,
} from "ionicons/icons";
import { defineComponent } from "vue";
import GradientProgressBar from "../../components/GradientProgressBar.vue";
import ModalLeadScore from "../Modals/ModalLeadScore.vue";
import ModalNewAzienda from "../Modals/ModalNewAzienda.vue";
import TaskComponent from "../../components/TaskComponent.vue";
import pdf from "pdfvuer";
import trCard from "@/components/Nuovi/trCard.vue";
import TimeLineStep from "../../components/Nuovi/TimeLineStep.vue";
import ShoppingCartService from "../../common/mixins/ShoppingCart.vue";
import ApiService from "../../common/service.api";
import SocialPost from "@/components/Yoube/SocialPost.vue";
export default defineComponent({
  components: {
    SocialPost,
    pdf,
    IonButtons,
    IonDatetime,
    IonModal,
    IonPopover,
    IonListHeader,
    IonRadio,
    IonRadioGroup,
    IonCheckbox,
    IonProgressBar,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonList,
    IonItem,
    IonTextarea,
    IonCard,
    GradientProgressBar,
    TaskComponent,
    trCard,
    TimeLineStep,
    IonFabButton,
    IonFab,
    IonRange,
    ModalNewAzienda,
  },
  name: "DettagliCliente",
  mixins: [ShoppingCartService],
  created() {
    this.getClienteData();
    this.getAttivitaSvolte();
    this.getStoricoOrdini();
  },
  data() {
    return {
      mail,
      logoWhatsapp,
      logoFacebook,
      logoLinkedin,
      copy,
      client: {},
      clientDetails: {},
      leadScoreDetailsVisible: false,
      chevronUp,
      chevronDown,
      document,
      calendar,
      call,
      add,
      browsers,
      footstepsOutline,
      informationCircleOutline,
      eyeOutline,
      closeOutline,
      documentUrl: "../../../public/sample-pdf.pdf",
      thumbsUpSharp,
      thumbsDownSharp,
      arrowBackOutline,
      aziendaSelezionata: "",
      ragioneSociale: "",
      pIva: null,
      nota: "",
      leadScore: 0,
      leadScoreDetails: [],
      tasksCategories: [{ val: "Invio" }],
      showTaskDetails: false,
      textarea: "",
      datetime: null,
      taskList: [],
      tastieraFocus: false,
      nuovoEventoData: null,
      anagraficaDetailsVisible: false,
      storicoOrdiniVisible: false,
      storicoOrdini: [],
      documentiDisponibili: [],
      loadingDocumentiDisponibili: false,
      selectedDocument: null,
      condividiDocumentoUrl: "",
      valutazioneInteresse: 3,
      interesseOptions: [
        {
          value: 1,
          text: "Molto basso",
        },
        {
          value: 2,
          text: "Basso",
        },
        {
          value: 3,
          text: "Medio",
        },
        {
          value: 4,
          text: "Alto",
        },
        {
          value: 5,
          text: "Molto alto",
        },
      ],
      mapInteresse: {
        1: "Molto basso",
        2: "Basso",
        3: "Medio",
        4: "Alto",
        5: "Molto alto",
      },
      sezioniCrm: {
        nuovoDocumento: {
          visible: false,
          icon: document,
          label: "Invia documento",
          key: "nuovoDocumento",
        },
        nuovaNota: {
          visible: false,
          icon: browsers,
          label: "Nota",
          key: "nuovaNota",
        },
        nuovaVisita: {
          visible: false,
          icon: footstepsOutline,
          label: "Registra visita",
          key: "nuovaVisita",
        },
        infoSocialMarketing: {
          visible: false,
          icon: informationCircleOutline,
          label: "Informazioni Social/Marketing",
          key: "infoSocialMarketing",
        },
      },
    };
  },

  methods: {
    async openModalNewClient() {
      const modal = await modalController.create({
        component: ModalNewAzienda,
        cssClass: "my-custom-class",
        componentProps: {
          anagrafica: {
            ...this.client,
            id: this.$route.params.idCliente,
          },
        },
      });

      modal.onDidDismiss().then(() => {
        this.getClienteData();
      });

      return modal.present();
    },
    salvaNuovaVisita() {
      this.postNota({
        tipoAzione: "Nuova Visita",
        testo: `Interesse: ${this.mapInteresse[this.valutazioneInteresse]}, ${
          this.textarea
        }`,
        DateTime: this.nuovoEventoData || new Date().toISOString(),
      });
    },
    salvaInfoSocialMarketing(e) {
      this.postNota({
        tipoAzione: "Info Social Marketing",
        testo: this.textarea,
        DateTime: new Date().toISOString(),
      });
    },
    salvaNuovaNota() {
      console.log("salvaNuovaNota");
      this.setFocusFalse();
      this.postNota({
        tipoAzione: "Nota",
        testo: this.textarea,
        DateTime: new Date().toISOString(),
      });
    },
    showAnteprimaDocumento(documento) {
      console.log(documento);
      window.open(documento.fileAllegato, "_blank");
    },
    getStoricoOrdini() {
      this.storicoOrdini.splice(0);
      ApiService.get(
        `Ecommerce/GetOrdersByClient/${this.$route.params.idCliente}`
      )
        .then((res) => {
          this.storicoOrdini = [...res.data];
          this.storicoOrdini.map((ordine) => {
            ordine.collapsed = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAttivitaSvolte() {
      this.taskList.splice(0);
      ApiService.get(`Crm/GetHistory/${this.$route.params.idCliente}`)
        .then((res) => {
          this.taskList = [...res.data];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    postNota({ tipoAzione, testo, DateTime }) {
      ApiService.post(`Crm/AddHistoryRecord/${this.$route.params.idCliente}`, {
        tipoAzione: tipoAzione,
        testo: testo,
        DateTime: DateTime,
      })
        .then((res) => {
          console.log(res);

          this.getClienteData();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.textarea = "";
          this.nuovoEventoData = null;
          this.getAttivitaSvolte();
          this.resetAzioniCrm();
        });
    },

    showCondividiDocumento(document) {
      this.selectedDocument = null;
      this.condividiDocumentoUrl = "";
      this.documentiDisponibili.splice(0);
      this.selectedDocument = document;
      this.condividiDocumentoUrl = document.url;
      this.documentiDisponibili.push(this.selectedDocument);
    },
    convertCamelCaseToTitleCase(str) {
      // Split the string at each capital letter or number
      const words = str.split(/(?=[A-Z0-9])/);

      // Capitalize the first letter of each word and join them with spaces
      const result = words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return result;
    },
    apriCatalogo() {
      //set customer in store
      this.setCustomer(this.client);
      this.$router.push("/catalogo");
      this.closeModal();
    },
    toggleShowLeadScoreDetails() {
      this.leadScoreDetailsVisible = !this.leadScoreDetailsVisible;
    },
    toggleShowAnagraficaDetails() {
      this.anagraficaDetailsVisible = !this.anagraficaDetailsVisible;
    },
    toggleShowStoricoOridini() {
      this.storicoOrdiniVisible = !this.storicoOrdiniVisible;
    },

    azioneCrm(sezione) {
      for (let sez in this.sezioniCrm) {
        if (this.sezioniCrm[sez].key == sezione.key) {
          this.sezioniCrm[sez].visible = true;
        } else {
          this.sezioniCrm[sez].visible = false;
        }

        if (sezione.key == "nuovoDocumento") {
          this.getDocumentiDisponibili();
        }

        if (sezione.key == "nuovaNota") {
          this.setFocusTrue();
        }
      }
    },
    resetAzioniCrm() {
      for (let sez in this.sezioniCrm) {
        this.sezioniCrm[sez].visible = false;
      }
    },
    setFocusTrue() {
      this.tastieraFocus = true;
      if (this.tastieraFocus) this.scrollDown();
    },
    setFocusFalse() {
      this.tastieraFocus = false;
    },
    scrollDown() {
      const element = window.document.querySelector("#bottom-component");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },

    async getDocumentiDisponibili() {
      this.loadingDocumentiDisponibili = true;
      this.documentiDisponibili.splice(0);
      ApiService.get("CampaignManager/GetUserLandings/0")
        .then((res) => {
          this.documentiDisponibili = res.data.map((item) => {
            return {
              ...item,
              key: "youbeLanding_" + item.id,
              title: item.title["it-IT"],

              url:
                this.settings.url_landing +
                "/" +
                encodeURI(item.title["it-IT"]) +
                "/" +
                encodeURI(item.id) +
                "/" +
                encodeURI(this.client.id),
            };
          });
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loadingDocumentiDisponibili = false;
          this.selectedDocument = null;
        });
    },
    closeModal() {
      return modalController.dismiss();
    },
    chiama(item) {
      alert("Sto chiamando " + item.name);
    },
    radioCheck(e) {
      this.showTaskDetails = e.detail.value;
    },
    getClienteData() {
      this.leadScoreDetails.splice(0);
      this.client = {};
      this.clientDetails = {};
      ApiService.post(`Ecommerce/GetAnag/${this.$route.params.idCliente}`).then(
        (res) => {
          this.client = { ...res.data };
          this.leadScoreDetails = res.data.ratings;
          this.leadScore = res.data.hotness;

          this.clientDetails = res.data;

          delete this.clientDetails.id;
          delete this.clientDetails.idUtente;
          delete this.clientDetails.idCliente;
          delete this.clientDetails.idTipoAnagrafica;
          delete this.clientDetails.idPadre;
          delete this.clientDetails.nazione;
          delete this.clientDetails.anagraficaYoube;
          delete this.clientDetails.hotness;
          delete this.clientDetails.ratings;
        }
      );
    },
    pinFormatter(value) {
      switch (value) {
        case 1:
          return "Molto basso";
        case 2:
          return "Basso";
        case 3:
          return "Medio";
        case 4:
          return "Alto";
        case 5:
          return "Molto alto";
      }
    },
  },
});
</script>

<style scoped lang="scss">
ion-grid {
  width: 90%;
  margin: auto;
}

ion-label {
  font-size: 0.8rem;
}

ion-radio::part(container) {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 2px solid grey;
}

ion-radio::part(mark) {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  --color-checked: var(--ion-color-primary);
}

ion-textarea {
  border: 1px solid grey;
  border-radius: var(--ion-border-radius);
  --placeholder-opacity: 0.2;
  padding: 0.5rem 1rem;
}

.checkbox {
  height: 40px;
}

.details {
  font-size: 0.8rem;
  text-decoration: underline;
}

.hr {
  width: 100%;
  height: 1px;
  margin-bottom: 2px;
  background: var(--ion-color-primary);
}

.no-padd-no-mar {
  width: 100%;
  padding: 0;
  margin: 0;
}

.place-center {
  display: grid;
  place-items: center;
}

.arrow-back {
  padding-top: 0.5rem;
  font-size: 1.5rem;
}

.thumbs-up {
  color: green;
}
.thumbs-down {
  color: red;
}

.bottoni-attivita {
  ion-icon {
    border: 1px solid var(--ion-color-primary);
    border-radius: 50%;
    background: var(--ion-color-primary);
    color: #fff !important;
    padding: 0.6rem;
  }
}

.bg-ground2 {
  background: var(--client-ground2-color);
}

.bg-ground1 {
  background: var(--client-ground1-color);
}

.leadscore-detail {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5px 5px 0;
}

.share {
  border-top: 1px solid rgba(0, 0, 0, 0.24);
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  margin: 20px 0 5px;
  padding: 20px 0;
  color: #000;

  .networks > * {
    width: 25%;
    float: left;
    text-align: center;
    text-decoration: none;
    margin: 10px 0 0;

    ion-icon {
      height: 50px;
      width: 50px;
      max-height: 80%;
      max-width: 80%;

      -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.2));
      filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.2));
    }

    .name {
      //-webkit-filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, .2));
      //filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, .2));
      font-weight: bold;
      font-size: 70%;
    }
  }

  .networks:after {
    content: "";
    clear: both;
    display: block;
  }
}

.box-azioni {
  border: 1px solid lightgray;
  border-radius: 17px;
  padding: 2rem 1rem;
}

.bottom-component {
  width: 100%;
}
</style>
