<template>
  <master-layout :pageTitle="'Catalogo prodotti'">
    <div style="padding-bottom: 100px">
      <ion-grid>
        <ion-row class="table-header">
          <ion-col>
            <ion-row>
              <ion-col> Seleziona il cliente </ion-col>
            </ion-row>
            <ion-row>
              <ion-col
                ><ion-select
                  v-model="clienteSelezionato"
                  placeholder="Seleziona cliente..."
                >
                  <ion-select-option
                    v-for="azienda of aziende"
                    :key="azienda.id"
                  >
                    {{ azienda.name }} {{ azienda.surname }}
                  </ion-select-option>
                </ion-select></ion-col
              >
            </ion-row>
            <ion-row>
              <ion-col size="3">Foto</ion-col>
              <ion-col size="4">Nome</ion-col>
              <ion-col size="3">Info</ion-col>
              <ion-col size="2">Prezzo </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row
          @dismissed="console"
          class="riga-prodotto"
          v-for="item of items"
          :key="item.code"
        >
          <ion-col @click="openModalDettaglioItem(item)" size="3">
            <img :src="item.pic" @error="handleImgError"
          /></ion-col>
          <ion-col @click="openModalDettaglioItem(item)" size="4">
            {{ item.name }}
          </ion-col>
          <ion-col @click="openModalDettaglioItem(item)" size="3">
            {{ item.format }}
          </ion-col>
          <ion-col @click="openModalDettaglioItem(item)" size="2">
            € {{ item.price.toFixed(2) }}
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div
      style="
        position: fixed;
        bottom: 0;
        background: white;
        width: 90%;
        padding: 1rem 2rem;
      "
    >
      <ion-row>
        <ion-col size="8">
          <ion-row>
            <ion-col> Elementi: 2 </ion-col>
          </ion-row>
          <ion-row>
            <ion-col> Totale: 55,00 € </ion-col>
          </ion-row>
        </ion-col>
        <ion-col size="4">
          <ion-button @click="openModalCarrello">Visualizza</ion-button>
        </ion-col>
      </ion-row>
    </div>
  </master-layout>
</template>

<script>
import { IonCol, IonGrid, IonRow, modalController } from "@ionic/vue";
import YoubeSingleItem from "./YoubeSingleItem.vue";
import YoubeDettaglioOrdine from "./YoubeDettaglioOrdine.vue";
export default {
  name: "catalogo",
  components: {
    IonCol,
    IonGrid,
    IonRow,
    YoubeSingleItem,
    YoubeDettaglioOrdine,
  },
  data() {
    return {
      clienteSelezionato: {},
      items: [
        {
          code: "7890",
          name: "Via Lactea",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/09/detersione-smart__gentle-cleasing-milk-prod-960x960.jpg",
          description:
            "Primario e primordiale il latte detergente di yoUBe attraversa il mito e la leggenda, per trasportarti dritto al centro di un nutrimento elementare. Una delicatezza galattica per una pelle divina.",
          format: "200 ml",
          price: 19.0,
          promo: 5,
          number: 0,
        },
        {
          code: "3486",
          name: "Gioia Infinita",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/detersione-smart__glow-tonic-prod-960x960.jpg",
          description:
            "Un flusso di radioso entusiasmo inonda la voglia della tua pelle, fremente di detersione giooiosa, pronta ad accogliere tutta l’energia di un nuovo trattamento.",
          format: "200 ml",
          price: 15.0,
          promo: 4,
          number: 0,
        },
        {
          code: "9234",
          name: "Vita Forte",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/09/detersione-smart__micellar-water-prod-960x960.jpg",
          description:
            "Forte come la vita che ti scorre incontro, l’acqua micellare di yoUBe riconduce la tua epidermide a una condizione originale. Intensamente gentile, rimuove trucco e impurità, idrata e protegge: 3 volte in 1.",
          format: "200",
          price: 15.0,
          promo: 3,
          number: 0,
        },
        {
          code: "1122",
          name: "A+MORE",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/detersione-smart__purifyng-face-scrub-prod-960x960.jpg",
          description:
            "Volersi + bene è una bellezza, un atto che libera ed emoziona ogni cosa. A levare in crescendo – e lasciare il superfluo – lo scrub viso di yoUBe t’innamora di te, purificando la tua pelle con il giusto sentimento.",
          format: "150 ml",
          price: 20.0,
          promo: 6,
          number: 0,
        },
        {
          code: "4094",
          name: "Mia Brama",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/anti-age__eye-lip-contour-cream-prod-960x960.jpg",
          description:
            "Intorno agli occhi e al contorno labbra, nello specchio del tempo più ardente di te. Il trattamento ridensificante globale di yoUBe: la migliore espressione del tuo desiderio.",
          format: "15 ml",
          price: 35.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "L'Assoluta",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/anti-age__hydrating-face-cream-prod-960x960.jpg",
          description:
            "A suprema immagine della bellezza è la crema viso anti-age di yoUBe: un concentrato di vitalità multifunzionale. Per ritrovare, di giorno in notte, l’essenza pura della tua pelle.",
          format: "50 ml",
          price: 55.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "D'Incanto",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/anti-age__face-cerum-prod-960x960.jpg",
          description:
            "Come un istante di meraviglia, un sortilegio o un sogno, il siero di yoUBe accarezza la tua pelle e se ne prende dolcemente cura. Contrastando le rughe e  ’invecchiamento, a sfidare le regole della gravità.",
          format: "50 ml",
          price: 60.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "AnimA",
          pic: "https://youbecosmetics.com/wp-content/uploads/2022/10/trattamenti-speciali__defence-repair-prod-960x960.jpg",
          description:
            "Schermo allo schermo, fluido al siero, il trattamento speciale di yoUBe porta alla luce riparo, proteggendo dal photoaging e rimediando ai suoi danni. Una combinazione sinergica, a strategia complementare.",
          format: "30 + 15 ml",
          price: 59.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "bustina acqua macellare - 1,5 ml 1 pz",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "VITA FORTE -bustina acqua macellare - 1,5 ml 1 pz",
          format: "1,5 ml",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "bustina eyes&lips countour - 1,5 ml 1 pz",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "mia brama -bustina eyes&lips countour - 1,5 ml 1 pz",
          format: "1,5 ml",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "bustina face cr antiage - 1,5 ml 1 pz",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "l’assoluta - bustina face cr antiage - 1,5 ml 1 pz",
          format: "1,5 ml",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "bustina face serum filler - 1,5 ml 1 pz",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "d’incanto - bustina face serum filler - 1,5 ml 1 pz",
          format: "1,5 ml",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "bustina gentle cleaning milk - 1,5 ml 1 pz",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description:
            "VIA LACTEA - bustina gentle cleaning milk - 1,5 ml 1 pz",
          format: "1,5 ml",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "bustina glow tonic - 1,5 ml 1 pz",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "GIOIA INFINITA - bustina glow tonic - 1,5 ml 1 pz",
          format: "1,5 ml",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "bustina purifyng face scrub - 1,5 ml 1 pz",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "A+MORE - bustina purifyng face scrub - 1,5 ml 1 pz",
          format: "1,5 ml",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "Tester Defense SPF 50 Pz. 1",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "Tester Defense SPF 50 Pz. 1",
          format: "50 Pz. 1",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "Tester EYE & LIP counter cream antiage 1 Pz.",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "Tester EYE & LIP counter cream antiage 1 Pz.",
          format: "1 Pz",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "Tester Repair Serum 1 Pz.",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "Tester Repair Serum 1 Pz.",
          format: "1 Pz",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "Tester Glow Tonic 1 Pz.",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "Tester Glow Tonic 1 Pz.",
          format: "1 Pz",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "Espositore MB",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "Espositore MB",
          format: "",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "Espositore GAM",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "Espositore GAM",
          format: "",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "pochette yoUbe cotone organico certificato",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "pochette yoUbe cotone organico certificato",
          format: "1 Pz",
          price: 0.0,
          promo: 5,
          number: 0,
        },
        {
          code: "4094",
          name: "leaflets yoUBe gamma 2022",
          pic: "https://scontent.fcnq2-1.fna.fbcdn.net/v/t39.30808-6/310627468_406568314973570_1166605210992267358_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=SdrxCmDW-V4AX_ilkhu&_nc_ht=scontent.fcnq2-1.fna&oh=00_AfDcFE0WiCGzpZ-6rNE59nIpx1Totz6RdgPvoTAUqjRzpA&oe=63C5BE1A",
          description: "leaflets yoUBe gamma 2022",
          format: "100 Pz",
          price: 0.0,
          promo: 5,
          number: 0,
        },
      ],
      aziende: [
        {
          id: 1,
          name: "Mario",
          surname: "Pagano",
          pIva: 83094275483,
          telefono: "389 45611311",
        },
        {
          id: 2,
          name: "Giorgia",
          surname: "De Luca",
          pIva: 30489263483,
          telefono: "333 38381625",
        },
        {
          id: 3,
          name: "Alice",
          surname: "Rizzo",
          pIva: 12345678912,
          telefono: "334 23877723",
        },
        {
          id: 4,
          name: "Lorenzo",
          surname: "Barbieri",
          pIva: 21987654321,
          telefono: "324 34439284",
        },
        {
          id: 5,
          name: "Andrea",
          surname: "Villa",
          pIva: 12098744738,
          telefono: "368 34923900",
        },
      ],
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    console() {
      console.log("ciao mamma");
    },
    async openModalDettaglioItem(item) {
      const modal = await modalController.create({
        component: YoubeSingleItem,
        cssClass: "my-custom-class",
        componentProps: {
          item: item,
        },
      });
      return modal.present();
    },
    async openModalCarrello(item, counter) {
      let cliente = this.clienteSelezionato;
      const modal = await modalController.create({
        component: YoubeDettaglioOrdine,
        cssClass: "my-custom-class",
        componentProps: {
          item: item,
          counter: counter,
          cliente: cliente,
        },
      });
      return modal.present();
    },
  },
};
</script>

<style scoped>
ion-grid {
  font-size: 0.8rem;
  /* font-weight: bolder; */
  color: grey;
}

img {
  border-radius: 4px;
}

.titolo {
  text-align: center;
  font-size: 2rem;
  font-weight: bolder;
}

.table-header ion-col {
  font-weight: bold;
}

.riga-prodotto ion-col {
  display: grid;
  place-items: center;
}
</style>
