<template>
  <master-layout :showHeader="true">
    <ion-card class="box">
      <ion-row>
        <ion-col>
          <div>
            <ion-row class="title">
              <ion-col>
                <h2>Cambia Password</h2>
              </ion-col>
            </ion-row>

            <ion-row class="content">
              <ion-col size="12">
                <ion-row>
                  <ion-col size="12">
                    <div class="password">
                      <ion-item
                        color="newcolor"
                        class="ion-margin-top ion-margin-bottom"
                        ><ion-label position="stacked"
                          >Nuova Password</ion-label
                        >
                        <ion-input
                          v-model="password"
                          :type="passwordVisible ? 'text' : 'password'"
                          minlength="8"
                          :class="{ valid: passwordValidation.valid }"
                        ></ion-input
                      ></ion-item>
                      <div class="eye-icon">
                        <ion-icon
                          v-if="passwordVisible"
                          tabindex="-1"
                          @click="togglePasswordVisibility"
                          :icon="eye"
                        ></ion-icon>
                        <ion-icon
                          v-else
                          tabindex="-1"
                          @click="togglePasswordVisibility"
                          :icon="eyeOff"
                        ></ion-icon>
                      </div>
                    </div>

                    <div class="password">
                      <ion-item
                        color="newcolor"
                        class="ion-margin-top ion-margin-bottom"
                        ><ion-label position="stacked"
                          >Conferma Password</ion-label
                        >
                        <ion-input
                          v-model.lazy="checkPassword"
                          :type="passwordVisible ? 'text' : 'password'"
                          minlength="8"
                        ></ion-input
                      ></ion-item>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <transition name="hint" appear>
              <div
                v-if="passwordValidation.errors.length > 0 && !submitted"
                class="hints"
              >
                <h5 style="margin: 0 0 0.5rem 0">Attenzione</h5>
                <p v-for="error in passwordValidation.errors" :key="error">
                  {{ error }}
                </p>
              </div>
            </transition>

            <div class="matches" v-if="notSamePasswords">
              <p>Passwords don't match.</p>
            </div>

            <ion-row class="ion-justify-content-center">
              <ion-col size="auto">
                <ion-button
                  @click="resetPasswords"
                  v-if="
                    passwordsFilled &&
                    !notSamePasswords &&
                    passwordValidation.valid
                  "
                >
                  Submit
                </ion-button>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
    </ion-card>
  </master-layout>
</template>

<script>
import { eye, eyeOff } from "ionicons/icons";
import ApiService from "../common/service.api";

// import md5 from "md5";

import {
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
export default {
  components: {
    IonButton,
    IonLabel,
    IonInput,
    IonItem,
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
  },
  name: "ChangePassword",
  data() {
    return {
      eye,
      eyeOff,
      rules: [
        { message: "Usa almeno una lettera minuscola", regex: /[a-z]+/ },
        { message: "Usa almeno una lettera maiuscola", regex: /[A-Z]+/ },
        { message: "Usa almeno 8 caratteri", regex: /.{8,}/ },
        { message: "Usa almeno un numero", regex: /[0-9]+/ },
      ],
      password: "",
      checkPassword: "",
      passwordVisible: false,
      submitted: false,
    };
  },
  methods: {
    resetPasswords() {
      var md5 = require("md5");
      let newPass = md5(this.password);

      ApiService.put("UserUtility/ChangePassword", {
        newPassword: newPass,
      }).then((res) => {
        if (res.data) {
          console.log("PASSWORD CAMBIATA");
          this.$router.push("/confermacambiopassword");
        } else {
          console.error("Errore nel cambio password: ", res);
        }
      });

      this.password = "";
      this.checkPassword = "";
      this.submitted = true;
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
  computed: {
    md5Password(string) {
      var md5 = require("md5");
      return md5(string);
    },
    notSamePasswords() {
      if (this.passwordsFilled) {
        return this.password !== this.checkPassword;
      } else {
        return false;
      }
    },
    passwordsFilled() {
      return this.password !== "" && this.checkPassword !== "";
    },
    passwordValidation() {
      let errors = [];
      for (let condition of this.rules) {
        if (!condition.regex.test(this.password)) {
          errors.push(condition.message);
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors };
      } else {
        return { valid: false, errors };
      }
    },
  },
};
</script>

<style scoped lang="scss">
$bgColor: #3a81c8;
$color: #d4dedf;

// * {
//   border: 1px solid salmon;
// }

// [v-cloak] {
//   opacity: 0;
// }

.hints {
  max-width: 400px;
  padding: 1em;
  background: whitesmoke;
  margin: 1rem 0;
  font-size: 0.9em;
  color: #808080;
  h2 {
    margin: 0.5em 0 0.2em 0;
  }
  p {
    font-size: 1rem;
    margin: 0;
    padding-left: 1em;
    &::before {
      content: "-";
      font-size: 0.9em;
      margin-right: 6px;
      display: inline-block;
    }
  }
}

.password {
  position: relative;
  width: 85%;
}

input [type="password"],
input [type="text"] {
  width: 500px;
  color: var(--text-color-light);
  border: 1px solid transparent;
  background: transparent;
  margin: 10px auto;
  &.valid {
    border: 1px solid whitesmoke;
  }
}
[type="password"] {
  letter-spacing: 0.2em;
}

.hint {
  &-enter {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &-leave-to {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  &-enter-active {
    transition: 300ms;
  }
  &-leave-active {
    transition: 400ms;
  }
}

.page {
  display: grid;
  place-items: center;

  width: 100%;
  height: 85vh;
  margin: 0;
  padding: 0;
}

.box {
  width: 90%;
  max-width: 400px;
  padding: 1rem;
}

.ion-color-newcolor {
  --ion-color-base: transparent;
  --ion-color-contrast: var(--text-color-light);
}

.eye-icon {
  position: absolute;
  top: 25px;
  right: -50px;
}
ion-icon {
  font-size: 2rem;
}
</style>
