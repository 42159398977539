<template>
  <div class="network-chart-wrapper">
    <div class="position-relative" ref="network_chart">
      <div v-for="user in dataCompanyTree" :key="user.id" class="network-row">
        <network-user
          :user="user"
          :active_user="active_user_id"
          :roles="roleTypeList"
          @add-user-coordinate="addUserCoordinate"
        />
      </div>

      <svg class="svg-absolute">
        <line
          v-for="(line, index) in lines"
          :key="'lines_' + index"
          class="line"
          :x1="line.x1"
          :y1="line.y1"
          :x2="line.x2"
          :y2="line.y2"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import NetworkUser from "./NetworkUser.vue"
import ApiService from "../common/service.api"

export default {
  name: "Network",
  props: { id: Number, company: Number },
  components: {
    NetworkUser,
  },
  mounted() {
    ApiService.get("User").then((res) => {
      this.list = res.data
      this.dataCompanyTree = this.companyTree()
    })
    ApiService.get("Role").then((res) => {
      this.roleTypeList = res.data.filter((item) => {
        return item.id_company == this.company
      })
    })
  },
  data() {
    return {
      list: null,
      roleTypeList: [],
      employee: "",
      active_user_id: null,
      user_coordinates: [],
      dataCompanyTree: [],
      companyList: [],
    }
  },
  methods: {
    drawSVGLine(rect1, rect2) {
      var parent = this.$refs["network_chart"]
      var rectParent = parent.getBoundingClientRect()
      const x_length = 12

      var x1 = rect1.left + rect1.width + x_length - rectParent.left
      var y1 = rect1.top + rect1.height / 2 - rectParent.top
      var x2 = rect2.left - x_length - rectParent.left
      var y2 = rect2.top + rect2.height / 2 - rectParent.top

      return [
        {
          x1: x1 - x_length,
          y1: y1,
          x2: x1,
          y2: y1,
        },
        {
          x1: x1,
          y1: y1,
          x2: x2,
          y2: y2,
        },
        {
          x1: x2,
          y1: y2,
          x2: x2 + x_length,
          y2: y2,
        },
      ]
    },
    addUserCoordinate(id, rect) {
      this.user_coordinates.push({ id: id, rect: rect })
    },
    searchTree(element, id, father) {
      if (element.id == id) {
        // if (father) {
        //   this.$emit("setTutor", father);
        // }
        return element
      } else if (element.children != null) {
        let i
        let result = null
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchTree(element.children[i], id, element)
        }
        return result
      }
      return null
    },
    companyTree() {
      if (this.list && this.company) {
        this.companyList = this.list.filter((item) => {
          if (this.company && item.id_company != this.company) {
            return false
          }
          return true
        })
      }

      if (!this.companyList) return []
      let map = {},
        node,
        roots = [],
        i
      for (i = 0; i < this.companyList.length; i += 1) {
        map[this.companyList[i].id] = i // initialize the map
        this.companyList[i].children = [] // initialize the children
      }
      for (i = 0; i < this.companyList.length; i += 1) {
        node = this.companyList[i]
        if (node.id_parent && node.id_parent !== "0") {
          this.companyList[map[node.id_parent]].children.push(node)
        } else {
          roots.push(node)
        }
      }

      let root = { id: 0, children: roots }
      let searchResult = this.searchTree(root, this.id)
      return searchResult ? [searchResult] : []
    },
  },
  computed: {
    lines() {
      let lines = []
      this.companyList.forEach((user) => {
        const rect1 = this.user_coordinates.find((x) => {
          return x.id === user.id
        })
        if (rect1 && user.children) {
          user.children.forEach((child) => {
            const rect2 = this.user_coordinates.find((x) => {
              return x.id === child.id
            })
            if (rect2) {
              let line_components = this.drawSVGLine(rect1.rect, rect2.rect)
              line_components.forEach((line) => {
                lines.push(line)
              })
            }
          })
        }
      })

      return lines
    },
  },
}
</script>

<style lang="scss">
.position-relative {
  position: relative;
}

.network-chart-wrapper {
  width: 100%;
  overflow: scroll;
}

.network_user {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-secondary);
  font-size: 0.84rem;
  padding: 0.49rem;
  opacity: 1;
  border: 1px solid rgba(0, 0, 0, 0);
  width: 11.2rem;
  min-width: 11.2rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;

  &.active {
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: #ececec;
  }

  &.active_user {
    border: 1px solid #007bff88;
    box-shadow: 0px 0px 4px #007bff88;
  }

  .network-role {
    font-size: 10.5px;
  }
}

.svg-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: visible !important;
  z-index: 1000;
}

.line {
  stroke-width: 2px;
  stroke: #555;
  shape-rendering: crispEdges;
}
</style>
