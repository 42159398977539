<template>
  <ion-grid :class="{ disabled: !item.status }">
    <div class="w-100">
      <ion-row id="image" class="ion-justify-content-center">
        <ion-col size="auto">
          <img v-if="item.image" :src="item.image" @error="handleImgError" />
          <img
            v-else
            src="../../public/assets/img/award_placeholder.png"
            @error="handleImgError"
          />
        </ion-col>
      </ion-row>
      <ion-row id="text" class="ion-justify-content-center">
        <ion-col size="auto">
          {{ item.text }}
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
export default {
  name: "SmallWidget",
  props: {
    item: Object,
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}

.disabled {
  opacity: 0.4;
}

/* .w-100 {
  width: 100%;
  margin: auto;
} */
</style>
