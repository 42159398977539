<template>
  <master-layout
    :showMenu="showMenu"
    :showBack="showBack"
    :pageTitle="pageTitle"
    :showInfoPophover="false"
  >
    <slot></slot>
    <template v-slot:footer>
      <ion-toolbar
        style="
          border: none;
          padding-left: 10px;
          padding-right: 10px;
          min-height: 10px;
        "
        v-if="showMenu"
        ><div class="campaign-menu">
          <a
            @click="$router.push(defaultCampaignUrl)"
            v-if="!disableMain"
            class="campaign-menu__button left-menu"
            :class="selected('main')"
          >
            <ion-icon src="/assets/icon/campaign-kpi.svg" />
          </a>
          <a
            v-if="!disableBadge"
            @click="$router.push('/campaign/badge/' + id)"
            class="campaign-menu__button"
            :class="selected('badge')"
          >
            <ion-icon src="/assets/icon/campaign-badge.svg" />
          </a>
          <a
            v-if="!disableRanking"
            @click="$router.push('/campaign/ranking/' + id)"
            class="campaign-menu__button center-menu"
            :class="selected('ranking')"
          >
            <ion-icon src="/assets/icon/campaign-rank.svg" />
          </a>
          <a
            v-if="!disableAward"
            @click="$router.push('/campaign/award/' + id)"
            class="campaign-menu__button right-menu"
            :class="selected('award')"
          >
            <ion-icon src="/assets/icon/campaign-reward.svg" />
          </a>
        </div>
      </ion-toolbar>

      <div v-if="showInfoPopHover" class="info-pophover">
        <div id="click-trigger" class="click-trigger">i</div>
        <ion-popover trigger="click-trigger" trigger-action="click">
          <ion-content class="ion-padding">{{ infoPopHover }}</ion-content>
        </ion-popover>
      </div>
    </template>
  </master-layout>
</template>

<script>
import { add } from "ionicons/icons";
import {
  IonToolbar,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon,
  IonFab,
  IonFabButton,
  IonRouterOutlet,
  IonAvatar,
  IonContent,
  IonPopover,
} from "@ionic/vue";
import { useRoute } from "vue-router";
import CampaignService from "../common/mixins/Campaign";

export default {
  name: "CampaignLayout",
  components: {
    IonIcon,
    IonFab,
    IonFabButton,
    IonToolbar,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonRouterOutlet,
    IonAvatar,
    IonContent,
    IonPopover,
  },
  data() {
    return {
      disableMain: false,
      disableBadge: false,
      disableRanking: false,
      disableAward: false,
      defaultCampaignUrl: null,
      currentRouteName: null,
    };
  },
  props: {
    pageTitle: {},
    showMenu: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    showInfoPopHover: {
      type: Boolean,
      default: false,
    },
    infoPopHover: {
      type: String,
      default: null,
    },
  },
  mixins: [CampaignService],
  setup() {
    const route = useRoute();
    const { id } = route.params;
    return {
      add,
      id,
    };
  },
  methods: {
    selected(route) {
      const map = {
        campaign_kpi: "main",
        campaign_learning: "main",
        campaign_social: "main",
        campaign_social_post: "main",
        campaign_badge: "badge",
        campaign_ranking: "ranking",
        campaign_award: "award",
        campaign_menu: "main",
        campaign_journey_menu: "main"
      };
      if (map[this.currentRouteName]) {
        if (map[this.currentRouteName] === route) {
          return route != "badge"
            ? "tab-selected-c"
            : this.disableMain
            ? "left-menu tab-selected-c"
            : "center-menu tab-selected-c";
        }
      }
      return route != "badge"
        ? ""
        : this.disableMain
        ? "left-menu "
        : "center-menu ";
    },
  },
  mounted() {
    const that = this;
    this.getCampaigns().then((data) => {
      const currentCampaign = data.find(
        (c) => c.id == that.$root.$route.params.id
      );
      if (this.whappyClientConfig.name == "Yeea") {
        this.defaultCampaignUrl = "/campaigns";
      } else {
        this.defaultCampaignUrl = this.getCampaignDefaultUrl(
          currentCampaign,
          true
        );
      }

      if (this.isOnBoarding(currentCampaign)) {
        if (this.whappyClientConfig.name == "Yoube") {
          this.disableMain = false;
        } else {
          this.disableMain = true;
        }

        this.disableRanking = true;
      } else if (this.isDataVisualization(currentCampaign)) {
        this.disableAward = true;
        this.disableBadge = true;
        this.disableMain = true;
        this.disableRanking = true;
      } else {
        this.disableMain = false;
        this.disableRanking = false;
      }
    });
    this.currentRouteName = this.$route.name;
  },
  watch: {
    "$route.name": function () {
      this.currentRouteName = this.$route.name;
    },
  },
};
</script>

<style scoped lang="scss">
ion-toolbar,
ion-tabs,
ion-tab-bar {
  // min-height: 60px;

  .icon-inner {
    .bottom-bar__icon {
      color: red;
      min-height: 50px;
    }
  }
}

.info-pophover {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  .click-trigger {
    color: black;
    display: grid;
    place-content: center;
    padding-bottom: 2px;
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 50%;
  }
}
</style>
