<template>
  <master-layout :key="$route.name"> </master-layout>
  <!-- <master-layout :pageTitle="$t('pageTitle.Welcome')"> </master-layout> -->
</template>

<script>
// import {
//   IonIcon,
//   IonButton,
// } from "@ionic/vue";

import { arrowForward } from "ionicons/icons"
import ApiService from "../common/service.api"
import { translate } from "../common/helper.auth"
import { alertController, IonicSafeString } from "@ionic/vue"
import moment from "moment"

export default {
  name: "Welcome",
  components: {
    // IonIcon,
    // IonButton,
  },
  setup() {
    return {
      // arrowForward
    }
  },
  methods: {
    async showInstallPrompt() {
      const shownPrompt = await this.$root.getLocalStorage("shown_install")
      let shouldShowInstallPrompt = true
      if (shownPrompt && moment().unix() - shownPrompt < 86400) {
        // console.log('shown recently');
        shouldShowInstallPrompt = false
      }
      // How app started
      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches
      if (document.referrer.startsWith("android-app://")) {
        shouldShowInstallPrompt = false
      } else if (navigator.standalone || isStandalone) {
        shouldShowInstallPrompt = false
      }

      // shouldShowInstallPrompt = true;
      // window.deferredPrompt = 1;

      if (window.deferredPrompt && shouldShowInstallPrompt) {
        const alert = await alertController.create({
          cssClass: "ion-alert-big",
          header: "Istallazione",
          message:
            "Vuoi installare l'applicazione direttamente nel telefono per averla comoda a portata di mano?",
          buttons: [
            {
              text: "No, grazie",
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                this.$root.setLocalStorage("shown_install", moment().unix())
                // console.log('Confirm Cancel:', blah)
              },
            },
            {
              text: "Sì, procedi",
              handler: () => {
                window.deferredPrompt.prompt()
              },
            },
          ],
          // header: this.$t('install.title'),
          // message: this.$t('install.text'),
          // buttons: [
          //   {
          //     text: 'Cancel',
          //     role: 'cancel',
          //     cssClass: 'secondary',
          //     handler: blah => {
          //       this.$root.setLocalStorage('shown_install', moment().unix());
          //       // console.log('Confirm Cancel:', blah)
          //     },
          //   },
          //   {
          //     text: this.$t('install.ok'),
          //     handler: () => {
          //       window.deferredPrompt.prompt();
          //     },
          //   },
          // ],
        })
        return alert.present()

        // Wait for the user to respond to the prompt
        // const { outcome } = await deferredPrompt.userChoice;
        // console.log(`User response to the install prompt: ${outcome}`);
        // window.deferredPrompt = null;
      }
    },
    async showGDPRPrompt() {
      this.$root.getLocalStorage("shown_gdpr").then(async (s) => {
        if (!s) {
          const alert = await alertController.create({
            cssClass: "ion-alert-big ion-alert-privacy",
            header: "Impostazioni Cookies",
            // header: this.$t("privacy.title"),
            message: new IonicSafeString(
              this.$t(
                "privacy.text",
                { link: "https://www.iubenda.com/privacy-policy/737204" }
                // { link: this.settings.link_privacy }
              )
            ), //'Questo sito fa uso di cookie per migliorare l’esperienza di navigazione degli utenti e per raccogliere informazioni sull’utilizzo del sito stesso. Utilizziamo sia cookie tecnici sia cookie di parti terze per inviare messaggi promozionali sulla base dei comportamenti degli utenti. Può conoscere i dettagli consultando la nostra privacy policy con il seguente <a target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/737204">Link</a>. Proseguendo nella navigazione si accetta l’uso dei cookie; in caso contrario è possibile abbandonare il sito.'
            buttons: [
              // {
              //   text: 'Cancel',
              //   role: 'cancel',
              //   cssClass: 'secondary',
              //   handler: blah => {
              //     console.log('Confirm Cancel:', blah)
              //   },
              // },
              {
                text: "Conferma",
                // text: this.$t("privacy.confirm"),
                cssClass: "primary",
                handler: () => {
                  this.confermaPrivacy()
                },
              },
            ],
          })
          return alert.present()
        }
      })
    },
    confermaPrivacy() {
      ApiService.post("CampaignManager/RegisterPrivacyClick").then((res) =>
        console.log(res)
      )
      this.$root.setLocalStorage("shown_gdpr", 1)
    },
  },
  ionViewWillEnter() {
    console.log("WELCOME")
    this.showGDPRPrompt()
    this.showInstallPrompt()
    this.$root.setNotificationToken()

    this.$root.getLocalStorage("shown_tutorial").then((s) => {
      if (!s && this.settings.show_tutorial) {
        this.$router.push("/tutorial")
      } else if (this.settings.ragione_sociale) {
        if (this.settings.ragione_sociale == "Axerta") {
          this.$router.push("/axerta/lista-ordini")
        } else {
          this.$router.push("/campaigns")
        }
      } else {
        this.$router.push("/")
      }
    })
  },
}
</script>
