<script>
import ApiService from "../service.api";
import { mapActions, mapGetters } from "vuex";
import { dateToDayMonth, loadFacebookScript, translate } from "../helper.auth";
import { IonicSafeString } from "@ionic/vue";
import moment from "moment";
import { AVATAR_PLACEHOLDER_URL } from "@/common/config";

export default {
  name: "UserService",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("user", {
      campaignsData: "getCampaignsData",
    }),
    ...mapGetters("auth", {
      loginStatus: "getLoginStatus",
      authData: "getAuthData",
    }),
  },
  methods: {
    ...mapActions("user", {
      saveUserToStorage: "saveUserToStorage",
    }),
    isLogged() {
      return this.authData && this.authData.token;
    },
    getCurrentUser(options) {
      let resolveReturn = (data, options, resolve) => {
        resolve(data);
      };
      const promise = new Promise((resolve) => {
        const data = this.userData;
        if (!data || (options && options.forceRefresh)) {
          ApiService.get("User/0").then((res) => {
            // Set avatar placeholder
            if (!res.data.avatar_url) {
              res.data.avatar_url = AVATAR_PLACEHOLDER_URL;
            }
            // const mappedData = res.data
            const mappedData = res;

            this.saveUserToStorage(mappedData);

            resolveReturn(mappedData, options, resolve);
          });
        } else {
          resolveReturn(data, options, resolve);
        }
      });
      return promise;
    },
    isLoggedSocial(socialname) {
      const promise = new Promise((resolve) => {
        // resolve(true);
        switch (socialname) {
          case "facebook":
            ApiService.get("SocialShare/CheckFacebookToken").then((res) => {
              console.log("RISULTATO LOGIN FACEBBOK", res);
              resolve(res.data.isValid);
            });
            break;
          case "linkedin":
            ApiService.get("SocialShare/CheckLinkedinToken").then((res) => {
              console.log("RISULTATO LOGIN LINKEDIN", res);

              resolve(res.data.isValid);
            });
            break;
        }
      });
      return promise;
    },
    loginFacebook() {
      const promise = new Promise((resolve) => {
        // const appId = 584563499070024;
        const appId = this.settings.FB_AppID;
        // alert(appId);
        loadFacebookScript(appId).then(() => {
          window.FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
              resolve(true);
            } else {
              window.FB.login(
                (response) => {
                  if (response.authResponse) {
                    const fbObject = response.authResponse;
                    // this.facebookService.saveFbLoginResponse(
                    //     this.fbObject.accessToken,
                    //     this.fbObject.dataAccessExpTime,
                    //     this.fbObject.expiresIn,
                    //     this.fbObject.userId,
                    //     0, this.sellerId).subscribe(data => {
                    // });
                    // localStorage.setItem('facebookLogin', 'logged');
                    // that.faceToken = 'logged';

                    ApiService.post("SocialShare/SaveFacebookLogin", {
                      accessToken: fbObject.accessToken,
                      expiresIn: fbObject.expiresIn,
                    }).then((res) => {
                      resolve(true);
                    });
                  } else {
                    resolve(false);
                    console.log("User login failed");
                  }
                },
                {
                  scope: "public_profile,user_posts",
                  auth_type: "reauthorize",
                }
              );
            }
          });
        });
      });
      return promise;
    },
    loginLinkedin() {
      const promise = new Promise((resolve) => {
        // Save token if passed via url
        if (this.$route.query.code) {
          ApiService.post("SocialShare/SaveLinkedinLogin", {
            accessToken: this.$route.query.code,
            expiresIn: 0,
          }).then((res) => {
            resolve(true);
          });
        } else {
          // const clientId = "77pvhpfr6dieg3";
          const clientId = this.settings.IN_ClientID;
          const loginpath = encodeURIComponent(
            location.protocol +
              "//" +
              location.host +
              this.$router.resolve({
                name: "social_login",
                params: {},
              }).href
          );
          //https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77pvhpfr6dieg3&scope=r_basicprofile%20r_emailaddress%20r_organization_social%20rw_organization_admin&redirect_uri=https%3A%2F%2Fyeea.whappy.it%2Fskill-detector%2Fsocial-login
          const authorization = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&scope=r_basicprofile%20r_emailaddress%20r_organization_social%20rw_organization_admin&redirect_uri=${loginpath}`;
          resolve(true);
          window.location.href = authorization;
        }
      });
      return promise;
    },
  },
};
</script>
