import axios from "axios";
import store from "../store/index";
import Vue from "vue";
import {API_URL} from "./config";
import {mapActions} from "vuex";


const ApiService = axios.create({});

ApiService.defaults.baseURL = API_URL;

ApiService.interceptors.request.use((config) => {
    const authData = store.getters["auth/getAuthData"];
    config.withCredentials = false;
    if (authData == null) {
        return config;
    }
    config.headers.common["Authorization"] =  authData.token;
    return config;
});

ApiService.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error && error.response && error.response.status === 401) {
            // const authData = store.getters["auth/getAuthData"];
            // const payload = {
            //     access_token: authData.token,
            //     refresh_token: authData.refreshToken,
            // };
            //
            // var response = await axios.post(
            //     "http://localhost:3000/auth/refreshtoken",
            //     payload
            // );
            // await store.dispatch("auth/saveTokensToStorage", response.data);
            // error.config.headers[
            //     "Authorization"
            //     ] = `bearer ${response.data.access_token}`;
            return axios(error.config);
        } else {
            return Promise.reject(error);
        }
    }
);


// Loader
ApiService.interceptors.request.use((config) => {
    store.dispatch('loading/setLoading', true);
    return config;
}, (error) => {
    store.dispatch('loading/setLoading', false);
    return Promise.reject(error);
});

ApiService.interceptors.response.use((response) => {
    store.dispatch('loading/setLoading', false);
    return response;
}, (error) => {
    store.dispatch('loading/setLoading', false);
    return Promise.reject(error);
});


export default ApiService;
