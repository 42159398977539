<template>
  <master-layout
    smallTitle="Questa settimana"
    bigTitle="Lead Caldi"
    :showInfoPopHover="true"
    :infoPopHover="
      whappyClientConfig.name == 'Yoube'
        ? INFOPOPOVER_MAP[whappyClientConfig.name]
        : INFOPOPOVER_MAP['default']
    "
  >
    <ion-row class="cta ion-justify-content-center ion-padding-top"
      ><ion-col size="auto">
        <button class="p-d button-primary" @click="openModalNewClient">
          Aggiungi cliente +
        </button>
      </ion-col>
    </ion-row>
    <ion-grid class="main-container ion-padding-top">
      <ion-row class="searchbar">
        <ion-col>
          <ion-searchbar
            placeholder="Ricerca cliente esistente..."
            v-model="filter"
            autocomplete="on"
            clear-icon
            search-icon
          ></ion-searchbar>
        </ion-col>
      </ion-row>
      <FiltroClienti
        :showFiltroAgente="userData.role == 'Agente' ? false : true"
        @update:anagProspectFilters="handleFiltroCLientiChange"
        @reset:anagProspectFilters="handleFiltroClientiReset"
        @cerca:anagProspectFilters="handleFiltroClientiCerca"
      ></FiltroClienti>
      <ion-row
        class="items ion-padding-horizontal ion-margin-bottom ion-margin-top ion-margin-top button-primary border-radius-client py-2"
        v-for="item of items"
        :key="item.id"
        @click="gotoDettagliCliente(item)"
      >
        <ion-col>
          <ion-row> {{ item.ragioneSociale }} </ion-row>
          <ion-row>
            <ion-col>
              <gradient-progress-bar
                class="height-5 gradient"
                :value="item.hotness"
              ></gradient-progress-bar>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col class="flex justify-content-end align-items-center" size="2">
          <ion-icon :icon="call"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-row class="cta ion-justify-content-center"
      ><ion-col size="auto">
        <button
          :disabled="loading"
          type="button"
          class="p-d button-primary"
          @click="askForMoreResults"
        >
          {{ loading ? "Caricamento..." : "Carica più risultati" }}
        </button>
      </ion-col>
    </ion-row>
  </master-layout>
</template>

<script>
import {
  modalController,
  IonButton,
  IonFooter,
  IonToolbar,
  IonSearchbar,
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
} from "@ionic/vue";
import { call } from "ionicons/icons";
import ModalActualClient from "./Modals/ModalActualClient.vue";
import ModalNewAzienda from "./Modals/ModalNewAzienda.vue";
import GradientProgressBar from "@/components/GradientProgressBar.vue";
import ApiService from "../common/service.api";
import FiltroClienti from "@/components/Nuovi/FiltroClienti.vue";
import { useGeneralStore } from "@/stores/useGeneralStore.js";
import { mapGetters } from "vuex";
export default {
  name: "AnagraficaClienti",
  components: {
    IonButton,
    IonFooter,
    IonToolbar,
    IonSearchbar,
    IonCol,
    IonGrid,
    IonRow,
    IonIcon,
    GradientProgressBar,
    FiltroClienti,
  },
  data() {
    return {
      call,
      filter: "",
      items: [],
      objectToPost: {
        idTipoAnagrafica: 0,
        idUtente: 0,
        idPadre: 0,
        nome: "",
        cognome: "",
        ragioneSociale: "",
        partitaIva: "",
        codiceFiscale: "",
        email: "",
        telefono: "",
        cellulare: "",
        citta: "",
        provincia: "",
        regione: "",
        nazione: "",
        note: "",
        anagraficaYoube: true,
      },
      INFOPOPOVER_MAP: {
        Yoube:
          "Una selezione dinamica dei tuoi clienti più interessati al brand. Per non perderti delle opportunità.",
        default:
          "Una selezione dinamica dei tuoi clienti più interessati al brand. Per non perderti delle opportunità.",
      },
      currentResults: 0,
      loading: false,
      currentFiltroClienti: {
        regione: null,
        provincia: null,
        citta: null,
        agente: null,
      },
    };
  },
  mounted() {
    const generalStore = useGeneralStore();

    this.currentFiltroClienti = generalStore.youbeFilter;

    this.getData();
  },
  methods: {
    async openModalNewClient() {
      const modal = await modalController.create({
        component: ModalNewAzienda,
        cssClass: "my-custom-class",
        componentProps: { aziende: this.aziende },
      });

      return modal.present();
    },
    handleFiltroClientiCerca() {
      this.currentResults = 0;
      this.getData();
    },
    handleFiltroClientiReset() {
      this.currentResults = 0;
      this.filter = "";
    },
    handleFiltroCLientiChange(filtro) {
      this.currentFiltroClienti = filtro;
    },
    getData() {
      console.log("TRIGG GET DATA", this.currentFiltroClienti);

      this.loading = true;
      ApiService.post("Ecommerce/GetAnagsHot/", {
        take: 10,
        skip: this.currentResults,
        ricerca: this.filter ? this.filter : "",
        regione:
          this.currentFiltroClienti.regione === "all"
            ? ""
            : this.currentFiltroClienti.regione,
        provincia:
          this.currentFiltroClienti.provincia === "all"
            ? ""
            : this.currentFiltroClienti.provincia,
        citta:
          this.currentFiltroClienti.citta === "all"
            ? ""
            : this.currentFiltroClienti.citta,
        idAgente:
          this.currentFiltroClienti.agente !== null
            ? this.currentFiltroClienti.agente
            : 0,
      })
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.currentResults = this.items.length;
          this.loading = false;
        });
    },
    askForMoreResults() {
      const endpoint = "Ecommerce/GetAnagsHot/";
      const queryParams = {
        take: 10,
        skip: this.currentResults,
        ricerca: this.filter ? this.filter : "",
        regione:
          this.currentFiltroClienti.regione === "all"
            ? ""
            : this.currentFiltroClienti.regione,
        provincia:
          this.currentFiltroClienti.provincia === "all"
            ? ""
            : this.currentFiltroClienti.provincia,
        citta:
          this.currentFiltroClienti.citta === "all"
            ? ""
            : this.currentFiltroClienti.citta,
        idAgente:
          this.currentFiltroClienti.agente !== null
            ? this.currentFiltroClienti.agente
            : 0,
      };

      this.loading = true;

      ApiService.post(endpoint, queryParams)
        .then((response) => {
          this.items = this.items.concat(response.data);
        })
        .finally(() => {
          this.currentResults = this.items.length;
          this.loading = false;
        });
    },
    gotoDettagliCliente(cliente) {
      console.log(
        "🚀 ~ file: AnagraficaClienti.vue:152 ~ gotoDettagliCliente ~ cliente:",
        cliente
      );

      this.$router.push({
        name: "DettagliCliente",
        params: {
          idCliente: cliente.id,
        },
      });
    },
    async openModalActualClient(item) {
      const modal = await modalController.create({
        component: ModalActualClient,
        cssClass: "my-custom-class",
        componentProps: { client: item },
      });

      return modal.present();
    },
  },
  computed: {
    ...mapGetters("user", {
      userData: "getUserData",
    }),
  },
};
</script>

<style scoped>
/* * {
  border: 1px solid purple;
} */

/* .cta {
  padding: 2rem 0;
}

.cta ion-button {
  height: 4rem;
} */

h3 {
  font-weight: bolder;
}
</style>
