<template>
  <master-layout :showHeader="false" :showContentHeader="false">
    <ion-grid class="no-padd-no-mar">
      <ion-row class="ion-align-items-center">
        <ion-col size="auto">
          <ion-icon
            @click="closeModal"
            :icon="arrowBackOutline"
            class="arrow-back"
          ></ion-icon>
        </ion-col>
        <ion-col class="ion-text-center">
          <h5>{{ client.name }} {{ client.surname }}</h5>
        </ion-col>
      </ion-row>

      <ion-row v-for="categoria in statistics" :key="categoria.categoryTitle">
        <ion-col>
          <ion-row>
            <ion-col>
              <h5>{{ categoria.categoryTitle }}</h5>
            </ion-col>
          </ion-row>
          <ion-row
            v-for="statistic in categoria.statistics"
            :key="statistic.title"
            class="item ion-align-items-center item-anagrafica"
          >
            <ion-col>
              <ion-row size="4">
                <span class="item-title title">{{ statistic.title }}</span>
              </ion-row>
              <ion-row class="ion-no-padding">
                <ion-col>
                  <gradient-progress-bar
                    class="height-5 gradient"
                    :value="statistic.value"
                  ></gradient-progress-bar>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col size="auto" class="ion-margin-start">
              <span class="statistic-value">{{ statistic.value * 100 }}% </span>
            </ion-col>
            <ion-col size="auto" class="ion-margin-start">
              <ion-icon
                v-if="statistic.value > 0.59"
                :icon="thumbsUpSharp"
                class="thumbs-up"
              ></ion-icon>
              <ion-icon
                v-else
                :icon="thumbsDownSharp"
                class="thumbs-down"
              ></ion-icon>
            </ion-col>
          </ion-row>
        </ion-col>
        <div class="hr"></div>
        <div class="hr"></div>
      </ion-row>
    </ion-grid>
  </master-layout>
</template>

<script>
import {
  IonProgressBar,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonCard,
  modalController,
} from "@ionic/vue"
import {
  arrowBackOutline,
  thumbsUpSharp,
  thumbsDownSharp,
} from "ionicons/icons"
import { defineComponent } from "vue"
import GradientProgressBar from "../../components/GradientProgressBar"

export default defineComponent({
  components: {
    IonProgressBar,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonList,
    IonItem,
    IonTextarea,
    IonCard,
    GradientProgressBar,
  },
  name: "ModalLeadScore",
  props: {
    client: Object,
  },
  data() {
    return {
      thumbsUpSharp,
      thumbsDownSharp,
      arrowBackOutline,
      statistics: [
        {
          categoryTitle: "Documenti",
          statistics: [
            { title: "Locandina marzo 2023", value: 0.4 },
            { title: "Listino prezzi rivenditore primavera 2023", value: 0.8 },
            { title: "Placeholder logo YoUBe", value: 0.4 },
          ],
        },
      ],
    }
  },

  methods: {
    closeModal() {
      return modalController.dismiss()
    },
  },
})
</script>

<style scoped>
/* * {
  border: 1px solid greenyellow;
} */

.no-padd-no-mar {
  width: 100%;
  padding: 0;
  margin: 0;
}

.arrow-back {
  padding-top: 0.5rem;
  font-size: 1.5rem;
}

ion-grid {
  width: 90%;
  margin: auto;
}

.title {
  font-size: 1.2rem !important;
}

.item-title,
.statistic-value {
  font-size: 1rem;
}

.hr {
  width: 100%;
  height: 1px;
  margin-bottom: 2px;
  background: var(--ion-color-primary);
}

.thumbs-up {
  color: green;
}
.thumbs-down {
  color: red;
}
</style>
