<template>
  <master-layout :showMenu="computed_debug" smallTitle="Il" bigTitle="Quiz">
    <common-quiz v-if="quiz" :quiz="quiz"></common-quiz>
  </master-layout>
</template>

<script>
import { arrowForward } from "ionicons/icons";
import ApiService from "../../common/service.api";
import { translate } from "../../common/helper.auth";
// import CampaignLayout from "../../components/CampaignLayout"
import { useRoute } from "vue-router";
import CommonQuiz from "../../components/Quiz/Quiz";

export default {
  name: "CampaignQuiz",
  components: {
    CommonQuiz,
    // CampaignLayout,
  },
  data() {
    return {
      quiz: null,
    };
  },
  setup() {
    const route = useRoute();
    const { id } = route.params;
    return { id };
  },
  ionViewWillEnter() {
    const route = this.$route;
    const { id, title } = route.params;
    this.title = title;
    const that = this;
    // this.quiz='eeee';
    ApiService.get("LearningQuiz", {
      params: {
        id: id,
      },
    }).then((res) => {
      that.quiz = res.data;
    });
  },
  computed: {
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
