<template>
  <master-layout
    title="Dettaglio Ordine"
    :showContentHeader="false"
    :showHeader="false"
  >
    <ion-grid>
      <ion-row class="title">
        <ion-col>
          <p><strong>Dettaglio Ordine</strong></p>
        </ion-col>
      </ion-row>
      <ion-row class="dati-azienda">
        <ion-col>
          <ion-row>
            <ion-col>
              <ion-row>
                <ion-col> <strong>Nome </strong></ion-col>
              </ion-row>
              <ion-row>
                <ion-col> {{ cliente.name }} {{ cliente.surname }}</ion-col>
              </ion-row>
            </ion-col>
            <ion-col>
              <ion-row>
                <ion-col> <strong></strong> </ion-col>
              </ion-row>
              <ion-row>
                <ion-col> </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-row>
                <ion-col> <strong>Numero Telefono</strong></ion-col>
              </ion-row>
              <ion-row>
                <ion-col> {{ cliente.telefono }} </ion-col>
              </ion-row>
            </ion-col>
            <ion-col>
              <ion-row>
                <ion-col> <strong>P. Iva</strong> </ion-col>
              </ion-row>
              <ion-row>
                <ion-col> {{ cliente.pIva }} </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-row>
                <ion-col> <strong>Numero Ordine</strong></ion-col>
              </ion-row>
              <ion-row>
                <ion-col> {{ cliente.pIva + 7777777777 }} </ion-col>
              </ion-row>
            </ion-col>
            <ion-col>
              <ion-row>
                <ion-col> <strong>Data</strong> </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  0{{ new Date().getDate() }}/0{{
                    new Date().getMonth() + 1
                  }}/{{ new Date().getFullYear() }}
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="table">
        <ion-col>
          <ion-row class="table-header">
            <ion-col size="4.5">
              <p><strong>Prodotto</strong></p>
            </ion-col>
            <ion-col size="3">
              <p><strong>Prezzo</strong></p>
            </ion-col>
            <ion-col size="1.5">
              <p><strong>Qtà</strong></p>
            </ion-col>
            <ion-col size="3">
              <p><strong>Subtotale</strong></p>
            </ion-col>
          </ion-row>
          <ion-row class="table-item">
            <ion-col size="4.5">
              <p>
                {{ item.name }}
              </p>
            </ion-col>
            <ion-col size="3">
              <p>€ {{ item.price.toFixed(2) }}</p>
            </ion-col>
            <ion-col size="1.5">
              <p>
                {{ counter }}
              </p>
            </ion-col>
            <ion-col class="align-end" size="3">
              <p>€ {{ (counter * item.price).toFixed(2) }}</p>
            </ion-col>
          </ion-row>
          <ion-row class="table-item-promo" v-if="counter >= item.promo">
            <ion-col size="4.5">
              <p>
                {{ item.name }}
              </p>
            </ion-col>
            <ion-col style="color: green" size="3">
              <p>Regalo</p>
            </ion-col>
            <ion-col size="1.5">
              <p>
                {{ Math.floor(counter / item.promo) }}
              </p>
            </ion-col>
            <ion-col class="align-end" size="3">
              <p>€ 0,00</p>
            </ion-col>
          </ion-row>
          <ion-row class="table-resume">
            <ion-col>
              <ion-row class="ion-justify-content-between">
                <ion-col size="auto"> Subtotale </ion-col>
                <ion-col size="auto">
                  € {{ ((counter * item.price) / 1.1).toFixed(2) }}
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-between">
                <ion-col size="auto"> Imposte </ion-col>
                <ion-col size="auto">
                  € {{ ((counter * item.price) / 1.1 / 10).toFixed(2) }}
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-between">
                <ion-col size="auto"> Spedizione </ion-col>
                <ion-col size="auto"> € {{ (0).toFixed(2) }} </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-between">
                <ion-col size="auto">
                  <h6><strong>Totale ordine</strong></h6>
                </ion-col>
                <ion-col size="auto">
                  <h6>
                    <strong>{{
                      (
                        (counter * item.price) / 1.1 +
                        (counter * item.price) / 1.1 / 10
                      ).toFixed(2)
                    }}</strong>
                  </h6>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col size="auto">
          <ion-button @click="closeModal"> Close</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </master-layout>
</template>

<script>
import CampaignLayout from "../../components/CampaignLayout"
import { modalController, IonCol, IonGrid, IonRow } from "@ionic/vue"
export default {
  name: "dettaglioOrdine",
  props: {
    item: Object,
    counter: Number,
    cliente: Object,
  },
  components: { CampaignLayout, IonCol, IonGrid, IonRow },
  methods: {
    closeModal() {
      return modalController.dismiss()
    },
  },
}
</script>

<style scoped>
/* * {
  border: 1px solid rgb(168, 255, 168);
} */

.title {
  text-align: center;
  font-size: 1.2rem;
  color: var(--ion-color-primary);
}

.table {
  font-size: 0.8rem;
}

.table-header {
  background: var(--ion-color-primary);
  color: var(--text-color-light);
}

.table-item {
  padding: 0.2rem;
}

.table-item-promo {
  padding: 0.2rem;
  color: var(--ion-color-primary);
}

.table-resume {
  padding: 0.2rem;
}

.align-end {
  text-align: end;
}
</style>
