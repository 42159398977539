import { Plugins } from "@capacitor/core";
import axios from "axios";

const { Storage } = Plugins;

const state = () => ({
  campaignsData: null,
  onBoardingId: [],
  onBoardingCampaigns: [],
});

const getters = {
  getCampaignsData(state) {
    return state.campaignsData;
  },
  getOnBoardingId(state) {
    return state.onBoardingId;
  },
  getOnBoardingCampaigns(state) {
    return state.onBoardingCampaigns;
  },
};

const actions = {
  async saveCampaignsToStorage({ commit }, payload) {
    commit("saveCampaigns", payload);
  },
  async setOnBoardingIdToStorage({ commit }, payload) {
    commit("setOnBoardingId", payload);
  },
};

const mutations = {
  saveCampaigns(state, payload) {
    if (!payload) {
      state.campaignsData = null;
    } else {
      const newCampaignsData = payload;

      state.campaignsData = newCampaignsData;

      for (let i = 0; i < state.onBoardingId.length; i++) {
        for (let j = 0; j < newCampaignsData.length; j++) {
          if (newCampaignsData[j].id == state.onBoardingId[i]) {
            if (
              !state.onBoardingCampaigns.some(
                (element) => element.id == newCampaignsData[j].id
              )
            ) {
              state.onBoardingCampaigns.push(newCampaignsData[j]);
            }
          }
        }
      }
    }
  },
  setOnBoardingId(state, payload) {
    for (let onBoardingId of state.onBoardingId) {
      if (onBoardingId == payload) {
        return;
      }
    }
    state.onBoardingId.push(payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
