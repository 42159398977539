<template>
  <div class="progress-container">
    <p class="progress-text">
      Question {{ currentQuestion }} of {{ totalQuestions }}
    </p>
    <div class="progress-bar">
      <div
        class="progress-bar-fill"
        :style="{ width: progressPercentage + '%' }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentQuestion: Number,
    totalQuestions: Number,
  },
  computed: {
    progressPercentage() {
      return (this.currentQuestion / this.totalQuestions) * 100;
    },
  },
};
</script>

<style scoped>
.progress-container {
  width: 100%;
  margin-bottom: 20px;
}

.progress-text {
  font-size: 1rem;
  margin-bottom: 5px;
  text-align: center;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
}
</style>
