<template>
  <master-layout :showBack="true" smallTitle="fine" bigTitle="Questionario">
    <div class="flex flex-column justify-content-between align-items-stretch">
      <div
        class="w-full flex flex-column justify-content-center align-items-center p-4"
      >
        <div>
          <img
            :src="currentUser.image"
            style="width: 6rem; height: 6rem; border-radius: 50%"
          />
        </div>

        <div class="name font-bold text-18">
          <span>{{ currentUser.name }}</span>
        </div>

        <div class="role">
          <span>{{ currentUser.role }}</span>
        </div>
      </div>
      <div
        class="card w-full flex flex-column justify-content-center align-items-center"
        style="padding: 1.5rem 2rem; margin: 1rem 0"
      >
        <div class="w-full flex justify-content-center align-items-center mb-4">
          <img
            src="../../../public/assets/img/danger.png"
            class="danger-image"
          />
        </div>
        <div class="text-align-center font-bold">
          Ti ricordiamo che le valutazioni rimarranno anonime
        </div>
      </div>

      <div class="px-4">
        <div v-for="(r, index) in results" :key="index" class="mb-4">
          <div class="text-align-center mb-2">{{ r.domanda }}</div>
          <!-- Rating statico -->
          <div class="static-rating-container">
            <div v-for="x in 5" :key="x" class="star-container">
              <img
                :src="
                  x <= r.valore
                    ? '/assets/img/fullStar.png'
                    : '/assets/img/emptyStar.png'
                "
                class="star"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <button
          style="border: 1px solid var(--ion-color-primary); min-width: 40%"
          @click="confirmSubmit"
          class="flex justify-content-center button-primary p-d text-18"
        >
          Invia
        </button>
      </div>
    </div>
  </master-layout>

  <!-- <surveyResult>
    <template #header> -->
  <!-- </template> -->

  <!-- <template #footer> -->
  <!-- Bottoni per reiniziare e confermare -->

  <!-- </template>
  </surveyResult> -->
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { computed } from "vue";
import { useGeneralStore } from "../../stores/useGeneralStore";

import surveyResult from "./layout/surveyResult.vue";

import Button from "./components/Button.vue";
import { routerKey } from "vue-router";

import MasterLayout from "../../components/MasterLayout.vue";

import { usePkSurvey } from "@/stores/usePkSurvey";

const pkSurvey = usePkSurvey();

const currentUser = pkSurvey.currentUser;

const router = useRouter();
const route = useRoute();

const generalStore = useGeneralStore();

const currentSurvey = computed(() => generalStore.currentSurvey);

const results = computed(() => {
  let res = [];

  for (let r of currentSurvey.value.risposte) {
    res.push({ domanda: r.domanda.t_domanda["it-IT"], valore: r.valore });
  }

  return res;
});

// Metti qui la logica per i pulsanti
function restartQuiz() {
  router.push("/pk-survey/" + route.params.idSurvey);
}

function confirmSubmit() {
  router.push("/pk-surveys");
}
</script>

<style scoped>
.danger-image {
  width: 48px;
  height: 48px;
}

.card {
  border-radius: 10px;
  background-color: var(--ion-color-primary);
  padding: 20px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative;
  min-height: 100px;
  color: var(--text-color-light);
  margin-bottom: 20px;
}

.static-rating-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.star-container {
  margin: 0 0.3rem;
}

.star {
  width: 24px;
  height: 24px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.btn {
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.restart-btn {
  background-color: #ffdddd;
}

.restart-btn:hover {
  background-color: #ffbbbb;
}

.submit-btn {
  background-color: #d4edda;
}

.submit-btn:hover {
  background-color: #c3e6cb;
}
</style>
