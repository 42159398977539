<template>
  <template v-if="items && items.length && (!onAward || items.length > 0)">
    <transition-group
      name="block-slide-in"
      :style="{ '--total': items.length }"
    >
      <template v-for="(item, i) in items" :key="i">
        <ion-card :style="{ '--i': i }" v-if="item.blockType === 'folder'" @click="navigateToNextRoute(item)"
          class="custom-card">
          <div class="badge">
            <span class="badge-text">{{ item.randomPercentage }}%</span>
          </div>
          <ion-card-header>
            <div class="percentage">
              &nbsp;
            </div>
            <div class="title">
              <ion-card-title>
                <span class="text-20">{{ item.nome }}</span>
              </ion-card-title>
            </div>
          </ion-card-header>
          <div class="progress-container">
            <div class="progress-bar" :style="{ width: item.randomPercentage + '%' }"></div>
          </div>
        </ion-card>
        <ion-card
  v-if="item.blockType === 'learning'"
  class="learning-card"
>
  <ion-card-header>
    <div class="header-content">
      <ion-card-title class="classtitle text-20">{{ item.learningChapter.title['it-IT'] }}</ion-card-title>
      <div class="lock-status" :class="{ locked: !item.learningChapter.sbloccato, unlocked: item.learningChapter.sbloccato }">
        <ion-icon v-if="item.learningChapter.sbloccato" :icon="lockOpen"></ion-icon>
        <ion-icon v-if="!item.learningChapter.sbloccato" :icon="lockClosed"></ion-icon>
      </div>
    </div>
  </ion-card-header>
  <ion-card-content>
    <template v-if="item.learningChapter.immagine_capitolo === null">
      <VideoPlayer
        v-if="item.learningChapter.embed_contenuto_formativo"
        :url="extractSrcFromIframe(item.learningChapter.embed_contenuto_formativo)"
        @watch_session_ended="handleWatchSessionEnded($event, item.learningChapter)"
      />
      <p class="description">{{ item.learningChapter.description['it-IT'] }}</p>
      <div v-if="item.learningChapter.id_survey" class="quiz-section">
        <ion-button expand="block" style="width: 100%;" @click="openChapter(item.learningChapter)">
          FAI IL TEST
        </ion-button>
        <div class="progress-container">
          <div class="progress-bar" :style="{ width: item.learningChapter.percentualeCompletamento + '%' }"></div>
        </div>
        <p class="percentage-survey">
          {{ item.learningChapter.RisposteEsatte }}/{{ item.learningChapter.RisposteTotali }} risposte corrette
        </p>
      </div>
    </template>
    <template v-else>
      <div class="chapter-image" v-if="item.learningChapter.immagine_capitolo">
        <img :src="item.learningChapter.immagine_capitolo" alt="Chapter Image" />
      </div>
      <p class="description">{{ item.learningChapter.description['it-IT'] }}</p>
    </template>
  </ion-card-content>
</ion-card>
<ion-card
  v-if="item.blockType === 'documents'"
  class="learning-card"
>
  <ion-card-header>
    <div class="header-content">
      <ion-card-title class="classtitle text-20">{{ item.nome }} </ion-card-title>
      
    </div>
  </ion-card-header>
  <ion-card-content>
    <p class="description">{{ item.signatureDocument.description }} </p>
      <div class="iframe-container"
                    >
      <iframe
                        :src="item.signatureDocument.fileUrl"
                        frameBorder="0"
                        scrolling="auto"
                        width="100%"
                        height="100%"
                      ></iframe>
                      </div>
        <ion-button expand="block" style="width: 100%; margin-top: 2rem; background-color: red !important;" @click="openChapter(item.learningChapter)">
          FIRMA ORA
        </ion-button>
  </ion-card-content>
</ion-card>
<ion-card v-if="item.blockType === 'induction'"> 
        <ion-card-header>
          <div class="lock-status">
            <ion-icon :icon="lockClosed"></ion-icon>
          </div>

          <ion-card-content class="ion-no-padding">
            <PrenotaAppuntamento
              v-if="whappyClientConfig.name == 'Automotive SpA'"
              currentMentorAvatar="https://randomuser.me/api/portraits/men/3.jpg"
              :mentor="{
                name: 'Jon Doe',
                role: 'Admin',
                img: 'https://randomuser.me/api/portraits/men/3.jpg',
              }"
              currentDate="2022-12-31:17:00:00"
              title="Ora che sei pronto proponi il giorno e ora l'affiancamento con il tuo collega, riceverai il feedback in breve tempo"
              :immediateScroll="false"
            />
          </ion-card-content>
        </ion-card-header>
      </ion-card>
      </template>
    </transition-group>
  </template>
  </template>
  
  <script>
  import ApiService from "../common/service.api";
  import { lockOpen, lockClosed } from "ionicons/icons";
  import { IonCard, IonCardHeader, IonCardTitle } from "@ionic/vue";
  import VideoPlayer from "@/components/VideoPlayer.vue";
  import { useQuizStore } from "@/stores/useQuizStore";
  import PrenotaAppuntamento from "../views/pk_views/components/PrenotaAppuntamento.vue";
  
  const quizStore = useQuizStore();
  
  export default {
    name: "CampaignJourneyMenuSubFolders",
    data() {
      return {
        title: "",
        items: [],
        itemsDocuments: [],
        campaignId: null,
        idFolder: null,
        lockClosed,
        lockOpen,
      };
    },
    components: { IonCard, IonCardHeader, IonCardTitle, VideoPlayer, PrenotaAppuntamento },
    methods: {
      async handleWatchSessionEnded(event, learningChapter) {
        const res = await ApiService.put(
          "LearningVideo/Put/" + learningChapter.id,
          {
            id: learningChapter.id,
            idUser: this.$store.getters["user/getUserData"].id,
            idLearning: this.$route.params.id,
            duration: event.durata,
            watchTime: event.tempoGuardato,
            remainingTime: event.tempoRimanente,
            progressPercentage: event.percentualeGuardata,
          }
        );
      },
  

      extractSrcFromIframe(iframeString) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = iframeString;
        const iframe = tempDiv.querySelector("iframe");
        return iframe ? iframe.src : null;
      },
  

      navigateToNextRoute(item) {
        console.log(item);
      },
  
      openChapter(learningChapter) {
        if (learningChapter.id_survey) {

          quizStore.setParentId(this.$route.params.id);
          quizStore.setParent(learningChapter.id_categoria);
          quizStore.setParentTitle(learningChapter.title["it-IT"]);
  
          this.$router.push({
            name: "campaign_quiz",
            params: {
              chapter: learningChapter.id,
              id: learningChapter.id_survey,
              title: learningChapter.title,
              parentId: this.$route.params.id,
              parent: learningChapter.id_categoria,
              parentTitle: learningChapter.title["it-IT"],
            },
          });
        }
      },
    },
    mounted() {
      const route = this.$route;
      const { id, idFolder } = route.params;
      this.campaignId = id;
      this.idFolder = idFolder;
  
      // Llamada inicial para obtener los datos
      ApiService.get(`Journey/GetAppJourney/${id}?idFolder=${idFolder}`)

        .then((response) => {
          this.items = response.data.blocks;
        })
        .catch((error) => {
          console.error("Error al obtener los datos iniciales:", error);
        });
    },

    
  };
  </script>
  
  
  <style scoped>
  .custom-card {
    background: #1C1C1C;
    color: #fff;
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  .custom-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    background: #262626;
    cursor: pointer;
  }
  .badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    color: #1C1C1C;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .badge-text {
    font-size: 14px;
    font-weight: 600;
  }
  .percentage {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .text-20 {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    color: white;
  }
  .progress-container {
    background: rgba(255, 255, 255, 0.2);
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 10px;
  }
  .progress-bar {
    height: 100%;
    background: white;
    transition: width 0.3s ease;
  }
  ion-card-header {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.4s ease forwards;
  }
  .learning-card {
    background: #2A2A2A;
    color: #fff;
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .learning-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  }
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .lock-status {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  .lock-status.locked ion-icon {
    color: #FF4757;
  }
  .lock-status.unlocked ion-icon {
    color: #2ECC71;
  }
  .classtitle {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin: 8px 0;
  }
  .description {
    margin: 16px 0;
    line-height: 1.6;
  }
  .quiz-section {
    margin-top: 20px;
  }
  .percentage-survey {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
  }
  .chapter-image img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .iframe-container {
  content: "Loading PDF...";
  background: #f0f0f0;
  width: 100%;
  height: 420px;
}
.button-inner::after {
  background: violet;
}
  </style>