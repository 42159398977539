<script setup>
import { ref } from "vue";
import competenzeList from "./components/competenzeList.vue";
import competenzeAwards from "./components/competenzeAwards.vue";

import IconsBar from "./components/IconsBar.vue";
import MasterLayout from "../../components/MasterLayout.vue";

const activeSubview = ref("competenze");

const smallTitle = ref("Le tue");
const bigTitle = ref("Competenze");

function switchSubview(subview) {
  activeSubview.value = subview;

  if (subview === "awards") {
    smallTitle.value = "Le tue";
    bigTitle.value = "Certificazioni";
  }
  if (subview === "competenze") {
    smallTitle.value = "Le tue";
    bigTitle.value = "Competenze";
  }
}
</script>

<template>
  <master-layout :showBack="true" :smallTitle="smallTitle" :bigTitle="bigTitle" :showInfoPopHover="true"
    infoPopHover=" In questa area trovi i percorsi formativi creati su misura per la tua crescita professionale. Qui troverai il tuo percorso di competenze da acquisire nei prossimi mesi, scoprirai da quali elementi è composto fra attività teoriche,  affiancamento per l'attuazione di task operative e relativo impatto sui tuoi KPI. Questo ti permetterà di certificare l’acquisizione di nuove competenze.">
    <transition name="fade">
      <div>
        <section v-if="activeSubview === 'competenze'">
          <competenzeList />
        </section>
        <section v-if="activeSubview === 'awards'">
          <competenzeAwards @event_x_adriano="activeSubview = 'competenze'" />
        </section>
      </div>
    </transition>

    <template v-slot:footer>
      <IconsBar :items="[
        { icon: '/assets/icon/competenze-list.svg', subview: 'competenze' },
        { icon: '/assets/icon/campaign-reward.svg', subview: 'awards' },
      ]" :activeSubview="activeSubview" @switch-subview="switchSubview" />
    </template>
  </master-layout>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<!-- Sarebbe troppo facile lasciare la soluzione qui... Prima vai a risolvere l'enigma nei due componenti e poi se proprio non capisci da che cognome partire... vai a cercare dove tutto comincia -->
