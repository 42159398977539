<template>
  <master-layout>
    <risultati-analisi-dei-talenti
      :showHeader="false"
    ></risultati-analisi-dei-talenti>
  </master-layout>
</template>

<script>
import RisultatiAnalisiDeiTalenti from "../../components/RisultatiAnalisiDeiTalenti.vue";
export default {
  name: "RisultatiAnalisiTalenti",
  components: {
    RisultatiAnalisiDeiTalenti,
  },
};
</script>