<template>
  <master-layout>
    <template v-if="items && items.length">
      <transition-group
        name="block-slide-in"
        :style="{ '--total': items.length }"
      >
        <template v-for="(item, i) in items">
          <ion-card
            :key="i"
            :style="{ '--i': i }"
            v-if="showList1 && showList2"
            @click="$router.push(item.url)"
          >
            <ion-card-header>
              <div class="title">
                <ion-card-title class="text-20"
                  >"{{ item.name }}</ion-card-title
                >
              </div>
            </ion-card-header>
          </ion-card>
        </template>
      </transition-group>
    </template>
  </master-layout>
</template>

<script>
export default {
  name: "AnalisiDelTalento",
  ionViewWillEnter() {
    this.showList1 = false;
    this.showList2 = false;

    setTimeout(() => {
      this.$nextTick(() => {
        this.showList1 = true;
      });
    }, 1);
  },
  ionViewDidEnter() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.showList2 = true;
      });
    }, 1);
  },
  data() {
    return {
      items: [
        {
          name: "Risultati analisi del talento",
          url: "RisultatiAnalisiTalenti",
        },
        { name: "Mappa dei talenti", url: "MappaDeiTalenti" },
      ],
      showList1: false,
      showList2: false,
    };
  },
};
</script>

<style lang="scss" scoped>
ion-card {
  //--background: var(--ion-color-primary-h);
  --background: var(--ion-color-primary-shade);
  color: var(--text-color-light);
  ion-card-title {
    color: var(--text-color-light);
    height: 50px;
    display: flex;
    align-items: flex-end;
  }

  ion-icon {
    font-size: 150%;
    margin: 0 5px -3px 0;
  }
}

.title {
  display: block;
  clear: both;
}
ion-card {
  ion-button {
    margin-top: 15px;
  }
}
</style>
