<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  avatarMentor: {
    type: String,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
  confermato: {
    type: Boolean,
    default: false,
  },
});

const formattedDate = computed(() => {
  const date = new Date(props.date);
  const monthYear = date.toLocaleDateString("it", {
    year: "2-digit",
    month: "short",
  });
  const day = date.getDate();

  return { monthYear, day };
});
</script>

<template>
  <div class="chip">
    <div class="avatar-wrapper">
      <img :src="avatarMentor" alt="Avatar" />
    </div>
    <div class="calendar-wrapper">
      <div class="calendar">
        <div class="calendar-header">{{ formattedDate.monthYear }}</div>
        <div class="calendar-day">{{ formattedDate.day }}</div>
        <div v-if="confermato" class="badge" title="Confermato">✔</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.chip {
  display: flex;
  align-items: center;
  position: relative;
}

.avatar-wrapper {
  position: relative;
  z-index: 1;
  margin-right: -0.8rem; /* Per l'overlap */
  margin-bottom: -0.4rem;
}

.avatar-wrapper img {
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  aspect-ratio: 1 / 1;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  border: 1px solid white;
}

.calendar-wrapper {
  position: relative;
  /* transform: rotate(-4deg); */
}

.calendar {
  width: 3.5rem;
  height: 3.2rem;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.calendar-header {
  background-color: red;
  color: white;
  width: 100%;
  text-align: center;
  padding: 0.2rem 0.5rem;
  font-size: 0.7rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.calendar-day {
  font-size: 1.2rem;
  color: black;
  height: 100%;
  display: grid;
  place-content: center;
}

.badge {
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
  background-color: green;
  color: white;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
</style>
