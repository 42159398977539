<script>

import {translate} from "../helper.auth";

export default {
  name: 'TranslationService',
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    translate(value) {
      return translate(value);
    },
  }
};
</script>