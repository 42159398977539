import {nextTick} from 'vue'
import {createI18n} from 'vue-i18n'
import {API_MOCK_ENABLE, API_MOCK_PATH, API_URL} from "./common/config";
import axios from "axios";

export const SUPPORT_LOCALES = ['en', 'it']

export function setupI18n(options = {locale: 'en'}) {
    const i18n = createI18n(options)
    setI18nLanguage(i18n, options.locale)
    return i18n
}

export function setI18nLanguage(i18n, locale) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale
    } else {
        i18n.global.locale.value = locale
    }
    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */
    document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
    // load locale messages with dynami import
    // const messages = await import(
    //     /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
    //     )
    /*
      const messages = {default:{
        "menu.home": "Home"
      }}*/
    // return axios.get(`/api/lang/${lang}`).then(response => {
    //   let msgs = response.data
    //   loadedLanguages.push(lang)
    //   i18n.setLocaleMessage(lang, msgs.default)
    //   setI18nLanguage(lang)
    // })


    let res = await axios({
        url: API_MOCK_ENABLE ? API_MOCK_PATH + 'language.json' : API_URL + 'Language',
        // url: API_MOCK_PATH+'language.json',
        method: 'get',
        params: {
            lang: locale + '-' + locale.toUpperCase()
        },
        headers: {
            'Content-Type': 'application/json',
        }
    })
    if (res.status == 200) {
        // test for status you want, etc
        // if(API_MOCK_ENABLE){
        //   res.data = JSON.parse(res.data.replace(/(\r\n|\n|\r)/gm, ""));
        // }

        const messages = res.data;
        // const messages =

        i18n.global.setLocaleMessage(locale, messages)
    }


    return nextTick()
}
