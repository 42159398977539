<template>
  <campaign-layout
    :showMenu="computed_debug"
    :showBack="computed_debug"
    :pageTitle="$t('pageTitle.SocialPost') + ' - ' + socialData.title"
  >
    <p class="toptitle">{{ $t("campaign_social_post.template_to_share") }}</p>
    <ion-card class="no-background">
      <ion-card-header>
        <ion-card-title class="posttitle text-20"
          >{{ socialData.title }}
        </ion-card-title>
        <ion-row v-if="user" class="avatarimage">
          <img
            :src="user.avatar_url"
            alt="Profile picture"
            @error="handleImgError"
          />
          &nbsp; {{ user.nome }} {{ user.cognome }}
        </ion-row>
      </ion-card-header>

      <ion-card-content>
        <ion-button v-if="!interact" @click="clickInteract" expand="block"
          >{{ $t("campaign_social_post.interact") }}
        </ion-button>
        <template v-if="interact">
          <div
            v-if="type == 'facebook' && fbShareEnabled"
            class="custom-fb-share"
          >
            <span @click="sharePostFbInteract()">Condividi</span>
          </div>
          <div class="share" v-if="sharingData">
            <p>{{ $t("campaign_social_post.share_on") }}</p>
            <div class="networks">
              <ShareNetwork
                network="facebook"
                :url="sharingData.url"
                :title="sharingData.title"
                :description="sharingData.description"
                class="facebook"
                @open="onOpen"
              >
                <div class="logo">
                  <ion-icon :icon="logoFacebook"></ion-icon>
                </div>
                <div class="name">Facebook</div>
              </ShareNetwork>
              <ShareNetwork
                network="linkedin"
                :url="sharingData.url"
                :title="sharingData.title"
                :description="sharingData.description"
                class="linkedin"
                @open="onOpen"
              >
                <div class="logo">
                  <ion-icon :icon="logoLinkedin"></ion-icon>
                </div>
                <div class="name">LinkedIn</div>
              </ShareNetwork>
              <ShareNetwork
                network="whatsapp"
                :url="sharingData.url"
                :title="sharingData.title"
                :description="sharingData.description"
                class="whatsapp"
                @open="onOpen"
              >
                <div class="logo">
                  <ion-icon :icon="logoWhatsapp"></ion-icon>
                </div>
                <div class="name">WhatsApp</div>
              </ShareNetwork>
              <ShareNetwork
                network="email"
                :url="sharingData.url"
                :title="sharingData.title"
                :description="sharingData.description"
                class="email"
                @open="onOpen"
              >
                <div class="logo">
                  <ion-icon :icon="mail"></ion-icon>
                </div>
                <div class="name">Email</div>
              </ShareNetwork>
            </div>
          </div>
        </template>
        <hr />
        <template v-if="type == 'linkedin'">
          <div class="iframe-ct">
            <iframe
              _ngcontent-rlr-c10=""
              allow="encrypted-media"
              allowtransparency="true"
              frameborder="0"
              height="100%"
              scrolling="yes"
              style="border: none; overflow: hidden"
              width="100%"
              :src="'https://www.linkedin.com/embed/feed/update/' + code"
            ></iframe>
          </div>
        </template>
        <template v-else>
          <div class="cover-container" v-if="socialData.url_cover">
            <img :src="socialData.url_cover" @error="handleImgError" />
          </div>
          <div class="socialpost-body">
            <div class="ql-editor" v-html="socialData.body"></div>
          </div>
        </template>
      </ion-card-content>
    </ion-card>
  </campaign-layout>
</template>

<script>
import { arrowForward } from "ionicons/icons";
import ApiService from "../../common/service.api";
import {
  loadFacebookScript,
  shortenTextForTitle,
  translate,
} from "../../common/helper.auth";
import CampaignLayout from "../../components/CampaignLayout";
import { useRoute } from "vue-router";

import CampaignService from "../../common/mixins/Campaign";
import UserService from "@/common/mixins/User";
import { logoFacebook, logoLinkedin, logoWhatsapp, mail } from "ionicons/icons";

export default {
  name: "SocialPost",
  data() {
    return {
      campaignData: {},
      socialData: {},
      sharingData: null,
      user: null,
      postid: null,
      interact: false,
      fbShareEnabled: false,
      logoFacebook,
      logoLinkedin,
      logoWhatsapp,
      mail,
    };
  },
  components: {
    CampaignLayout,
  },
  mixins: [CampaignService, UserService],
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    clickInteract() {
      if (this.type == "linkedin") {
        this.$root.externalLinkBlank(
          "https://www.linkedin.com/feed/update/" + this.code + "/"
        );
      } else {
        this.interact = true;
      }
    },
    ifShareMessage(timeout) {
      setTimeout(() => {
        this.$root.simplePrompt(
          this.$t("campaign_social.share_if_action_title"),
          this.$t("campaign_social.share_if_action_message"),
          this.$t("campaign_social.share_if_action_close")
        );
      }, timeout);
    },
    onOpen() {
      // Triggered on share open
      this.ifShareMessage(5000);
    },
    sharePostFbInteract() {
      this.sharePostFbInteractLogged();
      //
      // window.FB.getLoginStatus((response) => {
      //   if (response.status === 'connected') {
      //     this.sharePostFbInteractLogged();
      //   } else {
      //     this.loginFacebook().then(() => {
      //       this.sharePostFbInteractLogged();
      //     });
      //   }
      // });
    },
    sharePostFbInteractLogged() {
      const that = this;
      window.FB.ui(
        {
          method: "share",
          // display: 'popup',
          href: this.socialData.finalUrl,
        },
        function (response) {
          // console.log(response);
          if (response && response.length == 0) {
            // that.facebookService.saveShareResult(that.activityId, that.permalink, that.postId, that.fullPicture).subscribe(re => {
            //   that.popupDone();
            // });

            ApiService.post("SocialShare/SaveShareFacebook/" + that.postid, {
              idSocialPost: that.postid,
            }).then((res) => {});

            // console.log('done');
            setTimeout(() => {
              this.$root.simplePrompt(
                this.$t("campaign_social.share_fb_action_title"),
                this.$t("campaign_social.share_fb_action_message"),
                this.$t("campaign_social.share_fb_action_close")
              );
            }, 100);
          }
        }
      );
    },
  },
  ionViewWillEnter() {
    const route = this.$route;
    const { id, type, postid, code } = route.params;
    this.postid = postid;
    this.type = type;
    this.code = code;
    this.campaignId = id;
    this.getCampaign(id).then((data) => {
      this.campaignData = data;
    });
    this.getCurrentUser().then((data) => {
      this.user = data.data;
    });
    switch (type) {
      case "landing":
        ApiService.get("Landing/" + postid, {
          // ApiService.get("CampaignManager/GetUserCampaings" , {
        }).then((res) => {
          // let data =
          //   {    "LandingCustomCode": "string",
          //     "url_cover": "string",
          //     "url_profile": "string",
          //     "title": {"it-IT":"test"},
          //     "body": {"it-IT":"test2"},
          //     // perc_completamento:10,
          //     // perc_completamento:false,
          //     id:1}
          // ;
          let data = res.data;
          data.title = translate(data.title);
          data.body = translate(data.body);
          data.finalUrl =
            this.settings.url_landing +
            "/" +
            encodeURI(data.title) +
            "/" +
            encodeURI(postid) +
            "/" +
            encodeURI(code);
          this.socialData = data;
          this.sharingData = {
            title: this.socialData.title,
            // description: this.socialData.body,
            url: this.socialData.finalUrl,
          };
        });
        break;
      case "facebook":
        ApiService.get("SocialShare/GetFacebookPosts/" + this.campaignId, {
          // ApiService.get("CampaignManager/GetUserCampaings" , {
        }).then((res) => {
          let data = res.data.find((x) => x.id == postid);
          data.title = shortenTextForTitle(data.text);
          data.body = data.text;
          // data.url_cover = data.picture;
          data.finalUrl = "https://www.facebook.com/" + code + "/";
          this.socialData = data;
          this.code = data.postID;
          // this.id = data.postID;

          ApiService.get(
            "SocialShare/GetLastFacebookPostImage/" +
              this.campaignId +
              "/" +
              this.code,
            {}
          ).then((res) => {
            this.socialData.url_cover = res.data + "";
          });
          // this.fbShareEnabled = true;
          // loadFacebookScript(appId).then(() => {
          this.loginFacebook().then(() => {
            this.fbShareEnabled = true;
            // window.FB.api(
            //     "/" + postid,
            //     function (response) {
            //       console.log('e', response);
            //       if (response && !response.error) {
            //         /* handle the result */
            //       }
            //     }
            // );
          });
        });
        // const appId = this.settings.FB_AppID;
        break;
      case "linkedin":
        ApiService.get("SocialShare/GetLinkedinPosts/" + this.campaignId, {
          // ApiService.get("CampaignManager/GetUserCampaings" , {
        }).then((res) => {
          let data = res.data.find((x) => x.id == postid);
          data.title = shortenTextForTitle(data.text);
          data.body = data.text;
          data.url_cover = data.picture;
          data.finalUrl = "https://www.facebook.com/" + code + "/";
          this.socialData = data;

          this.ifShareMessage(15000);
        });
        break;
    }
  },
  computed: {
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss">
.route-campaign_social_post #main-content ion-content::part(background) {
  background-color: var(--ion-color-primary);
  background-image: linear-gradient(
    to right,
    var(--ion-color-primary),
    var(--ion-color-primary-shade)
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 45% bottom 50%;
}
</style>
<style lang="scss" scoped>
ion-card {
  background: #fff !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06),
    0 0.6rem 2.4rem 0 rgba(0, 0, 0, 0.06), 0 0.4rem 1.2rem 0 rgba(0, 0, 0, 0.06);
}

ion-card-content {
  color: #000;
}

.toptitle {
  font-size: 90%;
  margin: 10px 0 20px;
  text-align: center;
  color: var(--text-color-light);
}

.posttitle {
  text-align: center;
  font-weight: bold;
}

.avatarimage {
  text-align: left;
  line-height: 50px;
}

.avatarimage img {
  border-radius: 100%;
  max-height: 50px;
  margin-right: 20px;
}

.share-network-facebook {
  //background:#1877F2;
  color: #1877f2;
}

.linkedin {
  color: #0a66c2;
}

.whatsapp {
  color: #25d366;
}

.email {
  color: var(--ion-color-primary);
}

.cover-container {
  text-align: center;

  img {
    max-width: 100%;
    margin: 10px 0;
  }
}

.share {
  border-top: 1px solid rgba(0, 0, 0, 0.24);
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  margin: 20px 0 5px;
  padding: 20px 0;
  color: #000;

  .networks > * {
    width: 25%;
    float: left;
    text-align: center;
    text-decoration: none;
    margin: 10px 0 0;

    ion-icon {
      height: 50px;
      width: 50px;
      max-height: 80%;
      max-width: 80%;

      -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.2));
      filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.2));
    }

    .name {
      //-webkit-filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, .2));
      //filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, .2));
      font-weight: bold;
      font-size: 70%;
    }
  }

  .networks:after {
    content: "";
    clear: both;
    display: block;
  }
}

.custom-fb-share {
  text-align: center;

  span {
    font-weight: bold;
    background: #1977f3;
    display: inline-block;
    color: #fff;
    padding: 13px 29px;
    border-radius: 9px;
    font-size: 25px;
  }
}

.iframe-ct {
  height: 92rem;
}
</style>
<style lang="scss">
.socialpost-body {
  // based on https://cdn.quilljs.com/1.3.6/quill.core.css
  .ql-editor p,
  .ql-editor ol,
  .ql-editor ul,
  .ql-editor pre,
  .ql-editor blockquote,
  .ql-editor h1,
  .ql-editor h2,
  .ql-editor h3,
  .ql-editor h4,
  .ql-editor h5,
  .ql-editor h6 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
      list-9;
  }
  .ql-editor ol,
  .ql-editor ul {
    padding-left: 1.5em;
  }
  .ql-editor ol > li,
  .ql-editor ul > li {
    list-style-type: none;
  }
  .ql-editor ul > li::before {
    content: "\2022";
  }
  .ql-editor ul[data-checked="true"],
  .ql-editor ul[data-checked="false"] {
    pointer-events: none;
  }
  .ql-editor ul[data-checked="true"] > li *,
  .ql-editor ul[data-checked="false"] > li * {
    pointer-events: all;
  }
  .ql-editor ul[data-checked="true"] > li::before,
  .ql-editor ul[data-checked="false"] > li::before {
    color: #777;
    cursor: pointer;
    pointer-events: all;
  }
  .ql-editor ul[data-checked="true"] > li::before {
    content: "\2611";
  }
  .ql-editor ul[data-checked="false"] > li::before {
    content: "\2610";
  }
  .ql-editor li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }
  .ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }
  .ql-editor li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }
  .ql-editor ol li:not(.ql-direction-rtl),
  .ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }
  .ql-editor ol li.ql-direction-rtl,
  .ql-editor ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }
  .ql-editor ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
      list-9;
    counter-increment: list-0;
  }
  .ql-editor ol li:before {
    content: counter(list-0, decimal) ". ";
  }
  .ql-editor ol li.ql-indent-1 {
    counter-increment: list-1;
  }
  .ql-editor ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) ". ";
  }
  .ql-editor ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  .ql-editor ol li.ql-indent-2 {
    counter-increment: list-2;
  }
  .ql-editor ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) ". ";
  }
  .ql-editor ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  .ql-editor ol li.ql-indent-3 {
    counter-increment: list-3;
  }
  .ql-editor ol li.ql-indent-3:before {
    content: counter(list-3, decimal) ". ";
  }
  .ql-editor ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }
  .ql-editor ol li.ql-indent-4 {
    counter-increment: list-4;
  }
  .ql-editor ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) ". ";
  }
  .ql-editor ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }
  .ql-editor ol li.ql-indent-5 {
    counter-increment: list-5;
  }
  .ql-editor ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) ". ";
  }
  .ql-editor ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }
  .ql-editor ol li.ql-indent-6 {
    counter-increment: list-6;
  }
  .ql-editor ol li.ql-indent-6:before {
    content: counter(list-6, decimal) ". ";
  }
  .ql-editor ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }
  .ql-editor ol li.ql-indent-7 {
    counter-increment: list-7;
  }
  .ql-editor ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) ". ";
  }
  .ql-editor ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }
  .ql-editor ol li.ql-indent-8 {
    counter-increment: list-8;
  }
  .ql-editor ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) ". ";
  }
  .ql-editor ol li.ql-indent-8 {
    counter-reset: list-9;
  }
  .ql-editor ol li.ql-indent-9 {
    counter-increment: list-9;
  }
  .ql-editor ol li.ql-indent-9:before {
    content: counter(list-9, decimal) ". ";
  }
  .ql-editor .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }
  .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }
  .ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }
  .ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }
  .ql-editor .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }
  .ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }
  .ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }
  .ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }
  .ql-editor .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }
  .ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }
  .ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }
  .ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }
  .ql-editor .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }
  .ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }
  .ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }
  .ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }
  .ql-editor .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }
  .ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }
  .ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }
  .ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }
  .ql-editor .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }
  .ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }
  .ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }
  .ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }
  .ql-editor .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }
  .ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }
  .ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }
  .ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }
  .ql-editor .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }
  .ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }
  .ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }
  .ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }
  .ql-editor .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }
  .ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }
  .ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }
  .ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }
  .ql-editor .ql-video {
    display: block;
    max-width: 100%;
  }
  .ql-editor .ql-video.ql-align-center {
    margin: 0 auto;
  }
  .ql-editor .ql-video.ql-align-right {
    margin: 0 0 0 auto;
  }
  .ql-editor .ql-bg-black {
    background-color: #000;
  }
  .ql-editor .ql-bg-red {
    background-color: #e60000;
  }
  .ql-editor .ql-bg-orange {
    background-color: #f90;
  }
  .ql-editor .ql-bg-yellow {
    background-color: #ff0;
  }
  .ql-editor .ql-bg-green {
    background-color: #008a00;
  }
  .ql-editor .ql-bg-blue {
    background-color: #06c;
  }
  .ql-editor .ql-bg-purple {
    background-color: #93f;
  }
  .ql-editor .ql-color-white {
    color: #fff;
  }
  .ql-editor .ql-color-red {
    color: #e60000;
  }
  .ql-editor .ql-color-orange {
    color: #f90;
  }
  .ql-editor .ql-color-yellow {
    color: #ff0;
  }
  .ql-editor .ql-color-green {
    color: #008a00;
  }
  .ql-editor .ql-color-blue {
    color: #06c;
  }
  .ql-editor .ql-color-purple {
    color: #93f;
  }
  .ql-editor .ql-font-serif {
    font-family: Georgia, Times New Roman, serif;
  }
  .ql-editor .ql-font-monospace {
    font-family: Monaco, Courier New, monospace;
  }
  .ql-editor .ql-size-small {
    font-size: 0.75em;
  }
  .ql-editor .ql-size-large {
    font-size: 1.5em;
  }
  .ql-editor .ql-size-huge {
    font-size: 2.5em;
  }
  .ql-editor .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  .ql-editor .ql-align-center {
    text-align: center;
  }
  .ql-editor .ql-align-justify {
    text-align: justify;
  }
  .ql-editor .ql-align-right {
    text-align: right;
  }
  .ql-editor.ql-blank::before {
    color: rgba(0, 0, 0, 0.6);
    content: attr(data-placeholder);
    font-style: italic;
    left: 15px;
    pointer-events: none;
    position: absolute;
    right: 15px;
  }
}
</style>
