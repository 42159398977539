<template>
  <master-layout>
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col class="ion-text-center">
          <h5>Seleziona i destinatari</h5>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <swiper
            :effect="'coverflow'"
            :centeredSlides="true"
            :slidesPerView="'auto'"
            :coverflowEffect="{
              rotate: 0,
              stretch: 0,
              depth: 75,
              modifier: 2,
              slideShadows: false,
            }"
            class="my-swiper"
          >
            <swiper-slide
              part="swiper-slide"
              v-for="user in users"
              :key="user.id"
              class="slide"
              :class="{ selected: user.selected }"
              @click="toggleSelected(user)"
            >
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-avatar>
                      <ion-img
                        v-if="user.avatar_url.length > 0"
                        :src="user.avatar_url"
                        alt=""
                      />
                      <ion-img
                        v-else
                        src="../../public/assets/img/avatar_placeholder.png"
                        alt=""
                      />
                      <ion-icon
                        class="is-sent"
                        v-if="user.sent"
                        :icon="checkmarkCircle"
                      ></ion-icon>
                    </ion-avatar>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </swiper-slide>
          </swiper>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-text-center">
          <h5>Anteprima questionario</h5>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-card
            v-for="question in questions"
            :key="question.id"
            style="color: white"
            class="ion-text-center"
          >
            <h3>{{ $root.translate(question.question) }}</h3>
            <ion-img :src="question.image_url" alt=""></ion-img>
            <p
              v-for="answer in question.answers"
              :key="answer.id"
              :class="{ 'is-correct': answer.isCorrect }"
            >
              {{ $root.translate(answer.answer) }}
            </p>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col size="auto">
          <ion-button @click="inviaATutti"> Invia a tutti </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </master-layout>
</template>

<script>
import ApiService from "../common/service.api"
import { translate } from "../common/helper.auth"

import "swiper/swiper-bundle.min.css"
import "swiper/components/pagination/pagination.scss"
import "@ionic/vue/css/ionic-swiper.css"

import SwiperCore, { Navigation, EffectCoverflow, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/vue"

import { checkmarkCircle } from "ionicons/icons"

import {
  IonIcon,
  IonButton,
  IonCard,
  IonImg,
  IonAvatar,
  IonGrid,
  IonRow,
  IonCol,
  IonicSwiper,
} from "@ionic/vue"

SwiperCore.use([IonicSwiper, Navigation, EffectCoverflow, Pagination])

export default {
  name: "QuizOneToMany",
  components: {
    IonIcon,
    IonButton,
    IonCard,
    IonImg,
    IonAvatar,
    IonGrid,
    IonRow,
    IonCol,
    Swiper,
    SwiperSlide,
  },
  created() {
    ApiService.get(`User`).then((res) => {
      res.data.forEach((element) => {
        element.selected = false
        this.users.push(element)
      })
      console.log(this.users)
    })
    ApiService.get(`LearningQuiz/27`).then((res) => {
      res.data.questions.forEach((element) => {
        this.questions.push(element)
      })
      console.log(this.questions)
    })
  },
  data() {
    return {
      checkmarkCircle,
      users: [],
      questions: [],
    }
  },
  methods: {
    toggleSelected(item) {
      item.selected = !item.selected
      console.log(item)
    },
    inviaATutti() {
      this.users.forEach((element) => {
        if (element.selected) {
          element.sent = true
        }
      })
      console.log(this.users)
    },
  },
}
</script>

<style scoped>
.my-swiper {
  padding-top: 1rem;
  height: 15vh;
}

.slide {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  filter: grayscale(100%);
}

.selected {
  filter: grayscale(0%);
}

.selected ion-avatar {
  border: 5px solid var(--ion-color-primary);
}

ion-avatar {
  position: absolute;
  top: -5px;
  left: -5px;
}

.is-correct {
  font-weight: bold;
  color: rgb(23, 73, 8);
  background-color: #ffffffa4;
}

.is-sent {
  position: absolute;
  top: 40px;
  left: 30px;
  color: var(--ion-color-secondary);
  font-size: 2rem;
  transform: rotate(20deg);
}
</style>
