<template>
  <master-layout
    :showBack="true"
    smallTitle="Le tue"
    bigTitle="Surveys"
    :show-info-pop-hover="true"
    info-pop-hover="Questa sezione è dedicata alla valutazione delle performance, al clima di lavoro e alle autovalutazioni che vorrai dare a te stesso e ai tuoi colleghi sui risultati ottenuti."
  >
    <transition-group name="block-slide-in" :style="{ '--i': surveys.length }">
      <template v-for="(survey, i) in surveys" :key="i">
        <SurveyCard
          :style="{ '--i': i }"
          v-if="showList1 && showList2"
          :title="survey.t_nome['it-IT']"
          :survey-duration="survey.fine"
          :users="survey.surveys"
          @click="
            console.log(
              'currentsurvey',
              JSON.parse(JSON.stringify(survey.survey[0]))
            );
            pkSurveyStore.setCurrentSurvey(
              JSON.parse(JSON.stringify(survey.survey[0]))
            );
            generalStore.setCurrentSurvey(
              JSON.parse(JSON.stringify(survey.survey[0]))
            );
          "
        />
      </template>
    </transition-group>
  </master-layout>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useGeneralStore } from "../../stores/useGeneralStore";
import { usePkSurvey } from "@/stores/usePkSurvey";
import MasterLayout from "../../components/MasterLayout.vue";
import SurveyCard from "./components/SurveyCard.vue";
import { nextTick, onMounted, ref } from "vue";
import { useSurveys } from "@/composables/useSurveys.js";

// Stores
const pkSurveyStore = usePkSurvey();
const generalStore = useGeneralStore();

// Router
const router = useRouter();

// Composables
const { loading, mySurveys, getMySurveys } = useSurveys();

// Reactive references
const showList1 = ref(false);
const showList2 = ref(false);
const surveys = ref([]);

// Funzione per raggruppare surveys per id_survey
const raggruppaSurveyPerUtente = (array) => {
  const surveysBySurveyId = {};

  array.data.forEach((item) => {
    const { id_survey, name_utente_valutato, image_utente_valutato, fine } = item;

    // Si no existe el grupo aún, inicialízalo
    if (!surveysBySurveyId[id_survey]) {
      surveysBySurveyId[id_survey] = {
        id_survey,
        t_nome: item.t_nome, // Nombre del survey (depende de tus datos)
        fine, // Fecha de fin
        surveys: [], // Lista de usuarios evaluados
      };
    }

    // Agrega el usuario evaluado a la lista
    surveysBySurveyId[id_survey].surveys.push({
      id: item.id_utente_valutato,
      name: name_utente_valutato || "Sin nombre",
      image: image_utente_valutato || "URL predeterminada",
      risposte: item.risposte || [], // Respuestas, si están disponibles
    });
  });


  return Object.values(surveysBySurveyId);
};


const getSurveys = async () => {
  surveys.value.splice(0);
  await getMySurveys(229); 
  const groupedSurveys = raggruppaSurveyPerUtente(mySurveys.value.data);
  surveys.value = groupedSurveys;


  setTimeout(async () => {
    await nextTick();
    showList1.value = true;
  }, 1000);
};

onMounted(async () => {
  await getSurveys();
  setTimeout(async () => {
    await nextTick();
    showList2.value = true;
  }, 1);
});
</script>

<style scoped lang="scss">
.title-header {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.title-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: #e2e9f1;
}

.page-container {
  padding: 16px;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  z-index: 1;
}

.card {
  border-radius: 10px;
  background-color: white;
  padding: 20px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative;
  min-height: 100px;
}
</style>
