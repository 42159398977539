<!-- Sai che sto pensando a qualcuno che quando lavora produce solo cose di bassissima qualitá... no dai non essere cattivo, non sto pensando a LUI, sto pensando ai cinesi! E sai che altro fanno i cinesi? Scambiano lettere, hanno l'abitudine di sostituire le R con le L -->

<script setup>
import { ref, onMounted, defineEmits } from "vue";
import ApiService from "@/common/service.api";
import { useStore } from "vuex";
import { IonicSwiper, IonButton, IonAvatar, IonIcon } from "@ionic/vue";
import { lockOpen, lockClosed } from "ionicons/icons";
import "swiper/swiper-bundle.min.css";
import "swiper/components/pagination/pagination.scss";
import "@ionic/vue/css/ionic-swiper.css";
import SwiperCore, { Navigation, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import CommonProgressBar from "@/components/CommonProgressBar.vue";

SwiperCore.use([IonicSwiper, Navigation, EffectCoverflow, Pagination]);

const store = useStore();
const loggedUser = store.getters["user/getUserData"];

const competenzeList = ref([]);
const loadingCompetenzeList = ref(false);

const emit = defineEmits(["event_x_adriano"]);

async function getCompetenzeStato() {
  competenzeList.value = [];
  try {
    loadingCompetenzeList.value = true;
    let res = await ApiService.get(
      `Competenza/GetCompetenzeStato/${loggedUser.id}`
    );

    competenzeList.value = res.data;

    const competenzeImgUrls = [
      "https://api-automotive-demo.whappy.it/Drive/a86174d2-aa52-40d9-b231-c97328a92f3a_Schermata%202024-08-24%20alle%2019.08.39%202.png",
      "https://api-automotive-demo.whappy.it/Drive/3839acb6-6246-4893-b50b-d2ddaaa3eb9a_Schermata%202024-08-24%20alle%2019.08.39%202%203.png",
      "https://api-automotive-demo.whappy.it/Drive/e442e146-86d9-4a1a-abbe-6b96535604ad_Schermata%202024-08-24%20alle%2019.08.39%202%204.png",
    ];

    for (let i = 0; i < competenzeList.value.length; i++) {
      competenzeList.value[i].img =
        competenzeImgUrls[i] || "/path-to-default-image.png";
    }
  } catch (error) {
    console.error("Errore durante il caricamento delle competenze:", error);
  } finally {
    loadingCompetenzeList.value = false;
  }
}

function handleImgError(event) {
  event.target.src = "/path-to-fallback-image.png"; // Fallback image
}

onMounted(() => {
  getCompetenzeStato();
});

function emitChangeMenu() {
  emit("event_x_adriano");
}
</script>

<template>
  <swiper :effect="'coverflow'" :grabCursor="true" :centeredSlides="true" :slidesPerView="'auto'" :coverflowEffect="{
    rotate: 20,
    stretch: 50,
    depth: 100,
    modifier: 2,
    slideShadows: true,
  }" class="mySwiper">
    <swiper-slide part="swiper-slide" v-for="slide in competenzeList" :key="slide.id" class="slide card-item">
      <div class="ion-text-center slide-container">
        <div class="slide-img">
          <ion-avatar>
            <img :src="slide.img" @error="handleImgError" :class="slide.acquisita ? 'img-color' : 'img-grayscale'" />
          </ion-avatar>
        </div>
        <div class="slide-title">
          <h5>{{ slide.nomeCompetenza }}</h5>
        </div>
        <div v-if="!slide.acquisita" class="slide-btn">
          <ion-button @click="redeemAward(slide)" part="native">
            <ion-icon class="mr-1" v-if="slide.vinto" :icon="lockOpen"></ion-icon>
            <ion-icon class="mr-1" v-else :icon="lockClosed"></ion-icon>
            Riscatta
          </ion-button>
        </div>
        <div v-if="slide.acquisita">
          <ion-icon class="icon-premio-acquisita" :icon="checkmarkCircle"></ion-icon>
        </div>
      </div>
    </swiper-slide>
  </swiper>

  <br />

  <ion-card @click="emitChangeMenu" style="color: var(--text-color-light)">
    <ion-card-header>
      <div class="w-full flex justify-content-end align-items-center font-bold" style="color: var(--text-color-light)">
        {{ 0 }}%
      </div>
      <div class="title" style="color: var(--text-color-light)">
        <ion-card-title>
          <span class="text-20" style="color: var(--text-color-light)">Competenze</span>
        </ion-card-title>
      </div>
      <div class="common-bar-container">
        <common-progress-bar :value="0"></common-progress-bar>
      </div>
    </ion-card-header>
  </ion-card>
</template>

<style scoped lang="scss">
.slide {
  width: 200px;
  height: 300px;
  margin: 30px 0;
  padding: 1rem 0.2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide-img img {
  height: 100%;
}

.slide-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1rem;
}

.slide-title {
  font-size: 0.5rem;
}

ion-avatar {
  width: 130px;
  height: 130px;
}

.img-color {
  filter: none;
}

.img-grayscale {
  filter: grayscale(100%);
}
</style>
<!-- Hai giá visto l'indovinello di prima? quello su competenzeList? Torna li per capire a fondo questa cosa -->
