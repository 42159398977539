import ApiService from "@/common/service.api";
import Talk from "talkjs";

export function isValidCodiceFiscale(codiceFiscale) {
  if (!codiceFiscale) return false;
  codiceFiscale = codiceFiscale.toUpperCase();
  const pattern =
    /^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1}$/;
  return pattern.test(codiceFiscale);
}

export function isValidPartitaIVA(partitaIVA) {
  if (!partitaIVA) return false;
  const pattern = /^[0-9]{11}$/;
  return pattern.test(partitaIVA);
}

async function createUser(user) {


  await Talk.ready;

  try {
    const talkUser = new Talk.User({
      id: user.id,
      name: user.fullname,
      email: user.email,
      photoUrl: user.avatar_url,
      welcomeMessage: `Conversazione con ${user.fullname}.`,
      role: user.role,
    });

    return talkUser;
  } catch (error) {
    console.error("Errore nella creazione dell'utente:", error);
    return null;
  }
}

async function createSession(talkAppId, me) {


  try {
    await Talk.ready;

    const talkUser = await createUser({ ...me, role: "CIE" });

    return new Talk.Session({
      appId: talkAppId,
      me: talkUser,
    });
  } catch (error) {
    console.error("Errore nella creazione della sessione:", error);
    return null;
  }
}

async function createInbox(session, conversations) {
  try {
    await Talk.ready;
    const inbox = session.createInbox({
      showMessageActions: true,
    });

    for (const conversation of conversations) {
      inbox.select(conversation);
    }
    return inbox;
  } catch (error) {
    console.error("Errore nella creazione dell'inbox:", error);
    return null;
  }
}

async function createConversation(me, other, talkAppId, welcomeMessage) {


  try {
    await Talk.ready;
    const session = await createSession(talkAppId, me);



    if (!session) return null;

    const conversation = session.getOrCreateConversation(
      Talk.oneOnOneId(me, other)
    );



    conversation.setAttributes({
      welcomeMessages: [welcomeMessage || "AXERTA"],
    });

    const meUser = await createUser(me);
    const otherUser = await createUser(other);

    conversation.setParticipant(meUser);
    conversation.setParticipant(otherUser);

    return conversation;
  } catch (error) {
    console.error("Errore nella creazione della conversazione:", error);
    return null;
  }
}

async function creaNuovaChat(idDossier, idBo, idCie, chatid) {
  const endpoint = `/DossierManager/creaChatTkjs/${idDossier}/${idBo}/${idCie}/${chatid}`;

  try {
    return await ApiService.post(endpoint);
  } catch (error) {
    console.error("Errore nella creazione della chat:", error);
    return null;
  }
}

async function getChatsDossier(idDossier, idBo) {
  const endpoint = `/DossierManager/GetChatsDossierTkjs/${idDossier}/${idBo}`;

  try {
    return await ApiService.get(endpoint);
  } catch (error) {
    console.error("Errore nel recupero delle chat del dossier:", error);
    return null;
  }
}

async function getChatDossier(idDossier, idCie) {
  const endpoint = `/DossierManager/GetChatDossierTkjs/${idDossier}/${idCie}`;

  try {
    return await ApiService.get(endpoint);
  } catch (error) {
    console.error("Errore nel recupero della chat del dossier:", error);
    return null;
  }
}

async function eliminaChat(id) {
  const endpoint = `/DossierManager/DeleteChatTkjs/${id}`;

  try {
    return await ApiService.get(endpoint);
  } catch (error) {
    console.error("Errore nell'eliminazione della chat:", error);
    return null;
  }
}

async function shareMessage(idChat, message) {
  const endpoint = `/DossierManager/ShareMessageTkjs/${idChat}`;

  const body = { messaggio: message };
  try {
    return await ApiService.post(endpoint, body);
  } catch (error) {
    console.error("Errore nella condivisione del messaggio:", error);
    return null;
  }
}

async function getSharedMessages(idDossier) {
  const endpoint = `/DossierManager/GetSharedMessagesTkjs/${idDossier}`;

  try {
    return await ApiService.get(endpoint);
  } catch (error) {
    console.error("Errore nel recupero dei messaggi condivisi:", error);
    return null;
  }
}

async function deleteMessage(id) {
  const endpoint = `/DossierManager/DeleteMessageTkjs/${id}`;

  try {
    return await ApiService.get(endpoint);
  } catch (error) {
    console.error("Errore nell'eliminazione del messaggio:", error);
    return null;
  }
}

async function createSuperChat(
  { boUser, cieUser, dossierId, companyNome },
  store
) {


  try {
    await Talk.ready;

    const createTalkUser = (user, role) =>
      new Talk.User({
        id: `${companyNome}_${role}_${user.id}`,
        name: user.fullname,
        email: user.email,
        photoUrl:
          user.avatar_url || `https://avatar.iran.liara.run/public/${user.id}`,
        role,
        welcomeMessage: `Conversazione con ${user.fullname}.`,
      });

    const me = createTalkUser(boUser, "CIE");
    const cie = createTalkUser(cieUser, "BO");

    const session = new Talk.Session({ appId: "tjBGvoRX", me });
    const conversation = session.getOrCreateConversation(
      `${companyNome}_${dossierId}_${boUser.id}_${cieUser.id}`
    );

    conversation.setParticipant(me);
    conversation.setParticipant(cie);
    conversation.setAttributes({
      welcomeMessages: [`Dossier: ${dossierId} - DM: ${boUser.fullname}`],
    });

    conversation.sendMessage("Ciao " + cieUser.fullname + "!");

    const inbox = session.createInbox({
      selected: conversation,
      showMessageActions: true,
    });

    const AxertaChat = await creaNuovaChat(
      dossierId,
      boUser.id,
      cieUser.id,
      conversation.id
    );

    // Dispatch the conversation to Vuex store
    store.dispatch("newInbox", inbox);
    // await store.dispatch("addTalkConversation", conversation.id);

    return { talkConversation: conversation, AxertaChat };
  } catch (error) {
    console.error("Errore nella creazione della superchat:", error);
    return null;
  }
}

export const AxertaChat = {
  createInbox,
  createSession,
  createUser,
  createConversation,
  creaNuovaChat,
  getChatsDossier,
  getChatDossier,
  eliminaChat,
  shareMessage,
  getSharedMessages,
  deleteMessage,
  createSuperChat,
};
