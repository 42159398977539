<template>
  <div class="modal-content">
    <div class="title-container">
      <h4>{{ $root.translate(award.title) }}</h4>
    </div>
    <div class="image">
      <ion-avatar>
        <img :src="award.url_image" @error="handleImgError" />
      </ion-avatar>
    </div>
    <div class="progressbar-container">
      <common-progress-bar
        class="height-10"
        :value="award.perc_completamento"
      ></common-progress-bar>
    </div>
    <div class="description-container">
      <ion-row class="contenuto">
        <ion-col>
          <ion-row v-if="award.nomeCampagna">
            <ion-col>
              Award vinto nella campagna:<br />
              <span>{{ $root.translate(award.nomeCampagna) }}</span>
            </ion-col>
          </ion-row>
          <ion-row v-if="award.vintoData">
            <ion-col>
              Award vinto in data:<br />
              <span>{{
                award.vintoData.slice(8, 10) +
                "-" +
                award.vintoData.slice(5, 7) +
                "-" +
                award.vintoData.slice(0, 4)
              }}</span>
            </ion-col>
          </ion-row>
          <ion-row v-if="award.riscattatoData">
            <ion-col>
              Award riscattato in data: <br />
              <span>{{
                award.riscattatoData.slice(8, 10) +
                "-" +
                award.riscattatoData.slice(5, 7) +
                "-" +
                award.riscattatoData.slice(0, 4)
              }}</span>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>
    <ion-row class="ion-justify-content-center ion-margint-top">
      <ion-col size="auto">
        <ion-button @click="showModal = false"> Close </ion-button>
      </ion-col>
    </ion-row>
  </div>

  <!-- <ion-row class="main">
    <ion-col>
      <ion-row class="titolo" v-if="award.title['it-IT']">
        <ion-col>
          <h3>{{ $root.translate(award.title) }}</h3>
        </ion-col>
      </ion-row>
      <ion-row v-else>
        <ion-col>
          <h3>{{ award.title }}</h3>
        </ion-col>
      </ion-row>
      <ion-row class="img ion-justify-content-center">
        <ion-col size="auto">
          <img :src="award.url_image" alt="" />
        </ion-col>
      </ion-row>
      <ion-row class="contenuto">
        <ion-col>
          <ion-row v-if="award.nomeCampagna">
            <ion-col>
              Award vinto nella campagna:
              <span>{{ $root.translate(award.nomeCampagna) }}</span>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              Award vinto in data:
              <span>{{
                award.vintoData.slice(8, 10) +
                "-" +
                award.vintoData.slice(5, 8) +
                "-" +
                award.vintoData.slice(0, 5)
              }}</span>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              Award riscattato in data:
              <span>{{
                award.riscattatoData.slice(8, 10) +
                "-" +
                award.riscattatoData.slice(5, 8) +
                "-" +
                award.riscattatoData.slice(0, 5)
              }}</span>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col size="auto">
          <ion-button @click="closeModal"> Close </ion-button>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row> -->
</template>

<script>
import {
  IonButton,
  IonRow,
  IonCol,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalInfoAward",
  props: {
    award: Object,
  },
  // created() {
  //   console.log("MODAL AWARD CREATED", this.award.title);
  // },
  data() {
    return {};
  },
  components: {
    IonButton,
    IonRow,
    IonCol,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    closeModal() {
      return modalController.dismiss();
    },
  },
});
</script>

<style scoped>
/* * {
  border: 1px solid greenyellow;
} */

.main {
  height: 100%;
  background: var(--ion-color-primary);
  color: var(--text-color-light);
}

.img img {
  border-radius: var(--ion-border-radius);
  border: 2px solid var(--ion-color-primary);
}

span {
  font-weight: bold;
}

ion-button {
  margin-top: 3rem;
}

.titolo {
  color: white;
}
</style>
