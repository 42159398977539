<template>
  <master-layout :showContentHeader="false" :showHeader="true">
    <ion-grid>
      <ion-row class="card">
        <ion-col>
          <ion-row>
            <div v-for="user in users" :key="user.id" class="item">
              <ion-row>
                <ion-col size="auto" class="ion-no-padding">
                  <ion-checkbox
                    :value="user"
                    @ionChange="checkboxUpdate($event)"
                  ></ion-checkbox>
                </ion-col>
                <ion-col class="ion-no-padding item__name">
                  <p>
                    <span>{{ user.UserString }}</span>
                  </p>
                </ion-col>
              </ion-row>
            </div>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-button @click="showCompara"> Compara </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row v-if="compara" class="card">
        <ion-col size="12">
          <ion-row class="category">
            <ion-col>
              <ion-row class="ion-justify-content-end">
                <ion-col size="7" class="ion-text-center"> ESSERE </ion-col>
              </ion-row>
              <ion-row
                v-for="user in comparedUsers"
                :key="user.ID_User"
                class="ion-justify-content-between"
              >
                <ion-col v-if="user.AnalisiPresente" size="5">
                  <div class="user-container">
                    <div class="user-container__img">
                      <img :src="user.UserImage" @error="handleImgError" />
                    </div>
                    <div class="user-container__string">
                      {{ user.UserString }}
                    </div>
                  </div>
                </ion-col>
                <ion-col v-if="user.AnalisiPresente" size="7">
                  <VTStatistica
                    :showPaddingTop="false"
                    :showMaxMin="false"
                    :valore="user.essere"
                    v-if="showList1 && showList2"
                  ></VTStatistica>
                  <!-- :style="{ '--i': statistica.enterOrder }" -->
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>

          <ion-row class="category">
            <ion-col>
              <ion-row class="ion-justify-content-end">
                <ion-col size="7" class="ion-text-center"> FARE </ion-col>
              </ion-row>
              <ion-row
                v-for="user in comparedUsers"
                :key="user.ID_User"
                class="ion-justify-content-between"
              >
                <ion-col v-if="user.AnalisiPresente" size="5">
                  <div class="user-container">
                    <div class="user-container__img">
                      <img :src="user.UserImage" @error="handleImgError" />
                    </div>
                    <div class="user-container__string">
                      {{ user.UserString }}
                    </div>
                  </div>
                </ion-col>
                <ion-col v-if="user.AnalisiPresente" size="7">
                  <VTStatistica
                    :showPaddingTop="false"
                    :showMaxMin="false"
                    :valore="user.fare"
                    v-if="showList1 && showList2"
                  ></VTStatistica>
                  <!-- :style="{ '--i': statistica.enterOrder }" -->
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>

          <ion-row class="category">
            <ion-col>
              <ion-row class="ion-justify-content-end">
                <ion-col size="7" class="ion-text-center"> AVERE </ion-col>
              </ion-row>
              <ion-row
                v-for="user in comparedUsers"
                :key="user.ID_User"
                class="ion-justify-content-between"
              >
                <ion-col v-if="user.AnalisiPresente" size="5">
                  <div class="user-container">
                    <div class="user-container__img">
                      <img :src="user.UserImage" @error="handleImgError" />
                    </div>
                    <div class="user-container__string">
                      {{ user.UserString }}
                    </div>
                  </div>
                </ion-col>
                <ion-col v-if="user.AnalisiPresente" size="7">
                  <VTStatistica
                    :showPaddingTop="false"
                    :showMaxMin="false"
                    :valore="user.avere"
                    v-if="showList1 && showList2"
                  ></VTStatistica>
                  <!-- :style="{ '--i': statistica.enterOrder }" -->
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </master-layout>
</template>

<script>
import ApiService from "../../common/service.api";
import VTStatistica from "../../components/VTStatistica.vue";

import {
  IonButton,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonList,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";

export default {
  name: "MappaDeiTalenti",
  created() {
    ApiService.get("AnalisiDelTalentoUserGroup").then((res) => {
      console.log(res);
      res.data.forEach((element) => {
        this.users.push(element);
      });
    });
  },
  components: {
    IonButton,
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    VTStatistica,
  },
  data() {
    return {
      users: [],
      comparedUsers: [],
      compara: false,
      showList1: true,
      showList2: true,
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    checkboxUpdate(event) {
      if (event.detail.checked) {
        this.comparedUsers.push(event.detail.value);
      } else {
        let filtered = this.comparedUsers.filter((element) => {
          return element.ID_User != event.detail.value.ID_User;
        });
        this.comparedUsers = filtered;
        this.comparedUsers.length == 0 ? (this.compara = false) : null;
      }
    },
    showCompara() {
      if (this.comparedUsers.length > 0) {
        this.compara = !this.compara;
      }
    },
  },
};
</script>
.no-pad { padding: 0; }
<style scoped>
/* * {
  border: 1px solid greenyellow;
} */

.emm {
  border: 2px solid red;
}

p {
  margin: 0;
}

ion-button {
  margin-top: 1rem;
}

.card {
  border-radius: 15px;
  padding: 1rem 0.5rem;
  margin: 1rem 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

ion-checkbox {
  display: grid;
  place-items: center;
}

ion-checkbox::part(container) {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

span {
  font-size: 0.7rem;
}

.item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: 50%;
  padding: 0.1rem 0;
  line-height: 80%;
}

.item__name {
  padding: 0 0.5rem;
}

.user-container {
  display: flex;
}

.user-container__img {
  width: 50px;
}

.user-container__img img {
  width: 100%;
  border-radius: 50%;
  border: 2px solid var(--ion-color-secondary);
}

.user-container__string {
  padding: 0.1rem 0 0 1rem;
  font-size: 0.8rem;
}
</style>
