<script setup>
import { defineProps, onMounted, ref, watch } from "vue";
import { IonDatetime } from "@ionic/vue";

import ChipAppuntamento from "./ChipAppuntamento.vue";

const props = defineProps({
  mentor: {
    type: Object,
  },
  currentMentorAvatar: {
    type: String,
  },
  currentDate: {
    type: String,
  },
  title: {
    type: String,
    default: "Prenota un appuntamento",
  },
  immediateScroll: {
    type: Boolean,
    default: true,
  },
});

const date = ref(null);
const chipAppuntamentoVisible = ref(false);

const onIonChangeDate = (e) => {
  date.value = e.target.value;
  chipAppuntamentoVisible.value = false;
};

const onClickPrenota = () => {
  smoothScrollToLastDiv();
  chipAppuntamentoVisible.value = true;
};

const smoothScrollToLastDiv = () => {
  console.log("scrollelelelelel 1");
  setTimeout(() => {
    console.log("scrollelelelelel 2 - dentro il timeout");
    const element = document.getElementById("last-div");
    console.log("scrollelelelelel 3 - element", element);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, 500);
};

onMounted(() => {
  if (props.immediateScroll) smoothScrollToLastDiv();
  date.value = props.currentDate;
});

// Aggiungiamo un watcher per aggiornare 'date' quando 'currentDate' cambia
watch(
  () => props.currentDate,
  (newDate) => {
    smoothScrollToLastDiv();
    date.value = newDate;
  }
);
</script>

<template>
  <div
    class="prenota-appuntamento w-full flex flex-column justify-content-center align-items-center"
  >
    <div class="w-full my-2 flex justify-content-center align-items-center">
      <span class="text-16 font-bold" style="color: var(--text-color-light)">
        {{ title }}
      </span>
    </div>

    <div class="mentor-avatar">
      <img :src="currentMentorAvatar" />
    </div>

    <div
      class="w-full flex flex-column justify-content-center align-items-center"
    >
      <span class="text-18 font-bold" style="color: var(--text-color-light)">{{
        mentor.name
      }}</span>

      <span style="color: var(--text-color-light)">{{ mentor.role }}</span>
    </div>

    <div class="calendario w-full">
      <ion-datetime v-model="date" @ion-change="onIonChangeDate"></ion-datetime>
    </div>

    <div class="w-full flex justify-content-center align-items-center mt-4">
      <button
        @click="onClickPrenota"
        class="w-full"
        style="
        color: white;
          background-color: black;
          padding: 9.6px;
          margin-top: 1rem;
          font-size: 1rem;
          font-weight: normal;
        "
      >
        Prenota
      </button>
    </div>

    <div
      v-if="chipAppuntamentoVisible"
      class="w-full flex flex-column justify-content-center align-items-center mt-4"
    >
      <ChipAppuntamento
        :avatarMentor="currentMentorAvatar"
        :date="date"
        :confermato="false"
      />

      <span class="text-14 mt-4" style="color: var(--text-color-light)"
        >La tua richiesta è in attesa di conferma</span
      >
    </div>
  </div>

  <div id="last-div" class="mt-4 pt-4"></div>
</template>

<style scoped>
.prenota-appuntamento {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  position: relative;
}

.mentor-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.calendario {
  width: 100%;
  margin-top: 10px;
}

ion-card {
  color: var(--text-color-dark) !important;
}

.ion-card {
  color: var(--text-color-dark) !important;
}
</style>
