<template>
  <div class="analisideltalento-component">
    <ion-grid v-if="showGraph" class="main-container">
      <ion-row>
        <ion-col>
          <ion-row class="ion-justify-content-center">
            <ion-col size="12">
              <div id="chartdiv" class="chart-wrapper" ref="chartdiv">
                <div
                  v-if="user_img.length > 0"
                  class="photo-cicrle"
                  :style="{ backgroundImage: 'url(' + user_img + ')' }"
                ></div>
                <div v-else class="anon-icon-container">
                  <ion-icon :icon="personCircleOutline"></ion-icon>
                </div>
              </div>
              <!-- <div class="img-container">
                <img :src="user_img" alt="IMMAGINE PROFILO" />
              </div> -->
              <ion-row class="ion-justify-content-center">
                <ion-col size="auto">
                  <h3>{{ analisiDelTalento.UserString }}</h3>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <VTBloccoStatistiche
                v-for="categoria in analisiDelTalento.DataGroup"
                :key="categoria.GroupName"
                :nome="categoria.GroupName"
                :statistiche="categoria.Data"
              ></VTBloccoStatistiche>
            </ion-col>
          </ion-row>
          <ion-row v-if="analisiDelTalento.Premessa">
            <ion-col>
              <ion-row class="ion-justify-content-center">
                <ion-col size="auto">
                  <h3>Premessa</h3>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  {{ analisiDelTalento.Premessa }}
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row v-if="analisiDelTalento.TextGroup">
            <ion-col>
              <ion-row
                v-for="gruppo in analisiDelTalento.TextGroup"
                :key="gruppo.GroupName"
              >
                <ion-col>
                  <ion-row class="ion-justify-content-center">
                    <ion-col size="auto">
                      <h4>
                        {{
                          gruppo.GroupName.charAt(0).toUpperCase() +
                          gruppo.GroupName.slice(1)
                        }}
                      </h4>
                    </ion-col>
                  </ion-row>
                  <ion-row
                    v-for="column in gruppo.Columns"
                    :key="column.Valori"
                  >
                    <ion-col>
                      <ion-row v-for="valore in column.Valori" :key="valore.ID">
                        <ion-col v-if="valore.attivo">
                          <ion-row>
                            <ion-col>
                              <h6>
                                {{
                                  valore.chiave.charAt(0).toUpperCase() +
                                  valore.chiave.slice(1)
                                }}
                              </h6>
                            </ion-col>
                          </ion-row>
                          <ion-row>
                            <ion-col>
                              {{
                                valore.testo.charAt(0).toUpperCase() +
                                valore.testo.slice(1)
                              }}
                            </ion-col>
                          </ion-row>
                        </ion-col>
                      </ion-row>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row v-if="analisiDelTalento.Conclusioni">
            <ion-col>
              <ion-row class="ion-justify-content-center">
                <ion-col size="auto">
                  <h3>Conclusioni</h3>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  {{ analisiDelTalento.Conclusioni }}
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid v-if="!showGraph">
      <ion-row>
        <ion-col> Analisi del talento non presente</ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import VTBloccoStatistiche from "./VTBloccoStatistiche.vue";
import ApiService from "../common/service.api";
import { IonRow, IonCol, IonGrid } from "@ionic/vue";
import { hslToHex } from "../utils/hslToRgb";
import { hexToHSL } from "../utils/hexToHsl";
import { personCircleOutline } from "ionicons/icons";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
  name: "AnalisiDelTalento",
  components: { IonRow, IonCol, IonGrid, VTBloccoStatistiche },
  props: {
    id: Number,
    prop_user_img: String,
    prop_analisiDelTalento: Object,
    prop_showGraph: Boolean,
    colors: Array,
  },
  created() {
    if (this.id) {
      ApiService.get("AnalisiDelTalentoByUser/" + this.id)
        .then((res) => {
          this.analisiDelTalento = JSON.parse(JSON.stringify(res.data));
          this.showGraph = true;
          try {
            this.setChart();
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {
          this.showGraph = false;
          console.error("Questo utente non ha un analisi del talento");
        });
      ApiService.get("User/" + this.id).then((res) => {
        this.user_img = res.data.avatar_url;
      });
    } else {
      this.user_img = this.prop_user_img;
      this.analisiDelTalento = this.prop_analisiDelTalento;
      this.showGraph = this.prop_showGraph;
      try {
        this.setChart();
      } catch (e) {
        console.error(e);
      }
    }
  },
  // created() {
  //   ApiService.get("AnalisiDelTalentoByUser/" + this.id)
  //     .then((res) => {
  //       this.analisiDelTalento = JSON.parse(JSON.stringify(res.data));
  //       this.showGraph = true;
  //       try {
  //         this.setChart();
  //       } catch (e) {
  //         console.error(e);
  //       }
  //     })
  //     .catch(() => {
  //       this.showGraph = false;
  //       console.error("Questo utente non ha un analisi del talento");
  //     });
  //   ApiService.get("User/" + this.id).then((res) => {
  //     this.user_img = res.data.avatar_url;
  //   });
  // },
  watch: {
    id: async function (newId) {
      const that = this;
      this.analisiDelTalento = {};
      that.showGraph = null;
      await ApiService.get("AnalisiDelTalentoByUser/" + newId)
        .then((res) => {
          that.analisiDelTalento = JSON.parse(JSON.stringify(res.data));
          that.showGraph = true;
          try {
            this.setChart();
          } catch (e) {
            console.error(e);
          }
        })
        .catch((res) => {
          console.error("Questo utente non ha un analisi del talento");
          this.showGraph = false;
        });
      await ApiService.get("User/" + newId).then((res) => {
        this.user_img = res.data.avatar_url;
      });
    },
  },
  data() {
    return {
      user_img: "",
      analisiDelTalento: {},
      showGraph: false,
      //icons
      personCircleOutline,
      chartRoot: null,
      chart: null,
    };
  },
  methods: {
    setChart() {
      let chartRoot = this.chartRoot;

      if (this.chart) {
        this.chart.dispose();
        this.chartRoot.dispose();
        this.chart = null;
        this.chartRoot = null;
      }
      setTimeout(() => {
        //
        // if(this.chartRoot) {
        //   this.chartRoot.dispose();
        //   // const div = document.querySelector('#chartdiv');
        //   // [].slice.call(div.children).forEach(child => div.removeChild(child));
        //   this.chartRoot = null;
        // }

        if (!this.chartRoot) {
          chartRoot = am5.Root.new(this.$refs.chartdiv);
          chartRoot.setThemes([am5themes_Animated.new(chartRoot)]);
        }

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = chartRoot.container.children.push(
          am5radar.RadarChart.new(chartRoot, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            startAngle: 0,
            endAngle: 360,
            //raggio esterno e raggio interno
            radius: this.radius,
            innerRadius: this.innerRadius,
          })
        );

        // // Add scrollbar
        // // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        // chart.set(
        //   "scrollbarX",
        //   am5.Scrollbar.new(root, {
        //     orientation: "horizontal",
        //     exportable: false,
        //   })
        // );

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xRenderer = am5radar.AxisRendererCircular.new(chartRoot, {
          minGridDistance: 30,
        });

        xRenderer.grid.template.set("forceHidden", true);
        xRenderer.labels.template.set("forceHidden", true);

        var xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(chartRoot, {
            maxDeviation: 0,
            categoryField: "category",
            renderer: xRenderer,
          })
        );

        var yRenderer = am5radar.AxisRendererRadial.new(chartRoot, {});
        yRenderer.grid.template.set("forceHidden", true);
        yRenderer.labels.template.set("forceHidden", true);

        var yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(chartRoot, {
            //maxDeviation: 0.3,
            min: 0,
            max: 10,
            renderer: yRenderer,
          })
        );

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var series = chart.series.push(
          am5radar.RadarColumnSeries.new(chartRoot, {
            name: "Series 1",
            sequencedInterpolation: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            categoryXField: "category",
          })
        );

        // Rounded corners for columns
        series.columns.template.setAll({
          cornerRadius: 5,
          tooltipText: "{categoryX}: {valueY}",
          templateField: "columnSettings",
        });

        xAxis.data.setAll(this.chartData);
        series.data.setAll(this.chartData);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        chart.appear(1000, 100);
        this.chartRoot = chartRoot;
        this.chart = chart;
      }, 800);
    },
    colorChart(array) {
      console.log(" FUNZIONE COLOR CHART ", array);
      if (!this.colors) {
        const hexPrimaryColor = this.whappyClientConfig.primaryColor;
        const hexSecondaryColor = this.whappyClientConfig.secondaryColor;

        const hslPrimaryColor = hexToHSL(hexPrimaryColor);
        const hslSecondaryColor = hexToHSL(hexSecondaryColor);

        let hue = hslPrimaryColor.h;
        let range = (hslPrimaryColor.h - hslSecondaryColor.h) / array.length;

        // let hue = 200;
        let saturation = 90;
        let lightness = 65;

        // let range = 140 / array.length;

        array.forEach((element) => {
          element.Data.forEach((element) => {
            element.color = hslToHex(hue, saturation, lightness);
          });
          if (hue + range < 360) {
            hue += range;
          } else {
            hue += range - 360;
          }
        });
      } else {
        for (let i = 0; i < 3; i++) {
          array[i].Data.forEach((element) => {
            element.color = this.colors[i];
          });
        }
      }
    },
  },
  computed: {
    chartData() {
      //variabile dei dati del grafico

      let data = [];
      let statistiheFiore = [];
      if (this.analisiDelTalento.DataGroup) {
        this.analisiDelTalento.DataGroup.forEach((element) => {
          const nome = element.GroupName;

          if (nome == "AVERE" || nome == "ESSERE" || nome == "FARE") {
            statistiheFiore.push(element);

            // // this.colorChart(element.Data);
            // element.Data.forEach((x) => {
            //   let current = {};
            //   current.category = x.LabelSx + " - " + x.LabelDx;
            //   current.value = x.Valore;
            //   current.columnSettings = {
            //     fill: x.color,
            //     stroke: x.color,
            //   };
            //   data.push(current);
            // });
          }
        });
        this.colorChart(statistiheFiore);
        this.analisiDelTalento.DataGroup.forEach((element) => {
          const nome = element.GroupName;

          if (nome == "AVERE" || nome == "ESSERE" || nome == "FARE") {
            // this.colorChart(element.Data);
            element.Data.forEach((x) => {
              let current = {};
              current.category = x.LabelSx + " - " + x.LabelDx;
              current.value = x.Valore;
              current.columnSettings = {
                fill: x.color,
                stroke: x.color,
              };
              data.push(current);
            });
          }
        });
      }
      return data;
    },
    radius() {
      if (window.innerWidth < 370) {
        return window.innerWidth * 0.4;
      } else {
        return 165;
      }
    },
    innerRadius() {
      // if (window.innerWidth < 380) {
      //   return window.innerWidth * 0.2;
      // } else {
      return 85;
      // }
    },
  },
};
</script>

