const CLIENT_CONFIG = {
  name: "Automotive SpA",
  numTutorialImages: 22,
  showAvatarCampaigns: true,
  apiEndpoint: "https://api-automotive-demo.whappy.it/api/",
  primaryColor: "#1d1e3e",
  primaryColor_2: "#5357b3",
  secondaryColor: "#f49100",
  secondaryColor_2: "#9d5b00",
  thirdaryColor: "#5f422e",
  thirdaryColor_2: "#838788",
  graphLightColor_1: "#1d1e3e",
  graphLightColor_2: "#f49100",
  graphDarkColor_1: "#1d1e3e",
  graphDarkColor_2: "#f49100",
};
export default CLIENT_CONFIG;
